(function () {
    'use strict';
    angular
        .module('theFoodApp')
        .factory('Versione', Versione);

    Versione.$inject = ['$resource', 'SERVER_URL'];

    function Versione($resource, SERVER_URL) {
        var resourceUrl = SERVER_URL + 'api/need-update/:codiceVersion';

        return $resource(resourceUrl, {}, {
            'getNeedUpdate': {
                url: resourceUrl,
                method: 'GET'
            },

        });
    }
})();
