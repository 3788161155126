(function () {
        'use strict';

        angular
            .module('theFoodApp')
            .controller('AppController', AppController);

        function AppController($scope, $timeout, $window, $document, $uibModalStack, NavBarToggle, DownloadAppToggle, SERVER_URL, Versione) {

            var vm = this;


            vm.headerView = function () {
                return SERVER_URL + 'static/layouts/header.html';
            };
            vm.footerView = function () {
                return SERVER_URL + 'static/layouts/footer.html';
            };
            vm.bannerView = function () {
                return SERVER_URL + 'static/pages/download-app.html';
            }

            // Interaface

            vm.showNavBar = NavBarToggle.showNavBar;
            vm.showDownloadApp = DownloadAppToggle.showDownloadAppPage;
            vm.notShowDownloadApp = DownloadAppToggle.notShowDownloadApp;
            vm.getBodyClasses = getBodyClasses;
            vm.dismissAllModals = dismissAllModals;
            vm.downloadApp = DownloadAppToggle.downloadApp;
            vm.isIosX = isIosX;

            // Implementation

            function isIosX() {
                if (window.device !== undefined && window.device.platform == "iOS" && (window.device.model == "iPhone10,3" || window.device.model == "iPhone10,6")) {
                    return true;
                } else {
                    return false;
                }
            }

            function getBodyClasses() {
                var classes = [];
                classes.push('no-breadcrumb');
                if (DownloadAppToggle.showDownloadAppPage()) {
                    classes.push('modal-open');
                }

                // Applica una classe apposta per iOS >= 7.0
                if (window.device !== undefined && window.device.platform == "iOS" && parseFloat(window.device.version) >= 7.0) {
                    classes.push('ios7-app');
                }
                return _.join(classes, ' ');
            }

            function dismissAllModals() {
                $uibModalStack.dismissAll();
            }

            // Possibile soluzione per il tasto back di Android. Probabilmente doppione di locationChangeStart.
            // document.addEventListener("backbutton", dismissAllModals, false);

            $scope.$on('$locationChangeStart', function (e) {

                // Se si sta andando indietro tramite browser con una modale aperta,
                // Chiudi la modale e impedisci il redirect.
                if ($uibModalStack.getTop()) {
                    $uibModalStack.dismissAll();
                    e.preventDefault();
                    return false;
                }

            });

            var timeout = null;
            $window.onresize = function () {
                $timeout.cancel(timeout);
                timeout = $timeout(_.bind($scope.$apply, $scope), 350);
            };

            return vm;
        }
    }

)();
