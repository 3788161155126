(function() {
    'use strict';
    angular
        .module('theFoodApp')
        .factory('StoricoOrdini', StoricoOrdini);

    StoricoOrdini.$inject = ['$resource', 'SERVER_URL'];

    function StoricoOrdini ($resource, SERVER_URL) {
        var resourceUrl = SERVER_URL + 'api/v1/ordini/storico';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}//,
            // 'get': {
            //     method: 'GET',
            //     transformResponse: function (data) {
            //         if (data) {
            //             data = angular.fromJson(data);
            //         }
            //         return data;
            //     }
            // },
            // 'update': { method:'PUT' }
        });
    }
})();
