(function() {
	'use strict';

	angular
	.module('theFoodApp')
	.config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider
		.state('cliente', {
			parent: 'app',
			url: '/cliente',
			data: {
				authorities: ['ROLE_USER'],
				pageTitle: 'theFoodApp.cliente.detail.title'
			},
			views: {
				'content@': {
					templateUrl: 'app/pages/cliente/cliente-detail.html',
					controller: 'ClienteDetailController',
					controllerAs: 'vm'
				}
			},
			resolve: {
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('cliente');
					$translatePartialLoader.addPart('indirizzo');
					$translatePartialLoader.addPart('datiFatturazione');
					$translatePartialLoader.addPart('indirizzo');


					return $translate.refresh();
				}],
				cliente: function($stateParams, Cliente) {
					return Cliente.get({id : $stateParams.id}).$promise;
				},
				indirizzi: function(Indirizzo) {
					return Indirizzo.query();
				},
				datiFatturazione: function(DatiFatturazione) {
					return DatiFatturazione.query();
				}
			},
			breadcrumb: {
				title: "{{ 'theFoodApp.cliente.detail.title' | translate }}"
			}
		})
	}

})();
