(function(){
	'use strict';

	angular
		.module('theFoodApp')
		.directive('civico', civico);

	function civico(){

		var directive = {
			restrict: 'A',
			template: '<input ng-pattern="cc.patternNumeroCivico"  />',
			replace: true,
			controller: controller,
			controllerAs: 'cc'
		};

		function controller(TrovaZona, AlertService) {
			'ngInject';

			this.patternNumeroCivico = /^([1-9][0-9]*)([\s\/]?(rosso|[a-z]|[1-9])|)$/i;
			return this;

		}

		return directive;

	}

})();
