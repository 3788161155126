(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.controller('AcquistoConclusoController', AcquistoConclusoController);

	function AcquistoConclusoController( $state, $stateParams, $translate, DateUtils, pagamento, indirizzo ) {
    var vm = this;

		vm.pagamento = pagamento;
		vm.ordine = pagamento.ordine;
		vm.locale = pagamento.ordine.locale;
		vm.indirizzo = indirizzo;
		vm.getScontoTotale = getScontoTotale;
		vm.hasSconto = hasSconto;

		// Interface
		vm.getOrarioConsegnaDa = getOrarioConsegnaDa;
		vm.getOrarioConsegnaA = getOrarioConsegnaA;
		vm.goToHome = goToHome;
		vm.goToStoricoOrdini = goToStoricoOrdini;

		if (vm.ordine.ritiroAlLocale) {
			vm.paymentLabel = vm.ordine.orarioConsegna ? 'payments.payments-ok.takeaway' : 'payments.payments-ok.prima-possibile-takeaway';
			vm.indirizzoText = vm.locale.insegna.insegna + " - " + vm.locale.indirizzo + ","
			vm.indirizzoText += vm.locale.civico + (vm.locale.rosso ? $translate.instant('theFoodApp.locale.civicoRosso') : '');
			vm.indirizzoText += " " + vm.locale.comune;
		} else {
			vm.paymentLabel = vm.ordine.orarioConsegna ? 'payments.payments-ok.consegna' : 'payments.payments-ok.prima-possibile-consegna';
			vm.indirizzoText = vm.indirizzo.indirizzoAsText + " " + vm.indirizzo.strada.descrizioneComune;
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'purchaseCompleted'
        });

		// if (vm.ordine.orarioConsegna && vm.ordine.ritiroAlLocale) {
		// 	// ORARIO TAKEAWAY
		// 	vm.paymentLabel = 'payments.payments-ok.takeaway';
		// 	vm.indirizzoText = vm.locale.insegna.insegna + " - " + vm.locale.indirizzo + ","
		// 	vm.indirizzoText += vm.locale.civico + (vm.locale.rosso ? $translate.instant('theFoodApp.locale.civicoRosso') : '');
		// 	vm.indirizzoText += " " + vm.locale.comune;
		// } else if(!vm.ordine.orarioConsegna && !vm.ordine.ritiroAlLocale){
		// 	//PRIMA POSSIBILE NO TAKEAWAY
		// 	vm.paymentLabel = 'payments.payments-ok.prima-possibile-consegna';
		// 	vm.indirizzoText = vm.indirizzo.indirizzoAsText + " " + vm.indirizzo.strada.descrizioneComune;
		// } else if(!vm.ordine.orarioConsegna && vm.ordine.ritiroAlLocale){
		// 	//PRIMA POSSIBILE TAKEAWAY
		// 	vm.paymentLabel = 'payments.payments-ok.prima-possibile-takeaway';
		// 	vm.indirizzoText = vm.locale.insegna.insegna + " - " + vm.locale.indirizzo + ","
		// 	vm.indirizzoText += vm.locale.civico + (vm.locale.rosso ? $translate.instant('theFoodApp.locale.civicoRosso') : '');
		// 	vm.indirizzoText += " " + vm.locale.comune;
		// } else {
		// 	//ORARIO
		// 	vm.paymentLabel = 'payments.payments-ok.consegna';
		// 	vm.indirizzoText = vm.indirizzo.indirizzoAsText + " " + vm.indirizzo.strada.descrizioneComune;
		// }

		function getOrarioConsegnaDa(orarioConsegna) {
			if(orarioConsegna){
				return orarioConsegna;
			}
		}

		function getOrarioConsegnaA(orarioConsegna, locale) {
			if(orarioConsegna){
				var d = new Date();
				d.setHours(parseInt(orarioConsegna.split(':')[0]));
				d.setMinutes(parseInt(orarioConsegna.split(':')[1]));
				d.setMinutes(d.getMinutes() + locale.insegna.splitOrariDiConsegna);

				return moment(d).format('HH:mm');
			}
		}

		function getScontoTotale(){
		/*	if(vm.ordine.creditoUtilizzato){
				return vm.ordine.importoOrdineSenzaSconti  + vm.ordine.importoSpedizione - vm.ordine.importoOrdine + vm.ordine.creditoUtilizzato;
			}*/
			return vm.ordine.importoOrdineSenzaSconti - vm.ordine.importoOrdine;
		}

		function hasSconto(){
			return getScontoTotale() > 0;
		}

		function goToHome() {
			$state.go('landing');
		}

		function goToStoricoOrdini() {
			$state.go('storico');
		}


		vm.dataOraTransazione = DateUtils.convertDateTimeFromServer(vm.pagamento.dataOraTransazione);
		vm.dataOrdine = DateUtils.convertDateTimeFromServer(vm.ordine.dataOrdine);
		vm.dataConsegna = DateUtils.convertDateTimeFromServer(vm.ordine.dataConsegna);

		return vm;
  }

}) ();