(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('theFoodApp')
        .constant('APP_NAME', "RunnerPizza")
        .constant('APP_TITLE', "Runner Pizza")
        .constant('HOME_PAGE', "selezione-indirizzo")
        .constant('SERVER_URL', "")
        .constant('LINK_ANDROID_APP', "https://play.google.com/store/apps/details?id=it.sviluppopg.runnerpizza.ecommerce")
        .constant('LINK_IOS_APP', "https://itunes.apple.com/us/app/runnerpizza/id1353328805?l=it&ls=1&mt=8")
        .constant('VERSION', "1.1.66")
        .constant('DEBUG_INFO_ENABLED', false)
;
})();
