(function() {
    /*jshint camelcase: false */
    'use strict';

    angular
        .module('theFoodApp')
        .factory('AuthServerProvider', AuthServerProvider);

    AuthServerProvider.$inject = ['$http', 'SERVER_URL', '$interpolate','$q', '$localStorage', 'Base64', 'WebStorageUtil', 'TokenStore'];

    function AuthServerProvider ($http, SERVER_URL,$interpolate, $q,$localStorage, Base64, WebStorageUtil, TokenStore) {
        var service = {
            getToken: getToken,
            login: login,
            loginWithToken : loginWithToken,
            logout: logout,
            loginWithGoogle: loginWithGoogle,
            loginWithFacebook: loginWithFacebook,
            loginWithApple : loginWithApple,
            getRedirectAppleJS: getRedirectAppleJS
        };

        return service;

        function getToken () {
            if (WebStorageUtil.isLocalStorageSupported())
                return $localStorage.authenticationToken;
            else
                return TokenStore.isPresent() ? TokenStore.get().token : null
        }

        function login (credentials) {
            var data = 'username=' +  encodeURIComponent(credentials.username) + '&password=' +
                encodeURIComponent(credentials.password) + '&grant_type=password&scope=read%20write&' +
                'client_secret=my-secret-token-to-change-in-production&client_id=TheFoodapp';

            return $http.post(SERVER_URL + 'oauth/token', data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Authorization': 'Basic ' + Base64.encode('TheFoodapp' + ':' + 'my-secret-token-to-change-in-production')
                }
            }).success(authSucess);

            function authSucess (response) {
                var expiredAt = new Date();
                expiredAt.setSeconds(expiredAt.getSeconds() + response.expires_in);
                response.expires_at = expiredAt.getTime();
                if (WebStorageUtil.isLocalStorageSupported())
                    $localStorage.authenticationToken = response;
                else
                    TokenStore.setToken(response);
                return response;
            }
        }

        function loginWithToken(jwt) {
            var deferred = $q.defer();
            let received_jwt = jwt;
            if (angular.isDefined(received_jwt)) {
                if (received_jwt)
                    jwt = angular.fromJson(received_jwt);
                if (received_jwt.data && !jwt.access_token)
                    jwt = angular.fromJson(received_jwt.data);

                if (jwt) {
                    var expiredAt = new Date();
                    expiredAt.setSeconds(expiredAt.getSeconds() + jwt.expires_in);
                    jwt.expires_at = expiredAt.getTime();
                    if (WebStorageUtil.isLocalStorageSupported())
                        $localStorage.authenticationToken = jwt;
                    else
                        TokenStore.setToken(jwt);
                    deferred.resolve(jwt);
                } else
                    deferred.reject();

            } else {
                deferred.reject();
            }

            return deferred.promise;
        }

        function logout () {
            return $http.post(SERVER_URL + 'api/logout').then(function() {
                if (WebStorageUtil.isLocalStorageSupported())
                    delete $localStorage.authenticationToken;
                else
                    TokenStore.clearCookie();
            });
        }


        function loginWithGoogle (googleUser) {
        	var deferred = $q.defer();
            $http.post(SERVER_URL + 'social/signin/google',googleUser).then(function(token){
            	loginWithToken(token).then(function(){
            		deferred.resolve(token);
            	},function(){
            		deferred.reject();
            	})
            })
            return deferred.promise;
        }

        function loginWithFacebook (facebookUser) {
        	var deferred = $q.defer();
            $http.post(SERVER_URL + 'social/signin/facebook',facebookUser).then(function(token){
            	loginWithToken(token).then(function(){
            		deferred.resolve(token);
            	},function(){
            		deferred.reject();
            	})
            })
            return deferred.promise;
        }

        function getRedirectAppleJS() {
            let code = window.crypto.getRandomValues(new Int32Array(1)).toString();
            let baseUrl="";
            if(window.location.origin.includes('https://'))
                baseUrl = window.location.origin + '/';
            else
                baseUrl = SERVER_URL;
            let redirecturi = baseUrl + "social/signin/applejsweb";
            var params = {
                url: 'https://appleid.apple.com/auth/authorize',
                client_id: 'it.runnerpizza.ecommerce.client',
                redirect_uri: encodeURIComponent(redirecturi),
                response_type: 'code',
                scope:'name%20email',
                response_mode:'form_post',
                state:code
            };

            let templateInterpolation = "?client_id={{client_id}}&redirect_uri={{redirect_uri}}&response_type={{response_type}}&scope={{scope}}&response_mode={{response_mode}}&state+{{state}}";
            let paramsUrl = $interpolate(templateInterpolation)(params);
            return params.url + paramsUrl;
        }

        function loginWithApple (appleID) {
            var deferred = $q.defer();
            $http.post(SERVER_URL + 'social/signin/apple',appleID).then(function(token){
                loginWithToken(token).then(function(){
                    deferred.resolve(token);
                },function(){
                    deferred.reject();
                })
            })
            return deferred.promise;
        }
    }
})();
