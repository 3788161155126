(function() {
    'use strict';

    angular
        .module( 'theFoodApp' )
        .config( stateConfig );

        stateConfig.$inject = [ '$stateProvider', 'SERVER_URL' ];

        function stateConfig ( $stateProvider, SERVER_URL ) {
            $stateProvider.state('static-page', {
                abstract: true,
                parent: 'app',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        $translatePartialLoader.addPart('static-pages');
                        return $translate.refresh();
                    }]
                }
            })
            /* STATI IN COMUNE */
            .state('static-page.chi-siamo', {
                url: '/chi-siamo',
                data: {
                    pageTitle: "theFoodApp.chisiamo"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/chi-siamo.html'
                    }
                },
                breadcrumb: {
                    title: "{{ 'theFoodApp.chisiamo' | translate }}"
                }
            })
            .state('static-page.privacy-policy', {
                url: '/privacy-policy',
                data: {
                    pageTitle: "theFoodApp.privacypolicy"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/privacy-policy.html'
                    }
                },
                breadcrumb: {
                    title: "{{ 'theFoodApp.privacypolicy' | translate }}"
                }
            })
            /* STATI PER THE FOOD*/
            .state( 'static-page.il-servizio', {
                url: '/il-servizio',
                data: {
                    pageTitle: "theFoodApp.ilservizio"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/il-servizio.html'
                    }
                },
                breadcrumb: {
                    title: "{{ 'theFoodApp.ilservizio' | translate }}"
                }
            })
            .state( 'static-page.light-catering', {
                url: '/light-catering',
                data: {
                    pageTitle: "theFoodApp.lightcatering"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/light-catering.html'
                    }
                },
                breadcrumb: {
                    title: "{{ 'theFoodApp.lightcatering' | translate }}"
                }
            })
            .state( 'static-page.gallery', {
                url: '/gallery',
                data: {
                    pageTitle: "theFoodApp.gallery"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/gallery.html'
                    }
                },
                breadcrumb: {
                    title: "{{ 'theFoodApp.gallery' | translate }}"
                }
            })
            /* STATI PER RUNNER PIZZA */
            .state( 'static-page.info', {
                url: '/info',
                data: {
                    pageTitle: "theFoodApp.info"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/info.html'
                    }
                },
                breadcrumb: {
                    title: "{{ 'theFoodApp.info' | translate }}"
                }
            })
            .state( 'static-page.promo', {
                url: '/promo',
                data: {
                    pageTitle: "theFoodApp.promo"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'php/promo.html'
                    }
                },
                breadcrumb: {
                    title: "{{ 'theFoodApp.promo' | translate }}"
                }
            })
            .state( 'static-page.lavora-con-noi', {
                url: '/lavora-con-noi',
                data: {
                    pageTitle: "theFoodApp.lavoraconnoi"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/lavora-con-noi.html'
                    }
                },
                breadcrumb: {
                    title: "{{ 'theFoodApp.lavoraconnoi' | translate }}"
                }
            })
            .state( 'static-page.eventi', {
                url: '/eventi',
                data: {
                    pageTitle: "theFoodApp.eventi"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/eventi.html'
                    }
                },
                breadcrumb: {
                    title: "{{ 'theFoodApp.eventi' | translate }}"
                }
            })
            .state( 'static-page.allergeni', {
                url: '/allergeni',
                data: {
                    pageTitle: "theFoodApp.allergeni"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/allergeni.html'
                    }
                },
                breadcrumb: {
                    title: "{{ 'theFoodApp.allergeni' | translate }}"
                }
            })
            .state( 'static-page.buoni-pasto', {
                url: '/buoni-pasto',
                data: {
                    pageTitle: "theFoodApp.buonipasto"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/buoni-pasto.html'
                    }
                },
                breadcrumb: {
                    title: "{{ 'theFoodApp.buonipasto' | translate }}"
                }
            })
            .state( 'static-page.dove-siamo', {
                url: '/dove-siamo',
                data: {
                    pageTitle: "theFoodApp.dovesiamo"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/dove-siamo.html'
                    }
                },
                breadcrumb: {
                    title: "{{ 'theFoodApp.dovesiamo' | translate }}"
                }
            })
            // ============ NUOVI STATI AGGIUNTI ================ //
            .state( 'static-page.formcallcenter', {
                url: '/form-call-center',
                data: {
                    pageTitle: "theFoodApp.formcallcenter"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'php/formcallcenter.html'
                    }
                },
                breadcrumb: {
                    title: "{{ 'theFoodApp.formcallcenter' | translate }}"
                }
            })
            .state( 'static-page.formvettori', {
                url: '/form-vettori',
                data: {
                    pageTitle: "theFoodApp.formvettori"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'php/formvettori.html'
                    }
                },
                breadcrumb: {
                    title: "{{ 'theFoodApp.formvettori' | translate }}"
                }
            })
            .state( 'static-page.formpv', {
                url: '/form-pv',
                data: {
                    pageTitle: "theFoodApp.formpv"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'php/formpv.html'
                    }
                },
                breadcrumb: {
                    title: "{{ 'theFoodApp.formpv' | translate }}"
                }
            })
            .state( 'static-page.formcorporate', {
                url: '/form-corporate',
                data: {
                    pageTitle: "theFoodApp.formcorporate"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'php/formcorporate.html'
                    }
                },
                breadcrumb: {
                    title: "{{ 'theFoodApp.formcorporate' | translate }}"
                }
            })
            .state( 'static-page.newsletter', {
                url: '/newsletter',
                data: {
                    pageTitle: "theFoodApp.newsletter"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/newsletter.html'
                    }
                },
                breadcrumb: {
                    title: "{{ 'theFoodApp.newsletter' | translate }}"
                }
            })
            .state( 'static-page.lunch', {
                url: '/lunch',
                data: {
                    pageTitle: "theFoodApp.lunch"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/lunch.html'
                    }
                },
                breadcrumb: {
                    title: "{{ 'theFoodApp.lunch' | translate }}"
                }
            })
            .state( 'static-page.aiuto', {
                url: '/aiuto',
                data: {
                    pageTitle: "theFoodApp.aiuto"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/aiuto.html'
                    }
                }
            })
            .state( 'static-page.menu', {
                url: '/menu',
                data: {
                    pageTitle: "theFoodApp.menu"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/menu.html'
                    }
                }
            })
            .state( 'static-page.pizza', {
                url: '/pizza',
                data: {
                    pageTitle: "theFoodApp.pizza"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/pizza.html'
                    }
                }
            })
            .state( 'static-page.calzone', {
                url: '/calzone',
                data: {
                    pageTitle: "theFoodApp.calzone"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/calzone.html'
                    }
                }
            })
            .state( 'static-page.focaccia-in-piega', {
                url: '/focaccia-in-piega',
                data: {
                    pageTitle: "theFoodApp.focacciaInPiega"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/focaccia-in-piega.html'
                    }
                }
            })
            .state( 'static-page.teglia-del-fornaio', {
                url: '/teglia-del-fornaio',
                data: {
                    pageTitle: "theFoodApp.tegliaDelFornaio"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/teglia-del-fornaio.html'
                    }
                }
            })
            .state( 'static-page.insalata-carpaccio', {
                url: '/insalata-carpaccio',
                data: {
                    pageTitle: "theFoodApp.insalataCarpaccio"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/insalata-carpaccio.html'
                    }
                }
            })
            .state( 'static-page.hamburger', {
                url: '/hamburger',
                data: {
                    pageTitle: "theFoodApp.hamburger"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/hamburger.html'
                    }
                }
            })
            .state( 'static-page.sfiziosi', {
                url: '/sfiziosi',
                data: {
                    pageTitle: "theFoodApp.sfiziosi"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/sfiziosi.html'
                    }
                }
            })
            .state( 'static-page.secondi-piatti', {
                url: '/secondi-piatti',
                data: {
                    pageTitle: "theFoodApp.secondiPiatti"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/secondi-piatti.html'
                    }
                }
            })
            .state( 'static-page.primi-piatti', {
                url: '/primi-piatti',
                data: {
                    pageTitle: "theFoodApp.primiPiatti"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/primi-piatti.html'
                    }
                }
            })
            .state( 'static-page.bibite-vino-birra', {
                url: '/bibite-vino-birra',
                data: {
                    pageTitle: "theFoodApp.bibiteVinoBirra"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/bibite-vino-birra.html'
                    }
                }
            })
            .state( 'static-page.dolci-gelati', {
                url: '/dolci-gelati',
                data: {
                    pageTitle: "theFoodApp.dolciGelati"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/dolci-gelati.html'
                    }
                }
            })
            // ============ FINE NUOVI STATI AGGIUNTI ================ //
            .state( 'static-page.contatti', {
                url: '/contatti',
                data: {
                    pageTitle: "theFoodApp.contatti"
                },
                views: {
                    'content@': {
                        templateUrl: SERVER_URL + 'static/pages/contatti.html'
                    }
                },
                breadcrumb: {
                    title: "{{ 'theFoodApp.contatti' | translate }}"
                }
            })
            .state( 'static-page.home-runnerpizza', {
                url: '/home',
                views: {
                    'content@': {
                        templateUrl:  SERVER_URL + 'static/pages/home-runnerpizza.html'
                    }
                }
            })
        }
})();
