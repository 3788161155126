(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.controller('CarrelloController', CarrelloController);

	function CarrelloController($scope, $state, $translate, carrelloInstance, locale, isTakeaway, Principal, LoginService, ConfirmationYesnoDialogService) {

		var vm = this;

		// Interface
		vm.carrello = carrelloInstance;
		vm.canCheckout = canCheckout;
		vm.checkout = checkout;
		vm.svuotaCarrello = svuotaCarrello;
		vm.getImportoMinimoConsegna = getImportoMinimoConsegna;
		vm.showEmptyCartError = false;
		vm.showAmountTooLowError = false;
		vm.showBannerAmountTooLowInfo = true;
		vm.showAmountTooLowInfo = showAmountTooLowInfo;
		vm.visualizzaMessaggioPromo = visualizzaMessaggioPromo;
		vm.isTipoConsegnaGratis= isTipoConsegnaGratis;

		// Implementation

		function visualizzaMessaggioPromo(){
			return carrelloInstance.getMessaggiPromozione();
		}

        function isTipoConsegnaGratis(scontoPromozione) {
            return carrelloInstance.isTipoConsegnaGratis(scontoPromozione)
        }

		function showAmountTooLowInfo(){
			return vm.showAmountTooLowError ? false : vm.showBannerAmountTooLowInfo && !vm.carrello.isImportoMinimoRaggiunto();
		}

		function getImportoMinimoConsegna() {
			return isTakeaway ? 0 : locale.insegna.importoMinimoConsegna;
		}

		function canCheckout() {
			return !vm.carrello.isVuoto() && vm.carrello.isImportoMinimoRaggiunto();
		}

		function checkout() {

			vm.showEmptyCartError = false;
			vm.showAmountTooLowError = false;

			return Principal.identity(true).then( function(identity) {

				if ( identity === null ) {
					var loginBanner = {
						message: 'global.messages.info.login-to-complete-order',
						type: 'info'
					};
					LoginService.open( loginBanner ).then( vm.carrello.refresh ).then( checkout );
					return false;
				}

				if (vm.carrello.isVuoto()) {
					vm.showEmptyCartError = true;
					return false;
				}

				if (!vm.carrello.isImportoMinimoRaggiunto()) {
					vm.showAmountTooLowError = true;
					vm.showBannerAmountTooLowInfo = false;
					return false;
				}

				$state.go('concludi-acquisto');

			} );

		}

		function svuotaCarrello() {
			var data = {
				title: $translate.instant('theFoodApp.carrello.confirm.svuotaCarrelloTitle'),
				body: $translate.instant('theFoodApp.carrello.confirm.svuotaCarrelloBody'),
			};

			return ConfirmationYesnoDialogService.open(data).then(function() {
				return carrelloInstance.svuotaCarrello().then(function() {
					Notification.success({
						title: $translate.instant('theFoodApp.carrello.carrelloSvuotato'),
						delay: NOTIFICATION_TIME
					});
				}, function() {
					Notification.error({
						title: $translate.instant('theFoodApp.carrello.carrelloNonSvuotato'),
						delay: NOTIFICATION_TIME
					});
				});
			});
		}

		function onCarrelloUpdated() {
			vm.showEmptyCartError = false;
		}

		$scope.$on('carrello-updated', onCarrelloUpdated);

		return vm;

	}

})();
