(function() {
	'use strict';

	angular.module('theFoodApp').controller('ConfirmationYesnoDialogController', ConfirmationYesnoDialogController);

	function ConfirmationYesnoDialogController($uibModalInstance, data) {
		var vm = this;

    vm.title = data.title;
    vm.body = data.body;

    vm.ok = ok;
    vm.cancel = cancel;

    function ok() {
      $uibModalInstance.close();
    }

    function cancel() {
			$uibModalInstance.dismiss();
    }

  }

})();
