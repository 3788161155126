(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.factory('NavBarToggle', NavBarToggle);

	function NavBarToggle( $rootScope, Principal ) {

		var vm = this;

		// Interface

		vm.showNavBar = shouldShowNavBar;

		// Implementation

		var isAdmin;

		function getIsAdmin() {
			isAdmin = false;
			Principal.identity().then( function(identity) {
				isAdmin = identity && identity.authorities && identity.authorities.indexOf('ROLE_ADMIN') !== -1;
			});
		}

		$rootScope.$on('authenticationSuccess', function() {
	        getIsAdmin();
		});
		$rootScope.$on('authenticationLogout', function() {
	        getIsAdmin();
		});

		function shouldShowNavBar() {
			return isAdmin;
		}

		getIsAdmin();

		return vm;

	}

}) ();
