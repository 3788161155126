(function() {
    'use strict';

    angular
        .module('theFoodApp')
        .factory('LoginService', LoginService);

    function LoginService ($uibModal, $resource, SERVER_URL) {


        var service = {
            open: open,
            getResources: getResources
        };

        var modalInstance = null;
        var resetModal = function () {
            modalInstance = null;
        };

        return service;

        function open ( banner ) {
            if (modalInstance !== null) return;
            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/components/login/login.html',
                controller: 'LoginController',
                controllerAs: 'vm',
                resolve: {
                    banner: _.constant( banner ),
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('login');
                        return $translate.refresh();
                    }]
                }
            });
            return Promise.resolve( modalInstance.result ).finally( resetModal );
        }

        function getResources(){
            var resourceUrl = 'http://localhost:8080/social/signin/jwtApple';

            return $resource(resourceUrl, {}, {
                getJwtApple : {
                    url: resourceUrl,
                    method : 'GET'
                }});
        }
    }
})();
