( function() {
	'use strict';
	angular
		.module( 'theFoodApp' )
		.factory( 'Takeaway', Takeaway );

	Takeaway.$inject = [ '$resource', 'SERVER_URL' ];

	function Takeaway( $resource, SERVER_URL ) {
		var resourceUrl = SERVER_URL + 'api/public/v1/takeaway/:type';

		return $resource( resourceUrl, {}, {
			insegne: {
				method: 'GET',
				isArray: true,
				params: {
					type: 'insegne'
				},
				cache: true
			},
			comuni: {
				method: 'GET',
				isArray: true,
				params: {
					type: 'comuni'
				},
				cache: true
			},
			tipologieLocali: {
				method: 'GET',
				isArray: true,
				params: {
					type: 'tipologielocali'
				},
				cache: true
			}
		} );
	}
} )();
