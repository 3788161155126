(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.config(stateConfig);

	stateConfig.$inject = ['$stateProvider'];

	function stateConfig($stateProvider) {

		$stateProvider.state('concludi-acquisto', {
				parent: 'locale',
				url: '/concludi-acquisto',
				reloadOnSearch: false,
				data: {
					authorities: ['ROLE_USER'],
					pageTitle: 'theFoodApp.concludi.title'
				},
				params: {
					pagamentoId: undefined,
					paymentOk: false,
					paymentError: false,
					paymentCancel: false
				},
				views: {
					'content@': {
						templateUrl: 'app/pages/concludi-acquisto/concludi-acquisto.html',
						controller: 'ConcludiAcquistoController',
						controllerAs: 'vm'
					}
				},
				resolve: {
					orariConsegna: function(locale, OrariConsegna) {
						return OrariConsegna.query({
							localeId: locale.id
						}).$promise;
					},
					carrello: function(Carrello, locale, zona, carrelloInstance) {
						return carrelloInstance.$promise.tap(function(carrello) {
							return carrello.refresh();
						});
					},
					cliente: function(Cliente) {
						return Cliente.get().$promise;
					},
					indirizzo: function(IndirizzoStore, Indirizzo, RouteError, isTakeaway, ordine, translatePartialLoader) {
						// Se sono in takeaway non ho indirizzo
						if (isTakeaway) {
							return null;
						}

						if (!IndirizzoStore.isPresent()) {
							return Promise.reject(new RouteError().translatedErrorMessage('theFoodApp.selezione-locale.no-indirizzo-consegna').redirectTo('selezione-indirizzo'));
						}

						var indirizzo = IndirizzoStore.get();

						// Se è un indirizzo digitato, lo restituisco poi il cliente completerà i campi
						if (indirizzo.new) {
							return indirizzo;
						}
						// Altrimenti restituisco quello presente in rubrica
						return indirizzo = Indirizzo.get({
							id: indirizzo.addressId
						}).$promise;
					},
					indirizziRubrica: function(Principal, Indirizzo, isTakeaway, indirizzo, locale, ordine) {

						if (!isTakeaway && indirizzo && indirizzo.new) {
							return Principal.identity().then(function(identity) {
								return Principal.isAuthenticated() ? Indirizzo.query({ idZona: indirizzo.zone }).$promise : Promise.resolve([]);
							});
						} else {
							return Promise.resolve([]);
						}
					},
					// SERVE ?
					addressBook: function(Principal, Indirizzo) {
						return Principal.identity().then(function(identity) {
							return Principal.isAuthenticated() ? Indirizzo.query().$promise : Promise.resolve([]);
						});
					},
					datiFatturazione: function(DatiFatturazione) {
						return DatiFatturazione.query();
					},
					ordine: function(ClienteOrdine, locale, $stateParams) {
						return !$stateParams.paymentOk ? ClienteOrdine.get({ localeId: locale.id }).$promise : null;
					},
					translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
						$translatePartialLoader.addPart('concludiAcquisto');
						$translatePartialLoader.addPart('indirizzo');
						$translatePartialLoader.addPart('locale');
						$translatePartialLoader.addPart('cliente');
						$translatePartialLoader.addPart('payment');
						$translatePartialLoader.addPart('global');
						$translatePartialLoader.addPart('datiFatturazione');

						return $translate.refresh();
					}]
				},
				breadcrumb: {
					title: "{{ 'theFoodApp.concludi.title' | translate }}"
				}
			})
			.state('acquisto-concluso', {
				parent: 'concludi-acquisto',
				url: '/acquisto-concluso',
				params: {
					pagamentoId: undefined
				},
				views: {
					'content@': {
						templateUrl: 'app/pages/concludi-acquisto/acquisto-concluso.html',
						controller: 'AcquistoConclusoController as vm'
					}
				},
				resolve: {
					pagamento: function($stateParams, Pagamento) {
						return Pagamento.get({ id: $stateParams.pagamentoId}).$promise;
					},
					indirizzo: function(Indirizzo, pagamento) {
						return !pagamento.ordine.ritiroAlLocale ? Indirizzo.get({ id: pagamento.ordine.indirizzoId }).$promise : null;
					},
					translatePartialLoader: [ '$translate', '$translatePartialLoader', function( $translate, $translatePartialLoader ) {
						$translatePartialLoader.addPart( 'payment' );
						return $translate.refresh();
					} ]
				},
				breadcrumb: { title: "{{ 'theFoodApp.payment.title' | translate }}" }
			});

	}
})();
