(function() {
    'use strict';

    angular
        .module('theFoodApp')
        .controller('IndirizzoDialogController', IndirizzoDialogController);

    IndirizzoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'translationStorageProvider', 'Indirizzo', 'TipoIndirizzo'];

    function IndirizzoDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, translationStorageProvider, Indirizzo, TipoIndirizzo) {
        var vm = this;

        vm.indirizzo = entity;
        vm.selectedAddress = true;
        vm.clear = clear;
        vm.save = save;
        vm.tipoindirizzos = TipoIndirizzo.query();

		vm.getLocale = getLocale;
        vm.onAddressSelected = onAddressSelected;

		function getLocale() {
			return translationStorageProvider.getCurrent();
		}

        function onAddressSelected() {
			vm.selectedAddress = true;
		}

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            if (angular.isUndefined(vm.indirizzo.strada) || angular.isUndefined(vm.indirizzo.strada.id)) {
                vm.selectedAddress = false;
                return;
            }

            vm.isSaving = true;
            if (vm.indirizzo.id !== null) {
                Indirizzo.update(vm.indirizzo, onSaveSuccess, onSaveError);
            } else {
                Indirizzo.save(vm.indirizzo, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
