(function () {
    'use strict';

    angular
        .module('theFoodApp')
        .controller('ConcludiAcquistoController', ConcludiAcquistoController);

    function ConcludiAcquistoController(Pagamento, Coupon, $window, DateUtils, MovimentiCredito, bsLoadingOverlayService, $state, $scope, $stateParams, $anchorScroll, orariConsegna, $translate, $uibModal, carrello, cliente, Cliente, addressBook, indirizzo, indirizziRubrica, TipoIndirizzo, locale, isTakeaway, Indirizzo, Ordine, translationStorageProvider, datiFatturazione, ordine, IndirizzoStore, AlertService, DatiFatturazioneDialogService, IndirizziRubricaDialogService, Notification, NOTIFICATION_TIME, $timeout) {
        var vm = this;

        vm.pagamentoId = $stateParams.pagamentoId;
        vm.paymentError = $stateParams.paymentError;
        vm.paymentCancel = $stateParams.paymentCancel;
        var today = moment().format('YYYY-MM-DD');

        // Interface
        vm.checkCodiceLotteria = false;
        vm.emettiFattura = false;
        vm.checkEmettiFattura = false;
        vm.orariConsegna = orariConsegna;
        vm.isTakeaway = isTakeaway;
        vm.addressBook = addressBook;
        vm.indirizzo = indirizzo;
        // vm.indirizziRubrica = indirizziRubrica;
        vm.dataOraConfirmed = false;
        vm.calcolaCostiOrdine = calcolaCostiOrdine;
        vm.cliente = cliente;
        vm.codiceLotteria = vm.cliente.codiceLotteria;

        vm.datiFatturazione = datiFatturazione;
        vm.ordine = carrello.getOrdine(); // ordine;
        vm.localeEmetteFattura = localeEmetteFattura;

        vm.datePossibiliDiConsegna = [];
        vm.oraConsegnaSelezionata = null;
        vm.dataConsegnaSelezionata = null;
        vm.idFatturazione = null;
        vm.getOrariConsegna = _.memoize(getOrariConsegna);
        vm.showAlertDataOraNotConfirmed = false;

        vm.getImportoArticoliSenzaSconti = carrello.getImportoArticoliSenzaSconti;
        vm.getImportoArticoli = carrello.getImportoArticoli;
        vm.getCostoSpedizione = getCostoSpedizione;
        vm.hasCostoSpedizione = hasCostoSpedizione;
        vm.getScontoTotale = getScontoTotale;

        vm.dataConsegnaFormattata = getDataConsegnaFormattata;
        vm.getOrarioFormattato = getOrarioFormattato;
        vm.concludiAcquisto = concludiAcquisto;
        vm.getLocale = getLocale;
        vm.selezionaIndirizzo = selezionaIndirizzo;
        vm.datiFatturazioneDialog = datiFatturazioneDialog;
        vm.totaleConCredito = totaleConCredito;

        vm.hasSconto = hasSconto;
        vm.totaleCarrello = totaleCarrello;

        vm.indirizzoCliente = null;
        vm.civico = null;

        vm.tipoindirizzo = TipoIndirizzo.query();

        vm.onDataChanged = onDataChanged;
        vm.onOraChanged = onOraChanged;
        vm.dataOraLoading = false;
        vm.modifyOrderCondition = false;

        //COUPON
        vm.codiceCouponInserito = "";
        vm.coupon_code = "";
        vm.saveFailed1 = false;
        vm.saveFailed2 = false;
        vm.saveFailed3 = false;
        vm.saveFailed4 = false;
        vm.saveFailed5 = false;
        vm.saveFailed6 = false;
        vm.saved1 = false;
        vm.saved2 = false;

        //PAYPAL REDIRECT
        vm.isPaymentError = false;
        //RICALCOLO ORDINE
        //vm.recalcOrdine = recalcOrdine;
        // IS MOBILE
        vm.mobile = false;
        //CREDITO
        vm.getTotaleCredito = getTotaleCredito;
        vm.totaleCredito = 0;
        vm.debito = 0;
        vm.utilizzaCredito = true;
        // Implementation

        var lang = $translate.proposedLanguage() || $translate.use();
        if ($window.innerWidth < 760) { // 768px portrait
            vm.mobile = true;
        }


        function activate() {
            // Forzo il ritorno alla pagina precedente se l'importo dell'ordine
            // non ha raggiunto l'importo minimo. Possibile arrivo per URL Injection
            if (ordine.importoOrdineSenzaSconti < locale.insegna.importoMinimoConsegna) {
                AlertService.error($translate.instant('theFoodApp.concludi.error.importo-minimo-non-raggiunto', {
                    importoMinimo: locale.insegna.importoMinimoConsegna + '€'
                }));
                $state.go('locale');
                return false;
            }

            // Forzo il ritorno alla pagina precedente se
            // il carrello è vuoto. Possibile arrivo per URL Injection
            if (carrello.isVuoto()) {
                AlertService.error($translate.instant('theFoodApp.concludi.error.carrelloVuoto'));
                $state.go('locale');
                return false;
            }

            // Al refresh della pagina carica i dati di fatturazione
            if (vm.ordine.datiFatturazioneId !== null) {
                vm.emettiFattura = true;
                vm.checkEmettiFattura = true;
                vm.idFatturazione = vm.ordine.datiFatturazioneId;
            }

            // Calcola le date possibili di consegna.
            vm.datePossibiliDiConsegna = _(orariConsegna).keys()
                .filter(function (chiavi) {
                    return chiavi[0] !== '$';
                })
                .filter(isValidDay)
                .sortBy()
                .value();

            // Se non ci sono date possibili di consegna,
            // torno alla pagina precedente. Non permetto
            // all'utente di entrare su concludi-acquisto
            if (vm.datePossibiliDiConsegna.length === 0) {
                AlertService.error($translate.instant('theFoodApp.concludi.error.noDateConsegna'))
                $state.go('locale');
                return false;
            }

            if (today === moment(vm.datePossibiliDiConsegna[0]).format('YYYY-MM-DD')) {
                vm.dataConsegnaSelezionata = vm.datePossibiliDiConsegna[0];
            }

            /*var data = moment(vm.ordine.dataConsegna).format('YYYY-MM-DD')
            for (var i = 0; i < vm.datePossibiliDiConsegna.length; i++) {
                if (vm.datePossibiliDiConsegna[i] === data) {
                    vm.dataConsegnaSelezionata = vm.datePossibiliDiConsegna[i];
                    break;
                }
            }*/

            if (indirizzo && indirizzo.new && indirizziRubrica && indirizziRubrica.length > 0) {
                setTimeout(function () {
                    IndirizziRubricaDialogService.open(indirizziRubrica).then(function (i) {
                        vm.indirizzo = i;
                        vm.indirizzo.new = false;
                    });
                }, 500);
            }

            if (vm.dataConsegnaSelezionata) {
                autoSelectOrarioConsegna();
            }

            if (!isTakeaway) {
                vm.indirizzo.telefono = vm.indirizzo.telefono ? vm.indirizzo.telefono : vm.cliente.telefono;
            }
            getTotaleCredito();
        }

        function autoSelectOrarioConsegna() {

            var orariConsegna = getOrariConsegna(vm.dataConsegnaSelezionata);

            // Se esiste il prima possibile lo metto come default
            if (!orariConsegna[0].group) {
                vm.oraConsegnaSelezionata = orariConsegna[0];
            }

            // Popolata con l'ora presente sull'ordine sul DB.
            for (var i = 0; i < orariConsegna.length; i++) {
                var labelEstremoSx = _.split(orariConsegna[i].label, '-', 1);
                if (vm.ordine.orarioConsegna === moment(labelEstremoSx[0], 'HH:mm').locale(lang).format('HH:mm')) {
                    vm.oraConsegnaSelezionata = orariConsegna[i];
                    controllaSeAncoraValido(_.split(vm.oraConsegnaSelezionata.label, '-'), orariConsegna[i])
                    break;
                }
            }

            if (vm.oraConsegnaSelezionata) {
                calcolaCostiOrdine();
            }

        }

        function selezionaIndirizzo(indirizzo) {
            vm.indirizzo = indirizzo;
        }

        function getScontoTotale() {
            return vm.ordine.importoOrdineSenzaSconti - vm.ordine.importoOrdine;
        }

        function hasSconto() {
            return getScontoTotale() > 0;
        }


        function totaleCarrello() {
            if (vm.utilizzaCredito) {
                if ((vm.ordine.importoOrdine + getCostoSpedizione() + vm.debito) - vm.ordine.scontoCoupon - vm.totaleCredito < 0) {
                    return 0;
                } else {
                    return (vm.ordine.importoOrdine + getCostoSpedizione() + vm.debito) - vm.ordine.scontoCoupon - vm.totaleCredito;
                }
            } else {
                return (vm.ordine.importoOrdine + getCostoSpedizione() + vm.debito) - vm.ordine.scontoCoupon;
            }
        }

        function totaleConCredito() {
            var totaleSenzaCredito = ((vm.ordine.importoOrdine + getCostoSpedizione()) - vm.ordine.scontoCoupon);
            if (vm.totaleCredito >= totaleSenzaCredito) {
                return totaleSenzaCredito;
            } else {
                return vm.totaleCredito;
            }
        }

        function datiFatturazioneDialog() {
            return DatiFatturazioneDialogService.open(cliente)
                .then(function (datiFatturazione) {
                    vm.datiFatturazione.push(datiFatturazione);
                });
        }

        function isValidDay(d) {
            return orariConsegna[d].orariConsegnaPranzo.length +
                orariConsegna[d].orariConsegnaCena.length +
                orariConsegna[d].consegnaPrimaPossibilePranzo +
                orariConsegna[d].consegnaPrimaPossibileCena;
        }

        function getOrariConsegna(data) {
            if (!data)
                return [];

            var orari = orariConsegna[data];
            if (!orari)
                return [];

            var costoConsegnaPranzo = isTakeaway ? 0 : locale.insegna.costoConsegnaPranzo;
            var costoConsegnaCena = isTakeaway ? 0 : locale.insegna.costoConsegnaCena;

            var result = [];
            // result.push( vm.orarioConsegnaPlaceholder );
            if (orari.consegnaPrimaPossibilePranzo)
                result.push({
                    value: 'PrimaPossibile',
                    label: $translate.instant('theFoodApp.concludi.labelPrimaPossibile', {
                        attesa: locale.attesaPrnz
                    }),
                    costoConsegna: costoConsegnaPranzo
                });
            if (orari.consegnaPrimaPossibileCena)
                result.push({
                    value: 'PrimaPossibile',
                    label: $translate.instant('theFoodApp.concludi.labelPrimaPossibile', {
                        attesa: locale.attesaCena
                    }),
                    costoConsegna: costoConsegnaCena
                });
            _.each(orari.orariConsegnaPranzo, function (o, i) {
                result.push({
                    value: 'p' + i,
                    label: getOrarioFormattato(o),
                    costoConsegna: costoConsegnaPranzo,
                    group: $translate.instant('theFoodApp.concludi.labelOrariConsegnaPranzo'),
                    disabled: o.tipoFasciaBloccata === 'BLOCCATA'
                });
            });
            _.each(orari.orariConsegnaCena, function (o, i) {
                result.push({
                    value: 'c' + i,
                    label: getOrarioFormattato(o),
                    costoConsegna: costoConsegnaCena,
                    group: $translate.instant('theFoodApp.concludi.labelOrariConsegnaCena'),
                    disabled: o.tipoFasciaBloccata === 'BLOCCATA'
                });
            });

            return result;
        }

        function controllaSeAncoraValido(label, result) {
            var dataConsegnaMoment = new moment(vm.ordine.dataConsegna, 'YYYY-MM-DD');
            var labelDx = moment(label[1], 'HH:mm');
            var current = moment();

            if (moment(current.format('YYYY-MM-DD')).isSame(dataConsegnaMoment.format('YYYY-MM-DD')) && !current.isBefore(labelDx)) {
                vm.showAlertOrario = true;
                vm.oraConsegnaSelezionata = null;
            }
        }

        function getDataConsegnaFormattata(date) {
            if (!date)
                return '';

            var momentDate = new moment(date, 'YYYY-MM-DD');
            if (momentDate.startOf('day').isSame(new Date(), "day"))
                return $translate.instant('theFoodApp.concludi.oggi');

            return momentDate.locale(lang).format('dddd D MMMM YYYY');
        }

        function getOrarioFormattato(orario) {
            if (!orario)
                return '';

            var inizio = new moment(orario.oraInizio, 'HH:mm:ss').locale(lang).format('HH:mm');
            var fine = new moment(orario.oraFine, 'HH:mm:ss').locale(lang).format('HH:mm');
            var extra = orario.tipoFasciaBloccata === 'CRITICA' ? ' - Fascia critica' : '';
            return inizio + ' - ' + fine + extra;
        }

        function hasCostoSpedizione() {
            return isTakeaway || (vm.dataConsegnaSelezionata && vm.oraConsegnaSelezionata);
        }

        function getCostoSpedizione() {
            //return isTakeaway ? 0 : vm.oraConsegnaSelezionata.costoConsegna;
            return vm.ordine.importoSpedizione ? vm.ordine.importoSpedizione : 0;
        }

        function concludiAcquisto() {
            vm.paymentError = false;
            vm.paymentCancel = false;
            vm.coupon_code = "";
            vm.showAlertOraMissing = false;
            vm.showAlertIndFatturazione = false;

            if (!vm.dataOraConfirmed) {
                vm.showAlertDataOraNotConfirmed = true;
                $anchorScroll();
                return;
            }
            if (vm.oraConsegnaSelezionata === null) {
                vm.showAlertOraMissing = true;
                $anchorScroll();
                return;
            }
            if (vm.checkEmettiFattura && vm.idFatturazione === null) {
                vm.showAlertIndFatturazione = true;
                $anchorScroll();
                return;
            }
            if ($scope.editForm.$invalid) {
                return;
            }

            vm.isSaving = true;
            return Promise.resolve().then(function () {
                if (isTakeaway) {
                    return;
                }

                // Se entro con un nuovo indirizzo, lo salvo nel db.
                if (vm.indirizzo.new) {
                    vm.indirizzo.clienteId = cliente.id;
                    return Indirizzo.save(vm.indirizzo).$promise
                        .then(function (result) {
                            vm.ordine.indirizzoId = result.id;
                            vm.indirizzo.new = false;
                            vm.indirizzo.id = result.id;
                            return result;
                        });
                }

                // altrimenti faccio l'update nel caso avessi fatto delle modifiche
                return Indirizzo.update(vm.indirizzo).$promise.then(function () {
                    vm.ordine.indirizzoId = vm.indirizzo.id;
                });
            }).then(function () {
                if (isTakeaway) {
                    return;
                }
                IndirizzoStore.setFromAddressBook(vm.indirizzo, IndirizzoStore.get().zone);
                return Promise.resolve();
            }).then(function () {
                return salvaOrdine();
            }).then(function (ordine) {
                if (vm.utilizzaCredito === true) {
                    var totaleDaPagareSenzaCredito = (vm.ordine.importoOrdine + getCostoSpedizione() + vm.debito) - vm.ordine.scontoCoupon; // è una fix
                    if (vm.totaleCredito > totaleDaPagareSenzaCredito) { // sto pagando l'ordine interamente con il credito. Il credito residuo sarà >= 0
                        payWithoutPayments();
                        return null;
                    }
                }
                openPaymentsModal(vm.ordine);
            }).catch(function (error) {
                Notification.error({
                    message: $translate.instant('theFoodApp.concludi.error.salvataggioOrdine'),
                    delay: NOTIFICATION_TIME
                });
                vm.oraConsegnaSelezionata = null;
            }).finally(function () {
                vm.isSaving = false;
            });
        }

        function getLocale() {
            return translationStorageProvider.getCurrent();
        }


        function salvaOrdine() {
            // Prendo l'estremo sinistro dell'intervallo
            var oraConsegna = _.split(vm.oraConsegnaSelezionata.label, '-', 1);
            if (vm.oraConsegnaSelezionata.value === 'PrimaPossibile') {
                vm.ordine.orarioConsegna = null;
            } else {
                vm.ordine.orarioConsegna = _.trim(oraConsegna[0]);
            }

            vm.ordine.dataConsegna = vm.dataConsegnaSelezionata;
            //vm.ordine.importoSpedizione = getCostoSpedizione();
            if (!isTakeaway) {
                vm.ordine.zona = IndirizzoStore.get().zone;
            }
            vm.ordine.datiFatturazioneId = vm.emettiFattura ? vm.idFatturazione : null;
            vm.ordine.tipoOrarioConsegna = vm.oraConsegnaSelezionata.value === 'PrimaPossibile' ? 'PRIMA_POSSIBILE' : 'STANDARD';

            // Aggiorno il codice lotteria sul cliente
            if (vm.codiceLotteria !== vm.cliente.codiceLotteria) {
                vm.cliente.codiceLotteria = vm.codiceLotteria;
                Cliente.update(vm.cliente);
            }

            // check se posso salvare il codiceLotteria su ordine altrimenti aggiorno l'ordine eliminando il codice
            if (vm.checkCodiceLotteria && vm.codiceLotteria !== null && _.trim(vm.codiceLotteria) !== '' && _.trim(vm.codiceLotteria).length === 8) {
                vm.ordine.codiceLotteria = _.trim(vm.codiceLotteria);
            } else {
                vm.ordine.codiceLotteria = null;
            }

            if (vm.coupon_code || vm.codiceCouponInserito) {
                if (vm.coupon_code.length > 0) {
                    vm.ordine.codiceCoupon = vm.coupon_code;
                } else {
                    vm.ordine.codiceCoupon = vm.codiceCouponInserito;
                }
                return Ordine.updateWithCouponCode(vm.ordine).$promise.catch(function (e) {
                }).then(function (res) {
                    if (res.status === "SUCCESS") {
                        if (vm.codiceCouponInserito.length === 0) {
                            if (res.code === 0) {
                                vm.saved1 = true;
                                vm.codiceCouponInserito = vm.coupon_code;
                            } else if (res.code === 7) {
                                Coupon.getValueCouponCredito({
                                    codice: vm.coupon_code
                                }, onSuccess, onError);

                                function onSuccess(data1, headers) {
                                    if (data1.status === "SUCCESS") {
                                        var modalInstance = $uibModal.open({
                                            animation: true,
                                            templateUrl: 'app/pages/confirm-coupon-credito/confirm-coupon-credito.html',
                                            controller: 'ConfirmCouponCreditoController',
                                            controllerAs: 'vm',
                                            backdrop: 'static',
                                            keyboard: false,
                                            resolve: {
                                                couponCode: function () {
                                                    return data1.value;
                                                }
                                            }
                                        });
                                        modalInstance.result.then(function (parameter) {
                                            if (parameter !== 'cancel') {
                                                Coupon.saveCouponCredito({
                                                    codice: vm.coupon_code
                                                }, onSuccess, onError);

                                                function onSuccess(data2, headers) {
                                                    if (data2.status === "SUCCESS") {
                                                        vm.saved2 = true;
                                                        vm.coupon_code = '';
                                                        getTotaleCredito();
                                                    } else {
                                                        checkErrors(data2.status, data2.code)
                                                        return vm.ordine;
                                                    }
                                                }

                                                function onError(error) {
                                                    AlertService.error(error.data2.message);
                                                }
                                            }
                                        });
                                    } else {
                                        checkErrors(data1.status, data1.code)
                                        return vm.ordine;
                                    }
                                }

                                function onError(error) {
                                    AlertService.error(error.data1.message);
                                }
                            }
                            $timeout(function () {
                                vm.saved1 = false;
                                vm.saved2 = false;
                            }, 3000);
                            return res.value;
                        }
                    } else if (res.status === "ERROR") {
                        checkErrors(res.status, res.code)
                        return vm.ordine;
                    }
                });
            } else {
                return Ordine.update(vm.ordine).$promise;
            }
        }


        function onDataChanged() {
            vm.modifyOrderCondition = true;
            vm.dataOraConfirmed = false;
            vm.oraConsegnaSelezionata = null;
        }

        function onOraChanged() {
            vm.dataOraConfirmed = false;
            vm.showAlertDataOraNotConfirmed = false;

            if (vm.oraConsegnaSelezionata) {
                calcolaCostiOrdine();
            }
        }

        function calcolaCostiOrdine() {
            vm.dataOraLoading = true;
            return Promise.all([
                salvaOrdine(),
                Promise.delay(1250)
            ]).spread(function (ordine) {
                vm.dataOraConfirmed = true;
                vm.ordine = ordine;
            }).catch(function (e) {
                Notification.error({
                    title: $translate.instant('theFoodApp.concludi.error.calcolaCostiOrdine'),
                    delay: NOTIFICATION_TIME
                });
            }).finally(function () {
                vm.dataOraLoading = false;
            });
        }

        function localeEmetteFattura() {
            return locale.emetteFattura;
        }

        function openPaymentsModal(ordine) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/pages/concludi-acquisto/payments/payments.html',
                controller: 'PaymentsController',
                controllerAs: 'vm',
                resolve: {
                    ordine: ordine,
                    useCredit: vm.utilizzaCredito,
                    totalWithUseCredit: totaleCarrello(),
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('concludiAcquisto');
                        return $translate.refresh();
                    }]
                }
            });
            modalInstance.result.then(function (parameter) {
            }, function (parameter) {
                if (parameter !== 'cancel') {
                    checkErrors(parameter, Number(parameter));
                }
            });
            return Promise.resolve(modalInstance.result);
        }

        function checkErrors(status, code) {

            if (code === 1) {
                vm.saveFailed1 = true;
            }
            if (code === 2) {
                vm.saveFailed2 = true;
            }
            if (code === 3) {
                vm.saveFailed3 = true;
            }
            if (code === 4) {
                vm.saveFailed4 = true;
            }
            if (code === 5) {
                vm.saveFailed5 = true;
            }
            if (code === 6) {
                vm.saveFailed6 = true;
            }
            if (code === undefined) {
                if (status === 'INV-URL' || 'ERROR') {
                    vm.isPaymentError = true;
                }
            }
            $timeout(function () {
                vm.saveFailed1 = false;
                vm.saveFailed2 = false;
                vm.saveFailed3 = false;
                vm.saveFailed4 = false;
                vm.saveFailed5 = false;
                vm.saveFailed6 = false;
                vm.isPaymentError = false;
            }, 4000);
        }


        activate();

        return vm;


        function getTotaleCredito() {
            MovimentiCredito.totale({}, onSuccess, onError);

            function onSuccess(data, headers) {
                //_.forEach(data, function(movimento) {
                if (data.totale > 0) {
                    vm.totaleCredito = data.totale;
                    vm.debito = 0;
                    vm.utilizzaCredito = true;
                } else {
                    vm.totaleCredito = 0;
                    vm.debito = (data.totale) * (-1);
                    vm.utilizzaCredito = false;
                }
                //});
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function payWithoutPayments() {
            var params = {
                ordineId: vm.ordine.id,
                issuer: 'Credit',
                couponCode: vm.codiceCouponInserito,
                useCredit: 'true',
                device: $scope.isMobile() ? 'ANDROID' : 'WEB'
            };

            bsLoadingOverlayService.start({referenceId: 'body'});
            Pagamento.request(params).$promise
                .then(function (result) {
                    if ($scope.isMobile()) {
                        var ref = cordova.InAppBrowser.open(result.redirectUrl, '_self', 'location=no');
                        var shouldReload = true;
                        ref.addEventListener('loadstart', function (event) {
                            if (event.url.match("#/paymentOk/|#/paymentError/|#/paymentCancel/")) {
                                var newURL = event.url.substring(event.url.indexOf('#'));
                                $window.location.hash = newURL;
                                $window.location.reload(true);
                                shouldReload = false;
                                ref.close();
                            }
                        });
                        ref.addEventListener('exit', function () {
                            if (shouldReload) {
                                $window.location.reload(true);
                            }

                        });
                    } else {
                        $window.location.href = result.redirectUrl;
                    }
                })
                .catch(function (err) {
                    bsLoadingOverlayService.stop({referenceId: 'body'});
                })
                .finally(function () {
                    cancel();
                    if (isSafari || $scope.isMobile()) {
                        // Issue #109: Premendo back su safari o da app rimane il loading overlay attivo.
                        bsLoadingOverlayService.stop({referenceId: 'body'});
                    }
                });
        }

    }
})();
