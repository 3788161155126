(function() {
    'use strict';

    angular
        .module('theFoodApp')
        .controller('InsegnaDetailController', InsegnaDetailController);

    InsegnaDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Insegna', 'Locale', 'TipologiaLocale'];

    function InsegnaDetailController($scope, $rootScope, $stateParams, previousState, entity, Insegna, Locale, TipologiaLocale) {
        var vm = this;

        vm.insegna = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('theFoodApp:insegnaUpdate', function(event, result) {
            vm.insegna = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
