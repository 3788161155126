(function () {
    'use strict';

    angular
        .module('theFoodApp')
        .controller('SocialAppleController', SocialAppleController);

    SocialAppleController.$inject = ['$filter', '$stateParams', 'Auth', '$state'];

    function SocialAppleController($filter, $stateParams, Auth, $state) {
        var vm = this;
        vm.success = $stateParams.success;
        if (vm.success === 'true') {
            vm.token = $stateParams.token;
            let dto = {
                identityToken: vm.token
            }
            Auth.loginWithApple(dto).then(function () {
                return $state.go('landing');
            });
        }
    }
})();
