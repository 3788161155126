(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.controller('PaymentCancelController', PaymentCancelController);

	function PaymentCancelController( $state, $stateParams ) {
    var vm = this;

		return vm;
  }

}) ();