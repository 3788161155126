(function() {
	'use strict';

	angular.module('theFoodApp').controller('SelezioneLocaleController',
		SelezioneLocaleController);

	function SelezioneLocaleController($state, insegne, isTakeaway, InformazioniAperturaOdierna) {
		var vm = this;

		vm.collapsed = {};
		vm.onInsegnaClicked = onInsegnaClicked;
		vm.insegne = insegne;
		vm.isTakeaway = isTakeaway;
		vm.informazioniAperturaOdierna = informazioniAperturaOdierna;
		vm.mostraStringaProssimaConsegnaUtile = mostraStringaProssimaConsegnaUtile;
		vm.mostraGiornoProssimaConsegna = mostraGiornoProssimaConsegna;
		vm.nessunaDataDisponibile = nessunaDataDisponibile;

		function onInsegnaClicked(insegna) {
			if (insegna.locales.length === 1) {
				$state.go('locale', {
					t: isTakeaway ? insegna.locales[0].id : undefined,
					i: insegna.id
				});
			} else {
				vm.collapsed[insegna.id] = true;
			}
		}

		function mostraStringaProssimaConsegnaUtile(locale) {
			return InformazioniAperturaOdierna.getInformazioniAperturaOdierna(locale).prossimaConsegnaUtile && InformazioniAperturaOdierna.getInformazioniAperturaOdierna(locale).orarioPrimaConsegna;
		}

		function informazioniAperturaOdierna(locale) {
			return InformazioniAperturaOdierna.getInformazioniAperturaOdierna(locale);
		}

		function mostraGiornoProssimaConsegna(locale){
			return InformazioniAperturaOdierna.getInformazioniAperturaOdierna(locale).giornoProssimaConsegna === null ? false : true;
		}

		function nessunaDataDisponibile(locale){
			return InformazioniAperturaOdierna.getInformazioniAperturaOdierna(locale).nessunaDataDisponibile;
		}

		return vm;

	}

})();
