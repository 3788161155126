(function() {
    'use strict';

    angular
        .module('theFoodApp')
        .factory('DatiFatturazioneSearch', DatiFatturazioneSearch);

    DatiFatturazioneSearch.$inject = ['$resource', 'SERVER_URL'];

    function DatiFatturazioneSearch($resource, SERVER_URL) {
        var resourceUrl =  SERVER_URL + 'api/_search/dati-fatturaziones/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
