
/**
 * La direttiva imposta la larghezza di un elemento HTML in base alla larghezza di un suo contenitore con la classe outer-affix.
 * Questo serve ad escludere dei bruschi salti di dimensione quando si passa da un elemento in-flow (es, position: static) ad uno out-of-flow (es, position: fixed).
 * L'uso primario della direttiva è quello di rendere liscia la transizione fra un componente in modalità affix e la relativa modalità ancorata.
 */

( function() {
	'use strict';

	angular
		.module( 'theFoodApp' )
		.directive( 'innerAffix', innerAffix );

	function innerAffix( $window, $timeout, $log ) {

		var directive = {
			restrict: 'C',
			link: function(scope, element) {

				var parent = element.parent( '.outer-affix' ),
					timeout;

				if ( parent.length === 0 ) {
					$log.warn( 'No outer-affix parent found for ' + element[0] );
					return;
				}

				function recalculateWidth() {
					element.width( parent.width() );
				}

				angular.element( $window ).bind( 'resize', function() {
					$timeout.cancel( timeout );
					timeout = $timeout( recalculateWidth, 50 );
				} );

				recalculateWidth();

			}
		};

		return directive;
	}

})();
