(function() {
	'use strict';
	angular
			.module('theFoodApp')
			.factory('Pagamento', Pagamento);

	Pagamento.$inject = ['$resource', 'SERVER_URL'];

	function Pagamento ($resource, SERVER_URL) {
		var resourceUrl =  SERVER_URL + 'api/v1/pagamento/:id';

		return $resource(resourceUrl, {}, {
			get: {
				method: 'GET',
				isArray: false,
				cache: true
			},
			request: {
				method: 'GET',
				isArray: false,
				url: resourceUrl + '/request/:ordineId?issuer=:issuer',
				params: {
					ordineId: '@ordineId',
					issuer: '@issuer'
				},
				cache: false
			},	
			requestWithCouponAndCredit: {
				method: 'GET',
				isArray: false,
				url: resourceUrl + '/request/:ordineId?issuer=:issuer&couponCode=:couponCode&useCredit=:useCredit',
				params: {
					couponCode: '@couponCode',
					useCredit: '@useCredit',
					ordineId: '@ordineId',
					issuer: '@issuer'
				},
				cache: false
			},
			transaction: {
				method: 'GET',
				isArray: false,
				url: resourceUrl + '/transaction/:transactionId',
				params: {
					transactionId: '@transactionId'
				},
				cache: false
			}
		});
	}
})();
