(function () {
    'use strict';

    angular
        .module('theFoodApp')
        .controller('SelezioneIndirizzoController', SelezioneIndirizzoController);

    function SelezioneIndirizzoController(addressBook, SERVER_URL, Versione, $uibModal, DownloadAppToggle, $window) {
        var vm = this;

        function onSuccessGetNeedUpdate(data) {

            if (!data.compatible)
                showModalGetNeedUpdate();
        }

        function onErrorGetNeedUpdate() {
            showModalGetNeedUpdate();
        }

        if (window.device !== undefined && (window.device.platform === "Android" || window.device.platform === "iOS")) {

            /* var versionCode;
             $window.cordova.getAppVersion.getVersionCode(function (version) {
                 console.log("version code", version);
                 versionCode = version;
             });*/

            Versione.getNeedUpdate({codiceVersion: "10154"}, onSuccessGetNeedUpdate, onErrorGetNeedUpdate);

        }

        function showModalGetNeedUpdate() {

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/pages/selezione-indirizzo/modal-update-app/modal-update-app.html',
                controller: 'UpdateAppController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false


            });

            return Promise.resolve(modalInstance.result);
        }


        vm.showAddressBook = addressBook.length > 0;
        vm.getNotePage = getNotePage;
        vm.getInfoCommerciali = getInfoCommerciali;

        function getNotePage() {
            return SERVER_URL + '/static/pages/note.html';
        }

        function getInfoCommerciali() {
            return SERVER_URL + '/static/pages/informazioni-commerciali.html';
        }

        return vm;
    }

})();
