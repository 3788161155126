(function () {
    'use strict';

    angular
        .module('theFoodApp')
        .controller('SocialAuthController', SocialAuthController);

    SocialAuthController.$inject = ['$state', '$cookies', 'Auth'];

    function SocialAuthController($state, $cookies, Auth) {
        var token = $cookies.get('social-authentication');
        
        Auth.loginWithToken(token).then(function () {
            $cookies.remove('social-authentication');
            Auth.authorize(true);
            var redirect = $cookies.getObject('social-redirect');
            if (angular.isDefined(redirect)){
            	$cookies.remove('social-redirect');
            	location.href =redirect;
            }else{
            	 $state.go('home');
            }
            $cookies.putObject('social-redirect', $state.current);
        }, function () {
            $state.go('social-register', {'success': 'false'});
        });
    }
})();
