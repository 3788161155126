
( function() {
	'use strict';

	angular
		.module( 'theFoodApp' )
		.directive( 'affix', affix );

	function affix( $window, $timeout, $log ) {

		var directive = {
			restrict: 'A',
			link: function(scope, element) {

				var elementEl = element[0],
					wasActive = false,
					// offset = 20,
					offset = 120,
					cls = 'active';

				function handleScroll() {
					var pos = elementEl.getBoundingClientRect(),
						active = pos.top < offset;

					if ( active !== wasActive ) {
						element.toggleClass( cls, active );
						wasActive = active;
					}
				}

				angular.element( window )
					.on( 'scroll', handleScroll )
					.on( 'resize', handleScroll )
					.triggerHandler( 'scroll' );

			}
		};

		return directive;
	}

})();
