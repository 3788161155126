(function () {
    'use strict';

    angular
        .module('theFoodApp')
        .controller('LoginController', LoginController);

    function LoginController($rootScope, $state, $timeout, $window, Auth, banner, $uibModalInstance) {
        var vm = this;


        vm.isMobile = $rootScope.isMobile();

        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;
        vm.banner = banner;
        vm.callGoogle = callGoogle;
        vm.callApple = callApple;
        vm.callFacebook = callFacebook;
        vm.signAppleFromWeb = signAppleFromWeb;
        vm.isAvailableAppleSignIn = function () {
            if (window.device !== undefined && window.device.platform === "iOS" && window.device.version >= "13") {
                return true;
            } else return false;
        }

        vm.isAndroid = function () {
            if (window.device !== undefined && window.device.platform === "Android") {
                return true;
            } else return false;
        }

        /*===========================================================*/

        function callFacebook() {
            var facebookUser = {};
            var fbLoginSuccess = function (userData) {
                console.log("UserInfo: ", userData);
                facebookConnectPlugin.getAccessToken(function (token) {
                    console.log("Token: " + token);
                    facebookConnectPlugin.api("/me?fields=birthday,email,first_name,last_name,id,link,picture%7Burl%7D,name", ['public_profile', 'email'],
                        function onSuccess(result) {
                            facebookUser.birthday = result.birthday;
                            facebookUser.firstName = result.first_name;
                            facebookUser.lastName = result.last_name;
                            facebookUser.profileUrl = result.link;
                            //facebookUser.userId = result.id;
                            facebookUser.email = result.email;
                            facebookUser.imageUrl = result.picture.data.url;
                            facebookUser.displayName = result.name;
                            facebookUser.accessToken = userData.authResponse.accessToken;
                            facebookUser.userId = userData.authResponse.userID;
                            facebookUser.expiresIn = userData.authResponse.expiresIn;
                            console.log("Result: ", facebookUser);
                            Auth.loginWithFacebook(facebookUser).then(function () {
                                vm.authenticationError = false;
                                $uibModalInstance.close();
                            }).catch(function () {
                                vm.authenticationError = true;
                                vm.banner = null;
                            });
                        }, function onError(error) {
                            console.log("Failed: ", error);
                        }
                    );
                });
            }

            facebookConnectPlugin.login(["public_profile"], fbLoginSuccess,
                function (error) {
                    console.error(error)
                }
            );
        }

        function callGoogle() {
            window.plugins.googleplus.login(
                {
                    'scopes': 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
                    'webClientId': '632217101407-uj6okaf8ajtccbhh9r5l27ejd47k1qhh.apps.googleusercontent.com',
                    'offline': true
                },
                function (obj) {
                    vm.authenticationError = false;
                    Auth.loginWithGoogle(obj).then(function () {
                        vm.authenticationError = false;
                        $uibModalInstance.close();
                    }).catch(function () {
                        vm.authenticationError = true;
                        vm.banner = null;
                    });

                },
                function (msg) {
                    alert('error: ' + msg);
                }
            );
        }

        function isAvailableAppleSignIn() {
            return true;
        }

        function callApple() {
            let userCancelRequestCode = "1001";
            /*requestedScopes is an array of requested scopes.
            0: FullName
            1: Email*/
            if (window.cordova) {
                if (window.cordova.plugins && window.cordova.plugins.SignInWithApple) {
                    window.cordova.plugins.SignInWithApple.signin(
                        {requestedScopes: [0, 1]},
                        function (obj) {
                            vm.authenticationError = false;
                            Auth.loginWithApple(obj).then(function () {
                                vm.authenticationError = false;
                                $uibModalInstance.close();
                            }).catch(function () {
                                vm.authenticationError = true;
                                vm.banner = null;
                            });
                        },
                        function (err) {
                            if (err.code !== userCancelRequestCode)
                                alert('error: ' + err.localizedDescription);
                        }
                    );
                }
            }
        }

        function signAppleFromWeb() {
            let appleJSRedirect = Auth.getRedirectAppleJS();
            let mode = '_blank';
            if ($rootScope.isMobile()) {
                var ref = window.cordova.InAppBrowser.open(appleJSRedirect, mode, 'location=no');
                var shouldReload = true;
                ref.addEventListener('loadstart', function (event) {
                    if (event.url.match("#/social-apple/")) {
                        var newURL = event.url.substring(event.url.indexOf('#'));
                        $window.location.hash = newURL;
                        $window.location.reload(true);
                        shouldReload = false;
                        ref.close();
                    }
                });
                ref.addEventListener('exit', function () {
                    if (shouldReload) {
                        $window.location.reload(true);
                    }
                });
            } else {
                $window.location.href = appleJSRedirect;
            }
        }

        function cancel() {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
            $uibModalInstance.dismiss('cancel');
        }

        function login(event) {
            vm.authenticationError = false;
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                $uibModalInstance.close();
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('landing');
                }

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function () {
                vm.authenticationError = true;
                vm.banner = null;
            });
        }

        function register() {
            $uibModalInstance.dismiss('cancel');

            $state.go('register', {
                redirectUrl: location.href
            });
        }

        function requestResetPassword() {
            $uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }
    }
})();
