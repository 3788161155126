(function(){
	'use strict';

	angular
		.module('theFoodApp')
		.service('RieffettuaOrdine', RieffettuaOrdine);

	function RieffettuaOrdine(SERVER_URL, $resource, DateUtils){
		var resourceUrl = SERVER_URL + 'api/v1/ordini/rieffettua-ordine';

		return $resource(resourceUrl, {}, {
			'riordina': {
				method: 'POST',
				transformRequest: function(data) {
					var copy = angular.copy(data);
					copy.dataConsegna = DateUtils.convertLocalDateToServer(copy.dataConsegna);
					copy.dataPagamento = DateUtils.convertLocalDateToServer(copy.dataPagamento);
					copy.dataOrdine = DateUtils.convertLocalDateToServer(copy.dataOrdine);
					return angular.toJson(copy);
				}
			}
		});
	}
})();
