
( function() {
	'use strict';

	angular
		.module( 'theFoodApp' )
		.directive( 'articolo', articolo );

	function articolo() {

		var directive = {
			restrict: 'E',
			scope: {
				rigaOrdine: '=',
				sceltaMenu: '=',
				carrelloInstance: '=carrello'
			},
			templateUrl: 'app/pages/carrello/articolo.directive.html',
			bindToController: true,
			controllerAs: 'vm',
			controller: ArticoloController
		};

		return directive;

	}

	/* @ngInject */
	function ArticoloController($uibModal, $scope, $translate, $timeout, Notification, DOUBLE_CLICK_TIME, NOTIFICATION_TIME) {

		var vm = this;
		var lang = $translate.proposedLanguage() || $translate.use();
		// Interface

		vm.articolo = vm.sceltaMenu || vm.rigaOrdine;
		vm.modificaAggiunte = modificaAggiunte;
		vm.modificaVarianti = modificaVarianti;
		vm.rimuoviArticolo = rimuoviArticolo;

		vm.getPrezzoProdotto = vm.carrelloInstance.getPrezzoProdotto;
		var doubleClickGuard = false;

		// Implementation

		function rimuoviArticolo(articolo){
			if(doubleClickGuard)
				return Promise.resolve();

			doubleClickGuard = true;
			$timeout(function(){
				doubleClickGuard = false;
			}, DOUBLE_CLICK_TIME);

			vm.carrelloInstance.rimuoviArticolo(articolo).then(function() {
				Notification.success({
					message: lang == 'it' ? articolo.prodotto.descrizioneIt : articolo.prodotto.descrizioneEn,
					title: $translate.instant('theFoodApp.locale.articoloRimosso'),
					delay: NOTIFICATION_TIME
                });

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'removedProductFromCart'
                });
			}, function() {
				Notification.error({
					message: lang == 'it' ? articolo.prodotto.descrizioneIt : articolo.prodotto.descrizioneEn,
					title: $translate.instant('theFoodApp.locale.articoloNonRimosso'),
					delay: NOTIFICATION_TIME
				});
			});
		}

		function modificaAggiunte() {

			if ( !vm.articolo.prodotto.aggiunteDisponibili )
				return false;

			var modal = $uibModal.open({
				controller : 'AggiunteController as vm',
				templateUrl : 'app/pages/carrello/articolo.aggiunte.dialog.html',
				backdrop: 'static',
				resolve : {
					carrelloInstance : function() {
						return vm.carrelloInstance;
					},
					rigaOrdine : function() {
						return vm.rigaOrdine;
					},
					sceltaMenu : function() {
						return vm.sceltaMenu;
					},
					aggiunte : function() {
						return vm.carrelloInstance.getAggiunte( vm.rigaOrdine.id, vm.sceltaMenu ? vm.sceltaMenu.id : null );
					},
					translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
						$translatePartialLoader.addPart('aggiunte');
						$translatePartialLoader.addPart('carrello');
						return $translate.refresh();
					} ]
				}
			});

			return modal.result;

		}

		function modificaVarianti( riga ) {

			if (
					(!vm.articolo.dettaglioVariantes || !vm.articolo.dettaglioVariantes.length) &&
					(!vm.articolo.prodotto.aggiunteDisponibili)
				)
				return false;

			var modal = $uibModal.open({
				controller : 'VariantiController as vm',
				templateUrl : 'app/pages/carrello/articolo.varianti.dialog.html',
				backdrop: 'static',
				resolve : {
					carrelloInstance : function() {
						return vm.carrelloInstance;
					},
					rigaOrdine : function() {
						return vm.rigaOrdine;
					},
					sceltaMenu : function() {
						return vm.sceltaMenu;
					},
					varianti : function() {
						return vm.carrelloInstance.getVarianti( vm.rigaOrdine.id, vm.sceltaMenu ? vm.sceltaMenu.id : null );
					},
					aggiunte : function() {
						return vm.carrelloInstance.getAggiunte( vm.rigaOrdine.id, vm.sceltaMenu ? vm.sceltaMenu.id : null );
					},
					translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
						$translatePartialLoader.addPart('varianti');
						$translatePartialLoader.addPart('aggiunte');
						return $translate.refresh();
					} ]
				}
			});

			return modal.result;

		}

	}

} )();
