(function() {

	angular
		.module('theFoodApp')
		.factory('RouteError', RouteErrorFactory)
		.run(setupStateChangeHandlers);

	function RouteErrorFactory($translate) {

		// var handlingRouteChangeError = false;

		function RouteError(message, newState) {
			this.name = 'RouteError';
			this.stack = (new Error()).stack;
			this._errorMessage = message;
			this._redirectTo = newState;
		}
		RouteError.prototype = Object.create(Error.prototype);
		RouteError.prototype.constructor = RouteError;

		RouteError.prototype.redirectTo = function(newState) {
			this._redirectTo = newState;
			return this;
		};
		RouteError.prototype.errorMessage = function(message) {
			this._errorMessage = message;
			return this;
		};
		RouteError.prototype.error = function(err) {
			this.stack = err.stack;
			return this;
		};
		RouteError.prototype.translatedErrorMessage = function(messageCode, messageParameters) {
			this._errorMessage = $translate.instant(messageCode, messageParameters);
			return this;
		};
		RouteError.prototype.getErrorMessage = function() {
			return this._errorMessage;
		};
		RouteError.prototype.getRedirectTo = function() {
			return this._redirectTo;
		};

		return RouteError;
	}

	function setupStateChangeHandlers($rootScope, $state, $log, $document, $location, AlertService, RouteError, HOME_PAGE) {

		// eslint-disable-next-line angular/on-watch
		$rootScope.$on('$stateChangeError', function(evt, newState, newStateParams, oldState, oldStateParams, error) {

			var message = 'Error when trying to reach ' + newState.name;
			if (oldState && oldState.name !== '') message += ' from ' + oldState.name;
			message += ':';
			console.warn(message, error);

			if (error instanceof RouteError) {
				error._from = oldState;
				error._to = newState;

				if (error.getErrorMessage()) {
					AlertService.error(error.getErrorMessage());
				}
				if (error.getRedirectTo()) {
					$state.go(error.getRedirectTo());
				}
			}

		});

		// eslint-disable-next-line angular/on-watch
		$rootScope.$on('$stateChangeSuccess', function() {
			$document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;
		});

	}

})();
