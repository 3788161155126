(function() {
    'use strict';
    angular.module('theFoodApp').config(stateConfig);
    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('areaPersonale', {
            parent: 'app',
            url: '/area-personale',
            reloadOnSearch: false,
            data: {
				authorities: ['ROLE_USER'],
                pageTitle: 'theFoodApp.area-personale.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/area-personale/area-personale.html',
                    controller: 'AreaPersonaleController',
                    controllerAs: 'vm'
                }
            },
            breadcrumb: {
                title: "{{ 'theFoodApp.area-personale.title' | translate }}"
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('cliente');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
