(function(){
	'use strict';

	angular
		.module('theFoodApp')
		.service("RecuperaPromozioniService", RecuperaPromozioniService);

	function RecuperaPromozioniService($resource, SERVER_URL){
		var resourceUrl = SERVER_URL + 'api/public/v1/promozioni/:insegnaId';

		return $resource(resourceUrl, {}, {
			'get': {
				method: 'GET',
				isArray: true,
				params: {
					isMobile: '@isMobile'
				}
			}
		});
	}
})();