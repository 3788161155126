(function() {
    'use strict';

    angular
        .module('theFoodApp')
        .controller('RegisterController', RegisterController);

    function RegisterController($translate, $timeout, Auth, LoginService, $state, $stateParams, $anchorScroll) {
        var vm = this;

        vm.doNotMatch = null;
        vm.error = null;
        vm.errorUserExists = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.registerAccount = {};
        vm.success = null;
        vm.goPrivacyPolicy = goPrivacyPolicy;

        //garante della privacy
        // vm.registerAccount.consensoCondizioni = vm.registerAccount.consensoCondizioni ? vm.registerAccount.consensoCondizioni : true;
        // vm.registerAccount.consensoProfilazione = vm.registerAccount.consensoProfilazione ? vm.registerAccount.consensoProfilazione : true;;
        // vm.registerAccount.consensoCommerciale = vm.registerAccount.consensoCommerciale ? vm.registerAccount.consensoCommerciale : true;;

        vm.genderList = [{
                "id": "M",
                "value": 'global.form.genderMale'
            },
            {
                "id": "F",
                "value": 'global.form.genderFemale'
            }
        ];

        vm.datepickerOptions = {
            showWeeks: false,
            maxDate: new Date()
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dataDiNascita = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function register() {
            if (vm.registerAccount.password !== vm.confirmPassword) {
                vm.doNotMatch = 'ERROR';
                $anchorScroll();
            } else {
                vm.registerAccount.langKey = $translate.use();
                vm.doNotMatch = null;
                vm.error = null;
                vm.errorUserExists = null;
                vm.errorEmailExists = null;
                vm.errorPhoneExists = null;
                vm.registerAccount.login = vm.registerAccount.email;
                Auth.createAccount(vm.registerAccount).then(function() {
                    vm.success = 'OK';
                }).then(function() {
                    /**
                     * Quando la registrazione è completata,
                     * pusho l'evento.
                     */
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'registrationComplete'
                    });
                }).then(function() {
                    return autoLoginAndRedirect();
                }).catch(function(response) {
                    vm.success = null;
                    if (response.status === 400 && response.data === 'login already in use') {
                        vm.errorUserExists = 'ERROR';
                        $anchorScroll();
                    } else if (response.status === 400 && response.data === 'e-mail address already in use') {
                        vm.errorEmailExists = 'ERROR';
                        $anchorScroll();
                    } else if (response.status === 400 && response.data === 'phone number already in use') {
                        vm.errorPhoneExists = 'ERROR';
                        $anchorScroll();
                    } else {
                        vm.error = 'ERROR';
                        $anchorScroll();
                    }
                });
            }
        }

        function goPrivacyPolicy(){
            $state.go('static-page.privacy-policy');
        }

        // Login automatico dopo registrazione e redirect diretto sulla
        // pagina di concludi-acquisto se registrazione effettuata in fase di ordinazione
        function autoLoginAndRedirect() {
            return Auth.login({
                username: vm.registerAccount.email,
                password: vm.registerAccount.password,
                rememberMe: true
            }).then(function() {
                location.href = $stateParams.redirectUrl;
            });
        }
    }
})();
