( function() {
	'use strict';

	angular
		.module( 'theFoodApp' )
		.factory( 'TrovaZona', TrovaZona );

	TrovaZona.$inject = [ '$resource', 'SERVER_URL' ];

	function TrovaZona( $resource, SERVER_URL ) {
		var resourceUrl = SERVER_URL + 'api/public/v1/trovazona';

		return $resource( resourceUrl, {}, {
			civico: {
				method: 'GET',
				isArray: false,
				url: resourceUrl + '/civico?civico=:civico',
				params: {
					civico: '@civico',
				},
				cache: true
			},
			autocomplete: {
				method: 'GET',
				isArray: true,
				url: resourceUrl + '/autocomplete?query=:query',
				params: {
					query: '@query',
				},
				cache: true
			},
			zona: {
				method: 'GET',
				isArray: false,
				url: resourceUrl + '/zona?stradaId=:stradaId&civico=:civico',
				params: {
					stradaId: '@stradaId',
					civico: '@civico',
				},
				cache: true,
				transformResponse: function( data ) {
					return { id: data };
				}
			}
		} );
	}
} )();
