(function() {
    'use strict';
    angular
        .module('theFoodApp')
        .factory('Provincia', Provincia);

    Provincia.$inject = ['$resource', 'SERVER_URL'];

    function Provincia ($resource, SERVER_URL) {
        var resourceUrl =  SERVER_URL + 'api/v1/public/province/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
