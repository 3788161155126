(function () {
    'use strict';

    angular
        .module('theFoodApp')
        .controller('UpdateAppController', UpdateAppController);

    function UpdateAppController($scope, $window, $state, $translate, $uibModalInstance, MobileAppService) {
        var vm = this;

        vm.downloadApp = downloadApp;

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function downloadApp() {
            var url = MobileAppService.getStoreUrlForDevice();
            $window.open(url, '_blank');
        }

        return vm;
    }


})();
