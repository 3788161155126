(function() {
	'use strict';
	angular
		.module('theFoodApp')
		.factory('AnnullaPagamento', AnnullaPagamento);

		function AnnullaPagamento ($resource, SERVER_URL) {
			var resourceUrl = SERVER_URL + 'api/v1/ordini/sblocca-ordine';

		return $resource(resourceUrl, {}, {
			'sblocca': {
				method: 'POST',
				params: {
					id: '@id'
				}
			}
		});
	}
})();
