( function() {
	'use strict';

	angular
		.module( 'theFoodApp' )
		.filter( 'languageSwitch', languageSwitch );

	function languageSwitch( translationStorageProvider ) {

		return function( obj, keyIta, keyEng ) {
			var key = translationStorageProvider.getCurrent() === 'it' ? keyIta : keyEng;
			return obj[ key ];
		};

	}

} )();
