(function() {
	'use strict';

	angular
	.module('theFoodApp', [
		'ngStorage',
		'ngAnimate',
		'tmh.dynamicLocale',
		'pascalprecht.translate',
		'ngResource',
		'ngCookies',
		'ngAria',
		'ngCacheBuster',
		'ngFileUpload',
		'ui.bootstrap',
		'ui.bootstrap.datetimepicker',
		'ui.router',
		'infinite-scroll',
		// jhipster-needle-angularjs-add-module JHipster will add new module here
		'angular-loading-bar',
		'smoothScroll',
		'duScroll',
		'bsLoadingOverlay',
		'ui-notification',
        'angular-cookie-law',
        'angulartics',
        'angulartics.google.analytics',
        'angulartics.scroll'
	])
	.config(function($sceDelegateProvider, SERVER_URL) {
		$sceDelegateProvider.resourceUrlWhitelist([
			'self',
			SERVER_URL + '**'
		]);
	}).run(run);

	function run(stateHandler, translationHandler, $rootScope, $state, $log, AlertService, bsLoadingOverlayService, SERVER_URL, APP_TITLE) {
		stateHandler.initialize();
		translationHandler.initialize();

		Promise.setScheduler(function(cb) {
			$rootScope.$evalAsync(cb);
		});

		bsLoadingOverlayService.setGlobalConfig({
			templateUrl: 'static/pages/loading-overlay-template.html'
		});

		// Lodash template interpolation settings: Now interpolates {{x}} as template variables.
		_.templateSettings.interpolate = /{{([\s\S]+?)}}/g;
		$rootScope.SERVER_URL = SERVER_URL;
		
		
		window.document.title = APP_TITLE;

		$rootScope.isMobile = function() {
			if (window.cordova) {
				return true;
			} else {
				return false;
			}
		};


	}
})();
