( function() {
	'use strict';

	angular
		.module( 'theFoodApp' )
		.config( stateConfig );

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig( $stateProvider ) {

		$stateProvider.state( 'selezione-locale', {
			abstract: true,
			parent: 'selezione-indirizzo',
			resolve: {
				translatePartialLoader: [ '$translate', '$translatePartialLoader', function( $translate, $translatePartialLoader ) {
					$translatePartialLoader.addPart( 'insegna' );
					$translatePartialLoader.addPart( 'global' );
					$translatePartialLoader.addPart( 'selezione-locale' );
					$translatePartialLoader.addPart( 'locale' );
					return $translate.refresh();
				} ]
			}
		});

		$stateProvider.state( 'takeaway', {
			parent: 'selezione-locale',
			url: '^/takeaway?comune&tipologie',
			reloadOnSearch: false,
			data: {
				pageTitle: 'theFoodApp.selezione-locale.title-takeaway'
			},
			views: {
				'content@': {
					templateUrl: 'app/pages/selezione-locale/takeaway/takeaway-list.html',
					controller: 'TakeawayController',
					controllerAs: 'vm'
				},
				'insegne@takeaway': {
					templateUrl: 'app/pages/selezione-locale/selezione-locale.html',
					controller: 'SelezioneLocaleController',
					controllerAs: 'vm'
				}
			},
			params: {
				comune: {
					value: null,
					squash: true
				},
				tipologie: {
					value: null,
					squash: true,
					array: true
				}
			},
			resolve: {
				insegne: function( $state, $stateParams, $translate, Takeaway, AlertService, RouteError, translatePartialLoader ) {

					var selectedComune = null, selectedTipologie = [], filtersSet = false;

					if ( $stateParams.comune ) {
						selectedComune = parseFloat( $stateParams.comune );
						filtersSet = true;
					}

					if ( $stateParams.tipologie ) {
						selectedTipologie = _.map( $stateParams.tipologie, parseFloat );
						filtersSet = true;
					}

					var params = {
						comune: selectedComune,
						tipologieLocali: selectedTipologie
					};

					return Takeaway.insegne(params).$promise.then( function(insegne) {
						if ( insegne && insegne.length > 0 )
							return insegne;

						// Nessuna insegna trovata.

						// Se l'utente ha impostato dei filtri, lo facciamo comunque andare alla pagina, ma con un messaggio di warning che gli indica la mancanza di locali per i filtri specificati..
						if ( filtersSet )
							return [];

						// Altrimenti, se non abbiamo trovato nessuna insegna senza alcun filtro, vuol dire che non esistono locali che fanno takeaway.
						return Promise.reject( new RouteError().translatedErrorMessage( 'theFoodApp.selezione-indirizzo.error.notakeaway' ).redirectTo( 'selezione-indirizzo' ) );
					}, function(err) {
						return Promise.reject( new RouteError().error( err ) );
					} );
				},
				comuni: function( Takeaway ) {
					return Takeaway.comuni().$promise;
				},
				isTakeaway: _.constant( true ),
				pagingParams: [ '$stateParams', 'PaginationUtil', function( $stateParams, PaginationUtil ) {
					return {
						page: PaginationUtil.parsePage( $stateParams.page ),
						search: $stateParams.search
					};
				} ]
			},
			breadcrumb: {
				title: "{{ 'theFoodApp.selezione-locale.title-takeaway' | translate }}"
			}
		} );

		$stateProvider.state( 'consegna', {
			parent: 'selezione-locale',
			url: '^/consegna?tipologie',
			reloadOnSearch: false,
			data: {
				pageTitle: 'theFoodApp.selezione-locale.title-consegna'
			},
			views: {
				'content@': {
					templateUrl: 'app/pages/selezione-locale/consegna/consegna-list.html',
					controller: 'ConsegnaController',
					controllerAs: 'vm'
				},
				'insegne@consegna': {
					templateUrl: 'app/pages/selezione-locale/selezione-locale.html',
					controller: 'SelezioneLocaleController',
					controllerAs: 'vm'
				}
			},
			params: {
				tipologie: {
					value: null,
					squash: true,
					array: true
				}
			},
			resolve: {
				indirizzoSalvato: function( IndirizzoStore, RouteError, translatePartialLoader ) {
					if ( !IndirizzoStore.isPresent() ) {
						return Promise.reject( new RouteError().translatedErrorMessage( 'theFoodApp.selezione-locale.no-indirizzo-consegna' ).redirectTo( 'selezione-indirizzo' ) );
					} else {
						return IndirizzoStore.get();
					}
				},
				zona: function( indirizzoSalvato ) {
					return indirizzoSalvato.zone;
				},
				indirizzoCercato: function(indirizzoSalvato) {
					return indirizzoSalvato.strada.descrizione + ' ' + indirizzoSalvato.civico;
				},
				insegne: function( $state, $stateParams, Consegna, RouteError, zona, MultiInsegnaToggle, translatePartialLoader ) {
					var selectedTipologie = $stateParams.tipologie ? _.map( $stateParams.tipologie, parseFloat ) : [];
					var params = {
						tipologieLocali: selectedTipologie,
						zona: zona
					};

					return Consegna.insegne(params).$promise.then( function(data) {
						if ( !data || data.length === 0 ) {
							return Promise.reject( new RouteError().translatedErrorMessage( 'theFoodApp.selezione-locale.no-locali-zona' ).redirectTo( 'selezione-indirizzo' ) );
						}

						if (MultiInsegnaToggle.isMultiInsegna()) {
							return data;
						} else {
							$state.go( 'locale', { t: undefined, i: data[0].id } );
						}
					}, function(err) {
						if ( err && 'status' in err && err.status === 404 ) {
							return Promise.reject( new RouteError().translatedErrorMessage( 'theFoodApp.selezione-locale.no-locali-zona' ).redirectTo( 'selezione-indirizzo' ) );
						} else {
							return Promise.reject( new RouteError().error( err ) );
						}
					} );
				},
				tipologieLocali: function( $stateParams, Consegna, zona ) {
					return Consegna.tipologieLocali({ zona: zona }).$promise;
				},
				isTakeaway: _.constant( false ),
				pagingParams: [ '$stateParams', 'PaginationUtil', function( $stateParams, PaginationUtil ) {
					return {
						page: PaginationUtil.parsePage( $stateParams.page ),
						search: $stateParams.search
					};
				} ]
			},
			breadcrumb: {
				title: "{{ 'theFoodApp.selezione-locale.title-consegna' | translate }}"
			}
		} );

	}
} )();
