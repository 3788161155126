(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.factory('Carrello', Carrello);

	function Carrello($resource, SERVER_URL) {

		var resourceUrl = SERVER_URL + 'api/public/v1/carrello/:localeId',
			rigaCarrelloUrl = resourceUrl + '/articoli/:rigaId';

		return $resource(resourceUrl, {}, {

			get : {
				// Parametri necessari: localeId, zonaId, clienteId
				url: resourceUrl,
				method : 'GET'
			},

			aggiungiProdotto : {
				// Parameteri necessari: localeId, zonaId, clienteId
				// Body:
				//   productId: prodotto selezionato [obbligatorio]
				//   menuProducts: [ { productId, menuItemId } ]
				url: rigaCarrelloUrl,
				method: 'POST'
			},
			rimuoviArticolo : {
				// Parameteri necessari: localeId, rigaId, zonaId, clienteId
				url: rigaCarrelloUrl,
				method: 'DELETE'
			},

			svuotaCarrello : {
				url: resourceUrl + '/svuota-carrello',
				method: 'DELETE'
			},

			varianti: {
				// Parametri necessari: localeId, rigaId, clienteId
				// Parametri opzionali: dettaglioMenuId
				url: rigaCarrelloUrl + '/varianti',
				method : 'GET',
				isArray: true
			},
			salvaVarianti: {
				// Parametri necessari: localeId, rigaId, zonaId, clienteId
				// Parametri opzionali: dettaglioMenuId
				// Body:
				//   [ { varianteId, dettaglioVarianteId } ]
				url: rigaCarrelloUrl + '/varianti',
				method : 'PUT'
			},

			aggiunte: {
				// Parametri necessari: localeId, rigaId, clienteId
				// Parametri opzionali: dettaglioMenuId
				url: rigaCarrelloUrl + '/aggiunte',
				method : 'GET',
				isArray: true
			},
			salvaAggiunte: {
				// Parametri necessari: localeId, rigaId, zonaId, clienteId
				// Parametri opzionali: dettaglioMenuId
				// Body:
				//   [ aggiuntaId ]
				url: rigaCarrelloUrl + '/aggiunte',
				method : 'PUT'
			},
			salvaVariantiAggiunte: {
				url: rigaCarrelloUrl + '/variantiAggiunte',
				method : 'PUT'
			}

		});

	}
})();
