(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.controller('ConsegnaController', ConsegnaController);

	function ConsegnaController($scope, $state, Consegna, AlertService, pagingParams, paginationConstants, zona, indirizzoCercato, insegne, tipologieLocali, $anchorScroll, $location) {
		var vm = this;
		var startSMSize = 991;

		vm.indirizzoCercato = indirizzoCercato;
		vm.insegne = extendInsegne(insegne);
		vm.tipologieLocali = tipologieLocali;
		vm.setMostraTipologie = setMostraTipologie;
		vm.isScreenLargeEnough = isScreenLargeEnough;
		vm.toggleTipologiaLocale = toggleTipologiaLocale;

		vm.loadingInsegne = false;
		vm.selectedTipologie = $state.params.tipologie ? _.map($state.params.tipologie, parseFloat) : [];

		// isScreenLargeEnough();

		function isScreenLargeEnough() {
			if (document.body.offsetWidth > startSMSize) {
				return true;
			}

			return false;

		}

		setMostraTipologie();

		function setMostraTipologie() {
			if (document.body.offsetWidth <= startSMSize) {
				vm.mostraTipologie = false;
			}

			vm.mostraTipologie = true;
		}

		function activate() {
			_.each(vm.tipologieLocali, function(t) {
				t.selected = _.includes(vm.selectedTipologie, t.id);
			});
		}

		function toggleTipologiaLocale(e, t) {
			t.selected = !t.selected;

			vm.selectedTipologie = _(vm.tipologieLocali).filter('selected').map('id').value();
			$state.go('.', {
				tipologie: vm.selectedTipologie
			}).then(function() {
				loadInsegne(vm.selectedTipologie);
			});

			e.stopPropagation();
			if (document.body.offsetWidth <= startSMSize) {
				// $location.hash('primo-locale');
				// $anchorScroll();
				vm.mostraTipologie = true;
			}

			return false;
		}

		function loadInsegne(tipologieLocaliId) {
			var params = {
				zona: zona,
				tipologieLocali: tipologieLocaliId
			};

			vm.loadingInsegne = true;
			return Consegna.insegne(params).$promise.then(function(data) {
				vm.queryCount = vm.totalItems;

				vm.insegne.splice(0, vm.insegne.length);
				vm.insegne.push.apply(vm.insegne, extendInsegne(data));

				return data;
			}, function(error) {
				AlertService.error(error.data.message);
			}).then(function() {
				vm.loadingInsegne = false;
			});
		}

		function extendInsegne(insegne) {
			_.each(insegne, function(insegna) {
				_.each(insegna.locales, function(locale) {
					locale.insegna = insegna;
				});
			});
			return insegne;
		}

		activate();

		return vm;

	}

})();
