(function() {
    'use strict';

    angular
        .module('theFoodApp')
        .controller('DatiFatturazioneDetailController', DatiFatturazioneDetailController);

    DatiFatturazioneDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'DatiFatturazione', 'Cliente'];

    function DatiFatturazioneDetailController($scope, $rootScope, $stateParams, previousState, entity, DatiFatturazione, Cliente) {
        var vm = this;

        vm.datiFatturazione = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('theFoodApp:datiFatturazioneUpdate', function(event, result) {
            vm.datiFatturazione = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
