(function () {
	'use strict';

	angular
		.module('theFoodApp')
		.controller('AreaPersonaleController', AreaPersonaleController);

	function AreaPersonaleController($scope, Coupon, $state,$uibModal,$window, pagingParams, ParseLinks, AlertService, MovimentiCredito, translationStorageProvider, $timeout) {
		var vm = this;


		vm.movimentiCredito = [];
		vm.itemsPerPage = 5;
		vm.totaleCredito = 0;
		vm.loadTotale = loadTotale;
		vm.mostraMov = false;
		vm.transition = transition;
		vm.mobile = false;
		vm.coupon_code = "";
		vm.saveCoupon = saveCoupon;
		var predicate = 'dataOrdine';
		var reverse = false;
		vm.saved = false;
		vm.saveFailed1 = false;
		vm.saveFailed2 = false;
		vm.saveFailed3 = false;
		vm.saveFailed4 = false;


		loadAll();

		//Carica i movimenti credito
		function loadAll() {
			if ($window.innerWidth <= 460) { // 768px portrait
				vm.mobile = true;
			}
			MovimentiCredito.movimenti({
				page: pagingParams.page - 1,
				size: vm.itemsPerPage,
				//sort: sort()// --> eventualmente da popolare con l'ordinamento scelto dall'utente
			}, onSuccess, onError);


			function sort() {
				var result = [predicate + ',' + (reverse ? 'asc' : 'desc')];
				if (predicate !== 'dataOrdine') {
					result.push('dataOrdine');
				}
				return result;
			}


			function onSuccess(data, headers) {
				loadTotale();
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count');
				//_.forEach(data, function(movimento) {
				vm.movimentiCredito = data;
				//});
				vm.page = pagingParams.page;
			}

			function onError(error) {
				AlertService.error(error.data.message);
			}
		}



		function loadTotale() {
			MovimentiCredito.totale({}, onSuccess, onError);

			function onSuccess(data, headers) {
				//_.forEach(data, function(movimento) {
				vm.totaleCredito = data.totale;
				//});
			}

			function onError(error) {
				AlertService.error(error.data.message);
			}
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page: vm.page
			});
		}


		function saveCoupon() {
			Coupon.getValueCouponCredito({
				codice: vm.coupon_code
			}, onSuccess, onError);
			function onSuccess(data1, headers) {
				if (data1.status === "SUCCESS") {
					var modalInstance = $uibModal.open({
						animation: true,
						templateUrl: 'app/pages/confirm-coupon-credito/confirm-coupon-credito.html',
						controller: 'ConfirmCouponCreditoController',
						controllerAs: 'vm',
						backdrop: 'static',
						keyboard: false,
						resolve: {
							couponCode :  function() {
								return data1.value;
							  }
						}
					});
					modalInstance.result.then(function (parameter) {
						if (parameter !== 'cancel') {
							Coupon.saveCouponCredito({
								codice: vm.coupon_code
							}, onSuccess, onError);
							function onSuccess(data, headers) {
								showError(data);
							}		
							function onError(error) {
								AlertService.error(error.data.message);
							}
						}
					});
				} else {
					showError(data1);
				}
			}
			function onError(error) {
				AlertService.error(error.data1.message);
			}
			


		}

		function showError(data) {
			if (data.status === "SUCCESS") {
				vm.saved = true;
				$timeout(function () {
					vm.saved = false;
				}, 3000);
				loadAll();
			} else if (data.status === "ERROR") {
				if (data.code === 1) {
					vm.saveFailed1 = true;
				}
				if (data.code === 2) {
					vm.saveFailed2 = true;
				}
				if (data.code === 3) {
					vm.saveFailed3 = true;
				}
				if (data.code === 4) {
					vm.saveFailed4 = true;
				}
				$timeout(function () {
					vm.saveFailed1 = false;
					vm.saveFailed2 = false;
					vm.saveFailed3 = false;
					vm.saveFailed4 = false;
				}, 4000);
			}
				
		}
	}

})();
