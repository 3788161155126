(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.controller( 'AggiunteController', AggiunteController );

	function AggiunteController( $uibModalInstance, carrelloInstance, rigaOrdine, sceltaMenu, aggiunte ) {

		var vm = this;

		// Interface

		vm.aggiunte = _.clone( aggiunte );

		vm.isSaving = false;
		vm.canSave = canSave;
		vm.save = save;

		vm.canClear = _.constant( true );
		vm.clear = clear;

		vm.abort = abort;

		vm.toggleAggiunta = toggleAggiunta;
		vm.getTotaleAggiunte = getTotaleAggiunte;
		vm.getCostoAggiunte = getCostoAggiunte;
		vm.getPrezzoAggiunta = getPrezzoAggiunta;
		vm.getAggiunteDisponibili = getAggiunteDisponibili;

		// Implementation

		var riga = sceltaMenu || rigaOrdine,
			articolo = riga.prodotto;

		function activate() {
			var current = _.map( riga.aggiuntas, 'id' );
			_.each( vm.aggiunte, function(a) {
				a.selected = _.includes( current , a.id );
			});
			console.info( articolo, riga.aggiuntas, current );
		}

		function clear() {
			_.each( vm.aggiunte, function(a) {
				a.selected = false;
			} );
		}

		function getAggiunteSelezionate() {
			return _( vm.aggiunte ).filter( 'selected' ).map( 'id' ).value();
		}

		function getTotaleAggiunte() {
			return _( vm.aggiunte ).filter( 'selected' ).value().length;
		}

		function getPrezzoAggiunta( aggiunta ) {
			return carrelloInstance.getPrezzoProdotto( aggiunta );
		}

		function getCostoAggiunte() {
			return _( vm.aggiunte ).filter( 'selected' ).reduce( function(v, a) {
				return v + ( a.selected ? getPrezzoAggiunta( a ) : 0 );
			}, 0 );
		}

		function getAggiunteDisponibili() {
			return articolo.aggiunteDisponibili;
		}

		function canSave() {
			return getAggiunteDisponibili() && getTotaleAggiunte() <= getAggiunteDisponibili();
		}

		function save() {
			return carrelloInstance.salvaAggiunte( rigaOrdine.id, sceltaMenu ? sceltaMenu.id : null, getAggiunteSelezionate() )
				.then( $uibModalInstance.close );
		}

		function abort() {
			$uibModalInstance.dismiss();
		}

		function toggleAggiunta(evt, aggiunta) {
			aggiunta.selected = !aggiunta.selected;

			evt.stopPropagation();
			return false;
		}

		activate();

		return vm;

	}

})();
