(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.factory('DownloadAppToggle', DownloadAppToggle);

	function DownloadAppToggle( $rootScope, $window, $log, MobileAppService ) {
		var vm = this;

		// Interface
		vm.showDownloadAppPage = shouldShowDownloadAppPage;
		vm.notShowDownloadApp = notShowDownloadApp;
		vm.downloadApp = downloadApp;

		// Implementation

		var isWebsiteViewedOnMobileDevices = false;

		function getIsWebsiteViewedOnMobileDevices() {
			
			// Se siamo già sulla app, non visualizzare il banner.
			if ( $rootScope.isMobile() )
				return false; 

			// Il banner è attivo solo su TheFood e per certi OS.
			if ( !MobileAppService.getStoreUrlForDevice() )
				return false;

			// Altrimenti il banner è attivo. 

			// L'utente potrebbe aver già dismissato il banner in precedenza, in quel caso non lo rifacciamo vedere
			if ( $window.localStorage['banner-app'] )
				return false;

			return true;

		}

		function shouldShowDownloadAppPage() {
			return isWebsiteViewedOnMobileDevices;
		}

		function notShowDownloadApp() {
			isWebsiteViewedOnMobileDevices = false;
			$window.localStorage.setItem('banner-app', true);
		}

		function isLocalStorageNameSupported() {
			var localStorageName = 'localStorage';
			try {
				var supported = (localStorageName in window && $window[localStorageName]);
				if (supported) {
					localStorage.setItem("storage", "");
					localStorage.removeItem("storage");
				}

				return true;
			} catch(err) {
				$log.error(err);
				return false;
			}
		}
		
		
		function downloadApp(){
			var url = MobileAppService.getStoreUrlForDevice();
			$window.open( url, '_blank' );
		}

		isWebsiteViewedOnMobileDevices = isLocalStorageNameSupported() && getIsWebsiteViewedOnMobileDevices();

		return vm;
	}

})();
