(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.controller('PaymentErrorController', PaymentErrorController);

	function PaymentErrorController( $state, $stateParams ) {
    var vm = this;

		return vm;
  }

}) ();