(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.controller('SelezioneIndirizzoNewController', SelezioneIndirizzoNewController);

	function SelezioneIndirizzoNewController($log, $state, AlertService, IndirizzoStore, TrovaZona, newAddress, Consegna) {
		var vm = this;

		vm.strada = {};
		vm.civico = null;

		vm.validAddress = true;
		vm.validZone = true;
		vm.selectedAddress = true;
		vm.onAddressSelected = onAddressSelected;

		if (newAddress) {
			vm.strada = newAddress.strada;
			vm.civico = newAddress.civico;
		}

		function searchZona(strada, numeroCivico) {

			vm.validAddress = true;
			vm.validZone = true;
			vm.selectedAddress = true;

			if (angular.isUndefined(strada) || angular.isUndefined(numeroCivico)) {
				vm.validAddress = false;
			}
			if (angular.isUndefined(strada.id)) {
				vm.selectedAddress = false;
			}

			if (!vm.selectedAddress || !vm.validAddress)
				return;

			return TrovaZona.zona({
				stradaId: strada.id,
				civico: numeroCivico
			}).$promise.then(function(data) {
				IndirizzoStore.setFromInput(strada, numeroCivico, data.id);

				$state.go('consegna');

			}, function(err) {
				if (err.status === 404) {
					vm.validZone = false;
				} else {
					throw err;
				}
			});
		}

		function onAddressSelected() {
			vm.selectedAddress = true;
		}

		vm.searchZona = searchZona;
		return vm;
	}

})();
