(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.factory('TokenStore', TokenStore);

	var COOKIE_NAME = 'token';
	var COOKIE_EXPIRATION = 12; // In hours.

	function TokenStore($cookies) {

		var vm = this;

		// Interface
		vm.setToken = setToken;
		vm.clearCookie = clearCookie;
		vm.isPresent = isPresent;
		vm.get = clone;

		// Implementation

		var validCookie = false;
		var tokenCookie = null;

		// Low level functions
		function loadCookie() {
			return $cookies.getObject(COOKIE_NAME);
		}

		function saveCookie(cookie) {
			$cookies.putObject(COOKIE_NAME, cookie, {
				expires: new moment().add(COOKIE_EXPIRATION, 'hours').toDate()
			});
		}

		function removeCookie() {
			$cookies.remove(COOKIE_NAME);
		}

		// High level functions
		function setToken(token) {
			validCookie = true;
			tokenCookie = { token: token };
			saveCookie(tokenCookie);
			return clone();
		}

		function loadFromCookie() {
			tokenCookie = loadCookie();
			validCookie = angular.isDefined(tokenCookie);
		}

		function clearCookie() {
			validCookie = false;
			tokenCookie = null;
			removeCookie();
		}

		function isPresent() {
			return validCookie;
		}

		function clone() {
			return _.clone(tokenCookie);
		}

		loadFromCookie();

		return vm;
	}

})();
