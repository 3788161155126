( function() {
	'use strict';

	angular
		.module( 'theFoodApp' )
		.config( stateConfig );

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig( $stateProvider ) {
		$stateProvider
			.state( 'selezione-indirizzo', {
				parent: 'app',
				url: '/selezione-indirizzo',
				data: {
					pageTitle: 'theFoodApp.selezione-indirizzo.title'
				},
				views: {
					'content@': {
						templateUrl: 'app/pages/selezione-indirizzo/selezione-indirizzo.html',
						controller: 'SelezioneIndirizzoController as vm'
					},
					'rubrica@selezione-indirizzo': {
						templateUrl: 'app/pages/selezione-indirizzo/selezione-indirizzo.rubrica.html',
						controller: 'SelezioneIndirizzoRubricaController',
						controllerAs: 'vm'
					},
					'new@selezione-indirizzo': {
						templateUrl: 'app/pages/selezione-indirizzo/selezione-indirizzo.new.html',
						controller: 'SelezioneIndirizzoNewController',
						controllerAs: 'vm'
					},
					'takeaway@selezione-indirizzo': {
						templateUrl: 'app/pages/selezione-indirizzo/selezione-indirizzo.takeaway.html'
					}
				},
				resolve: {
					newAddress: function(IndirizzoStore) {
						if ( IndirizzoStore.isPresent() ) {
							var indirizzo = IndirizzoStore.get();
							if ( indirizzo.new ) {
								return indirizzo;
							}
						}
						return {};
					},
					addressBook: function(Principal, Indirizzo) {
						return Principal.identity().then( function(identity) {
							return Principal.isAuthenticated() ? Indirizzo.query().$promise : Promise.resolve( [] );
						} );
					},
					translatePartialLoader: [ '$translate', '$translatePartialLoader', function( $translate, $translatePartialLoader ) {
						$translatePartialLoader.addPart( 'selezione-indirizzo' );
						$translatePartialLoader.addPart( 'global' );
						return $translate.refresh();
					} ]
				},
				breadcrumb: {
					title: "{{ 'theFoodApp.selezione-indirizzo.title' | translate }}"
				}
			} );

	}

} )();
