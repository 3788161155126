(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.controller('HeaderController', HeaderController);

	function HeaderController($scope, $document, $state, $translate, $window, Auth, Principal, ProfileService, LoginService, tmhDynamicLocale, CarrelloHolder, PromozioniHolder) {
		var vm = this;

		vm.isAuthenticated = Principal.isAuthenticated;

		vm.login = login;
		vm.logout = logout;
		vm.goToHome = goToHome;
		vm.changeLanguage = changeLanguage;
		vm.carrelloHolder = CarrelloHolder;
		vm.promozioniHolder = PromozioniHolder;
		vm.toggleMyAccount = toggleMyAccount;
		vm.goToHomePage = goToHomePage;
		vm.open = false;
		vm.isIosX = isIosX;
		vm.goToHelp = goToHelp;

		function isIosX() {
			if ( 	window.device !== undefined && window.device.platform == "iOS" &&	(window.device.model == "iPhone10,6" || window.device.model == "iPhone10,3")) {
				return true;
			}else{
				return false;
			}
		}

		function goToHelp() {
				$state.go('static-page.aiuto');
		}

		function login() {
			LoginService.open().then(function() {
				$state.reload();
			});
		}

		function logout() {
			Auth.logout().then(function() {
				return $state.go('landing');
			}).then(function() {
				$state.reload();
			});
		}

		function goToHome() {
			$state.go('cliente');
		}

		function changeLanguage(languageKey) {
			$translate.use(languageKey);
			tmhDynamicLocale.set(languageKey);
		}

		function toggleMyAccount(event) {
			event.stopPropagation();
			vm.open = !vm.open;
			return vm.open;
		}

		function goToHomePage() {
			$state.go('landing');
		}

		// Aggiunto listener sul document per chiudere tendina con i link
		// per gli utenti loggati

		$document.click(function() {
			$scope.$apply(function() {
				vm.open = false;
			});
		});
	}
})();
