(function() {

	angular
		.module('theFoodApp')
		.controller('LocaleListinoController', LocaleListinoController);

	function LocaleListinoController( $uibModal, $scope, isTakeaway, listino, locale, zona, carrelloInstance, Notification, $translate, CarrelloHolder, DOUBLE_CLICK_TIME, NOTIFICATION_TIME, $timeout, PromozioniHolder, $window ) {

		var vm = this;

		// Interface
		vm.linee = listino;
		vm.locale = locale;
		vm.zona = zona;
		vm.scelteMenu = {};
		vm.showMenuError = -1;

		vm.getPrezzoProdotto = getPrezzoProdotto;
		vm.getImportoCarrello = getImportoCarrello;
		vm.aggiungiProdotto = aggiungiProdotto;
		vm.modificaVarianti = modificaVarianti;
		vm.isDesktop = $window.outerWidth > 812 ? true : false;


		vm.onSceltaMenuChanged = onSceltaMenuChanged;

		CarrelloHolder.setVisibility(true, [carrelloInstance]);

		$scope.$on('$destroy', function() {
			CarrelloHolder.setVisibility(false);
			PromozioniHolder.setVisibility(false);
		});

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': 'displayingMenu'
		});

		// Implementation
		var lang = $translate.proposedLanguage() || $translate.use();
		var doubleClickGuard = false;

		function aggiungiProdotto(articolo){
			var scelteMenu = [],
				hasError = false;
			if (articolo.menus.length > 0) {
				var ids = _.map(articolo.menus, 'id').map(_.toString);
				var scelteIds = _.pick(vm.scelteMenu, ids);

				_.each(articolo.menus, function(menu) {
					var scelta = scelteIds[menu.id];
					if (!scelta) {
						if (!menu.opzionale) {
							hasError = true;
						}
						return;
					}

					scelteMenu.push({
						menuItemId: menu.id,
						productId: scelta
					});
				});
			}

			if (!hasError) {
				if(doubleClickGuard)
					return Promise.resolve();

				doubleClickGuard = true;
				$timeout(function(){
					doubleClickGuard = false;
				}, DOUBLE_CLICK_TIME);

				return carrelloInstance.aggiungiProdotto(articolo, scelteMenu).then(function(response) {
					Notification.success({
						message: lang == 'it' ? articolo.descrizioneIt : articolo.descrizioneEn,
						title: $translate.instant('theFoodApp.locale.articoloAggiunto'),
						delay: NOTIFICATION_TIME
					}).then(function(){
						vm.modificaVarianti(response.ordine);
					}).then(function() {
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({
							'event': 'addProductOnCart'
						});
					});
				}, function() {
					Notification.error({
						message: lang == 'it' ? articolo.descrizioneIt : articolo.descrizioneEn,
						title: $translate.instant('theFoodApp.locale.articoloNonAggiunto'),
						delay: NOTIFICATION_TIME
					});
				});
			} else {
				vm.showMenuError = articolo.id;
				return Promise.reject(new Error('Invalid menu choices'));
			}
		}

		function getPrezzoProdotto(prodotto) {
			return isTakeaway ? prodotto.prezzoAsporto : prodotto.prezzoDomicilio;
		}

		function onSceltaMenuChanged(scelta) {
			vm.showMenuError = false;
		}

		function getImportoCarrello() {
			return carrelloInstance.getImportoArticoli();
		}


		function modificaVarianti(ordine) {

			if (vm.isDesktop){
				return false;
			}

			var sceltaMenu = null;
			var rigaOrdine = _.maxBy(ordine.rigaOrdines, 'id');

			if (rigaOrdine.dettaglioMenus.length){
					var menus = rigaOrdine.dettaglioMenus;
					var articoloMenuConVariante = _.find(menus, function(menu) {
							if (menu.dettaglioVariantes.length){
								return menu;
							}
					})
					sceltaMenu = articoloMenuConVariante ? articoloMenuConVariante : rigaOrdine;
					if (
							(!articoloMenuConVariante.dettaglioVariantes || !articoloMenuConVariante.dettaglioVariantes.length) &&
							(!articoloMenuConVariante.prodotto.aggiunteDisponibili)
						)
						return false;
			}else{
				if (
					(!rigaOrdine.dettaglioVariantes || !rigaOrdine.dettaglioVariantes.length) &&
					(!rigaOrdine.prodotto.aggiunteDisponibili)
				)
				return false;
			}

			var modal = $uibModal.open({
				controller : 'VariantiController as vm',
				templateUrl : 'app/pages/carrello/articolo.varianti.dialog.html',
				backdrop: 'static',
				resolve : {
					carrelloInstance : function() {
						return carrelloInstance;
					},
					rigaOrdine : function() {
						return rigaOrdine;
					},
					sceltaMenu : function() {
						return sceltaMenu;
					},
					varianti : function() {
						return carrelloInstance.getVarianti( rigaOrdine.id, sceltaMenu ? sceltaMenu.id : null );
					},
					aggiunte : function() {
						return carrelloInstance.getAggiunte( rigaOrdine.id, sceltaMenu ? sceltaMenu.id : null );
					},
					translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
						$translatePartialLoader.addPart('varianti');
						$translatePartialLoader.addPart('aggiunte');
						return $translate.refresh();
					} ]
				}
			});

			return modal.result;

		}


		return vm;

	}

})();
