(function() {
	'use strict';

	angular
		.module("theFoodApp")
		.factory("InformazioniAperturaOdierna", InformazioniAperturaOdierna);

	function InformazioniAperturaOdierna() {
		var vm = this;

		vm.getInformazioniAperturaOdierna = getInformazioniAperturaOdierna;


		var descrizioneApertura = ['theFoodApp.locale.oggiChiuso', 'theFoodApp.locale.apertoCena', 'theFoodApp.locale.apertoPranzo', 'theFoodApp.locale.oggiAperto'];
		var stiliApertura = ['oggi-chiuso', 'oggi-aperto-cena', 'oggi-aperto-pranzo', 'oggi-aperto oggi-aperto-cena oggi-aperto-pranzo'];
		var giorniSettimana = ['theFoodApp.locale.giorniSettimana.lunedi', 'theFoodApp.locale.giorniSettimana.martedi', 'theFoodApp.locale.giorniSettimana.mercoledi', 'theFoodApp.locale.giorniSettimana.giovedi', 'theFoodApp.locale.giorniSettimana.venerdi', 'theFoodApp.locale.giorniSettimana.sabato', 'theFoodApp.locale.giorniSettimana.domenica'];

		var informazioniAperturaCache = [];

		function getDescrizioneAperturaLocale(statoApertura) {
			return descrizioneApertura[statoApertura];
		}

		function getStileAperturaLocale(statoApertura) {
			return stiliApertura[statoApertura];
		}

		function showProssimaConsegnaUtile(apertoPranzo, apertoCena) {
			return apertoPranzo || apertoCena;
		}

		function calcolaGiornoProssimaConsegna(giornoDelCalendario, dayOfWeek){
			return giornoDelCalendario === dayOfWeek ? null : giorniSettimana[giornoDelCalendario-1]
		}

		function nessunaDataDisponibile(apertoPranzo, apertoCena, statoApertura){
			return !apertoPranzo && !apertoCena && statoApertura === 0
		}

		function getInformazioniAperturaOdierna(locale) {
			var nessunaConsegnaDisponibile = locale.insegna.nessunaConsegnaDisponibile
			var nessunCalendarioDisponibile = locale.insegna.nessunCalendarioDisponibile;
			// var dayOfWeek = moment().day();
			var giornoDelCalendario = null;
			var calendarioRelativoAlGiornoDellaSettimana = null;
			var dayOfWeek = moment().isoWeekday();
			if (informazioniAperturaCache[locale.id] != null) {
				return informazioniAperturaCache[locale.id]
			}

			for(var i = dayOfWeek; i <= (dayOfWeek+6); i++){
				giornoDelCalendario = (i - 1) % 7 + 1;
				calendarioRelativoAlGiornoDellaSettimana = _.find(locale.calendarios, {
					'giornoSettimana': giornoDelCalendario
				});
				if(calendarioRelativoAlGiornoDellaSettimana !== undefined && ((calendarioRelativoAlGiornoDellaSettimana.pranzoApre != null || calendarioRelativoAlGiornoDellaSettimana.cenaApre != null) || nessunCalendarioDisponibile === true) ){
					break;
				}
				if(calendarioRelativoAlGiornoDellaSettimana !== undefined && (calendarioRelativoAlGiornoDellaSettimana.pranzoApre == null || calendarioRelativoAlGiornoDellaSettimana.cenaApre == null)){
					break;
				}
			}
			var apertoPranzo = !!(calendarioRelativoAlGiornoDellaSettimana && calendarioRelativoAlGiornoDellaSettimana.pranzoApre !== null);
			var apertoCena = !!(calendarioRelativoAlGiornoDellaSettimana && calendarioRelativoAlGiornoDellaSettimana.cenaApre !== null);
			var statoApertura = apertoPranzo * 2 + apertoCena;

			informazioniAperturaCache[locale.id] = {
				descrizioneAperturaLocale: getDescrizioneAperturaLocale(giornoDelCalendario !== dayOfWeek || nessunaConsegnaDisponibile ? 0 : statoApertura),
				stileAperturaLocale: getStileAperturaLocale(giornoDelCalendario !== dayOfWeek || nessunaConsegnaDisponibile ? 0 : statoApertura),
				prossimaConsegnaUtile: showProssimaConsegnaUtile(apertoPranzo, apertoCena),
				orarioPrimaConsegna: mostraPrimaConsegna(dayOfWeek, giornoDelCalendario, locale, calendarioRelativoAlGiornoDellaSettimana),
				giornoProssimaConsegna: calcolaGiornoProssimaConsegna(giornoDelCalendario, dayOfWeek),
				nessunaDataDisponibile: nessunaConsegnaDisponibile || nessunaDataDisponibile(apertoPranzo, apertoCena, statoApertura)
			};
			return informazioniAperturaCache[locale.id]
		}

		function mostraPrimaConsegna(dayOfWeek, giornoDelCalendario, locale, calendarioRelativoAlGiornoDellaSettimana){
			if(dayOfWeek !== giornoDelCalendario){
				return calendarioRelativoAlGiornoDellaSettimana.pranzoApre !== null ? calendarioRelativoAlGiornoDellaSettimana.pranzoApre : calendarioRelativoAlGiornoDellaSettimana.cenaApre;
			}

			return locale.orarioPrimaConsegna;
		}

		return vm;
	}

})();
