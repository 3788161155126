(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.directive('showValidation', showValidation);

	function showValidation() {
		var directive = {
			restrict: 'A',
			require: 'form',
			link: linkFunc
		};

		return directive;



		function linkFunc(scope, element, attrs, formCtrl) {

			scope.length = function() {
				// console.info('length');;
			}
			scope.$watch(function() {
				return element.find('.form-group:not(.validation)').length;
			}, function() {
				refreshFormGroups();
			});

			function refreshFormGroups() {
				element.find('.form-group:not(.validation)').each(function() {
					var $formGroup = angular.element(this);
					var $inputs = $formGroup.find('input[ng-model],textarea[ng-model],select[ng-model]');
					$formGroup.addClass('validation');

					if ($inputs.length > 0) {
						$inputs.each(function() {
							var $input = angular.element(this);
							var inputName = $input.attr('name');
							scope.$watch(function() {
								return (formCtrl[inputName].$invalid && (formCtrl[inputName].$dirty || formCtrl.$submitted));
							}, function(isInvalid) {
								$formGroup.toggleClass('has-error', isInvalid);
							});
						});
					}
				});
			}
		}
	}
})();
