(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.controller('StoricoOrdiniController', StoricoOrdiniController);

		function StoricoOrdiniController($state, StoricoOrdini, pagingParams, ParseLinks, AlertService, RieffettuaOrdine, IndirizzoStore) {
			var vm = this;

			// Interface
			vm.itemsPerPage = 5;
			vm.loadPage = loadPage;
			vm.transition = transition;
			vm.loadAll = loadAll;
			vm.vediDettaglio = false;
			vm.storicoOrdini = [];
			vm.getIndirizzoCliente = getIndirizzoCliente;
			vm.isTakeaway = isTakeaway;
			vm.getPrezzo = getPrezzo;
			vm.hasSconto = hasSconto;
			vm.getScontoTotale = getScontoTotale;
			vm.getTotale = getTotale;
			// vm.vediOpzioniAggiunte = false;
			vm.showCaret = showCaret;
			vm.riacquista = riacquista;

			var predicate = 'dataOrdine';
			var reverse = false;

			loadAll();

			function loadAll() {
				StoricoOrdini.query({
					page: pagingParams.page - 1,
					size: vm.itemsPerPage,
					sort: sort()// --> eventualmente da popolare con l'ordinamento scelto dall'utente
				}, onSuccess, onError);
				function sort() {
				var result = [predicate + ',' + (reverse ? 'asc' : 'desc')];
				if (predicate !== 'dataOrdine') {
					result.push('dataOrdine');
				}
				return result;
			}


				function onSuccess(data, headers) {
					vm.links = ParseLinks.parse(headers('link'));
					vm.totalItems = headers('X-Total-Count');
					_.forEach(data, function(dettaglio) {
						vm.storicoOrdini.push(JSON.parse(dettaglio.dettaglioOrdine));
					});
					vm.page = pagingParams.page;
				}

				function onError(error) {
					AlertService.error(error.data.message);
				}
			}

			function loadPage(page) {
				vm.page = page;
				vm.transition();
			}

			function transition() {
				$state.transitionTo($state.$current, {
					page: vm.page
				});
			}

			function getIndirizzoCliente(indirizzoCliente){
				var indirizzo = indirizzoCliente.indirizzo.indirizzo + ', ' + indirizzoCliente.indirizzo.civico;

				return indirizzo;
			}

			function showCaret(riga){
				return (riga.dettaglioMenus && riga.dettaglioMenus.length > 0) || (riga.aggiuntas && riga.aggiuntas.length > 0) || (riga.dettaglioVariantes && riga.dettaglioVariantes.length > 0);
			}

			function isTakeaway(ordine){
				if(ordine){
					return ordine.ritiroAlLocale;
				}
			}

			function hasSconto(ordine) {
				return getScontoTotale(ordine) > 0;
			}

			function getScontoTotale(ordine) {
				return ordine.importoOrdineSenzaSconti - ordine.importoOrdine;
			}

			function getTotale(ordine) {
				if(ordine.creditoUtilizzato != null){
					return ordine.importoOrdine + ordine.importoSpedizione + ordine.creditoUtilizzato;
				}
				return ordine.importoOrdine + ordine.importoSpedizione;
			}

			function getPrezzo(prodotto, ordine){
				return isTakeaway(ordine) ? prodotto.prezzoAsporto : prodotto.prezzoDomicilio;
			}

			function riacquista(ordine){
				var riordine = {};
				riordine.righeOrdine = ordine.ordine.righeOrdine;
				riordine.isTakeaway = ordine.ordine.ritiroAlLocale;
				riordine.localeId = ordine.locale.id;
				riordine.insegnaId = ordine.locale.insegnaId;
				riordine.device = ordine.ordine.device;
				riordine.clienteId = ordine.cliente.id;

				if(!isTakeaway(ordine.ordine)){
					riordine.indirizzoClienteId = ordine.cliente.indirizzo.id;
				}

				RieffettuaOrdine.riordina(riordine).$promise
					.then(function(response){
						
						if(!isTakeaway(ordine.ordine)){
							var address = {};
							address.id = ordine.cliente.indirizzo.id;
							address.civico = ordine.cliente.indirizzo.civico;
							address.strada = ordine.cliente.indirizzo.indirizzo;
							IndirizzoStore.setFromAddressBook(address, response.zonaUtente);
						}

						$state.go('locale', {
							t: isTakeaway(ordine.ordine) ? ordine.locale.id : undefined,
							i: response.insegnaId,
							carrelloRiordinato: true
						});

					}).catch(function(error){
					});
			}
		}
})();
