(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.controller('IndirizziRubricaDialogController', IndirizziRubricaDialogController);


	function IndirizziRubricaDialogController($uibModalInstance, indirizziRubrica) {
		var vm = this;

		vm.indirizziRubrica = indirizziRubrica;

		vm.selezionaIndirizzo = selezionaIndirizzo;
		vm.clear = clear;

		function selezionaIndirizzo(indirizzo) {
			$uibModalInstance.close(indirizzo);
		}

		function clear () {
			$uibModalInstance.dismiss('cancel');
		}

		return vm;
	}

})();