(function() {
    'use strict';

    angular
        .module('theFoodApp')
        .factory('authExpiredInterceptor', authExpiredInterceptor);


    authExpiredInterceptor.$inject = ['$rootScope', '$q', '$injector', '$localStorage', '$sessionStorage', 'WebStorageUtil', 'TokenStore'];

    function authExpiredInterceptor($rootScope, $q, $injector, $localStorage, $sessionStorage, WebStorageUtil, TokenStore) {
        var service = {
            responseError: responseError
        };

        return service;

        function responseError(response) {
            if (response.status === 401) {
                if (WebStorageUtil.isLocalStorageSupported()) {
                    delete $localStorage.authenticationToken;
                    delete $sessionStorage.authenticationToken;
                } else {
                    TokenStore.clearCookie();
                }

                var Principal = $injector.get('Principal');
                if (Principal.isAuthenticated()) {
                    var Auth = $injector.get('Auth');
                    Auth.authorize(true);
                }
            }
            return $q.reject(response);
        }
    }
})();
