(function() {
    'use strict';

    angular
        .module('theFoodApp')
        .directive('codiceFiscale', codiceFiscale);

    function codiceFiscale () {
        var directive = {
            restrict: 'A',
            require: '?ngModel',
            link: linkFunc
        };

        return directive;

        function linkFunc (scope, element, attrs, ngModel) {
            if (!ngModel) {
                return;
            }

            ngModel.$validators.codiceFiscale = function (modelValue) {
                return ngModel.$isEmpty(modelValue) || checkCodiceFiscale(modelValue);
            };
        }

		function checkCodiceFiscale(cf) {
			cf = cf.toUpperCase();
			if (cf === '') {
				return true;
			}
			if (!/^[0-9A-Z]{16}$/.test(cf)) {
				return false;
			}
			var map = [ 1, 0, 5, 7, 9, 13, 15, 17, 19, 21, 1, 0, 5, 7, 9, 13,
					15, 17, 19, 21, 2, 4, 18, 20, 11, 3, 6, 8, 12, 14, 16, 10,
					22, 25, 24, 23 ];
			var s = 0;
			for (var i = 0; i < 15; i++) {
				var c = cf.charCodeAt(i);
				if (c < 65)
					c = c - 48;
				else
					c = c - 55;
				if (i % 2 === 0)
					s += map[c];
				else
					s += c < 10 ? c : c - 10;
			}
			var atteso = String.fromCharCode(65 + s % 26);
			if (atteso !== cf.charAt(15)) {
				return false;
			}
			return true;
		}
    }

})();
