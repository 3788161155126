(function() {
	'use strict';

	angular.module('theFoodApp').controller('DatiFatturazioneDialogController',
			DatiFatturazioneDialogController);

	DatiFatturazioneDialogController.$inject = [ '$timeout', '$scope',
			'$stateParams', '$uibModalInstance', 'entity', 'DatiFatturazione', 'Provincia' ];

	function DatiFatturazioneDialogController($timeout, $scope, $stateParams,
			$uibModalInstance, entity, DatiFatturazione, Provincia) {
		var vm = this;

		vm.datiFatturazione = entity;
		vm.clear = clear;
		vm.save = save;
		vm.province = Provincia.query({sort: 'descrizione'});

		vm.cap = /^([0-9]{5})+$/

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function save() {
			vm.isSaving = true;
			if (vm.datiFatturazione.id !== null) {
				DatiFatturazione.update(vm.datiFatturazione, onSaveSuccess,
						onSaveError);
			} else {
				DatiFatturazione.save(vm.datiFatturazione, onSaveSuccess,
						onSaveError);
			}
		}

		function onSaveSuccess(result) {
			$scope.$emit('theFoodApp:datiFatturazioneUpdate', result);
			$uibModalInstance.close(result);
			vm.isSaving = false;
		}

		function onSaveError() {
			vm.isSaving = false;
		}

	}
})();
