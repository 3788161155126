(function() {
    'use strict';

    angular.module('theFoodApp').controller('ClienteDetailController',
        ClienteDetailController);

    function ClienteDetailController($state, $anchorScroll, translationStorageProvider, cliente, indirizzi, datiFatturazione, Cliente, $translate, DatiFatturazioneDialogService, IndirizzoDialogService) {
        var vm = this;
        // Interfaccia
        vm.cliente = cliente;
        vm.indirizzi = indirizzi;
        vm.datiFatturazione = datiFatturazione;
        vm.getLocale = getLocale;

        vm.datiFatturazioneDialog = datiFatturazioneDialog;

        vm.aggiungiIndirizzo = aggiungiIndirizzo;
        vm.modificaIndirizzo = modificaIndirizzo;
        vm.cancellaIndirizzo = cancellaIndirizzo;
        vm.goPrivacyPolicy = goPrivacyPolicy;

        vm.aggiungiDatiFatturazione = aggiungiDatiFatturazione;
        vm.modificaDatiFatturazione = modificaDatiFatturazione;
        vm.cancellaDatiFatturazione = cancellaDatiFatturazione;

        vm.isSaving = false;
        vm.saved = false;

        vm.clear = clear;
        vm.save = save;

        vm.genderList = [{
                "id": "M",
                "value": 'global.form.genderMale'
            },
            {
                "id": "F",
                "value": 'global.form.genderFemale'
            }
        ];

        vm.datepickerOptions = {
            showWeeks: false,
            maxDate: new Date()
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dataDiNascita = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        // Implementazione

        var clienteData = _.cloneDeep(cliente);

        function goPrivacyPolicy(){
            $state.go('static-page.privacy-policy');
        }

        function datiFatturazioneDialog(){
            DatiFatturazioneDialogService.open(cliente,vm.datiFatturazione);
        }

        function aggiungiDatiFatturazione() {
            return DatiFatturazioneDialogService.open(cliente)
                .then(function(datiFatturazione) {
                    vm.datiFatturazione.push(datiFatturazione);
                });
        }

        function modificaDatiFatturazione(datiFatturazione) {
            return DatiFatturazioneDialogService.open(cliente, datiFatturazione);
        }

        function cancellaDatiFatturazione(datiFatturazione) {
            return DatiFatturazioneDialogService.openDelete(datiFatturazione)
                .then(function() {
                    vm.datiFatturazione = _.filter(vm.datiFatturazione, function(df) {
                        return df.id !== datiFatturazione.id;
                    });
                });
        }

        function aggiungiIndirizzo() {
            return IndirizzoDialogService.open(cliente)
                .then(function(indirizzo) {
                    vm.indirizzi.push(indirizzo);
                });
        }

        function modificaIndirizzo(indirizzo) {
            return IndirizzoDialogService.open(cliente, indirizzo);
        }

        function cancellaIndirizzo(indirizzo) {
            return IndirizzoDialogService.openDelete(indirizzo)
                .then(function() {
                    vm.indirizzi = _.filter(vm.indirizzi, function(i) {
                        return i.id !== indirizzo.id;
                    });
                });
        }

        function getLocale() {
            return translationStorageProvider.getCurrent();
        }

        function clear() {
            vm.cliente = _.cloneDeep(clienteData);
        }

        function save() {
            if(vm.cliente.consensoCondizioni === false){
                vm.saveFailed = true;
                $anchorScroll();
                return;
            }
            vm.saved = false;
            vm.saveFailed = false;
            vm.isSaving = true;
            Cliente.update(vm.cliente, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            vm.saved = true;
            $anchorScroll();
        }

        function onSaveError(err) {
            vm.isSaving = false;
            vm.saveFailed = true;
            $anchorScroll();
        }

    }
})();
