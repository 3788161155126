(function() {
    'use strict';
    angular
        .module('theFoodApp')
        .factory('MovimentiCredito', MovimentiCredito);

        MovimentiCredito.$inject = ['$resource', 'SERVER_URL'];

    function MovimentiCredito ($resource, SERVER_URL) {
        var resourceUrl = SERVER_URL + 'api/v1/movimento-credito';

        return $resource(resourceUrl, {}, {

            totale: {
				method: 'GET',
				isArray: false,
				url: resourceUrl + '/totale',
				cache: false
            },            
            movimenti: {
				method: 'GET',
				isArray: true,
				url: resourceUrl + '/movimenti',
				cache: false
			}
            //'query': { method: 'GET', isArray: true}//,
            // 'get': {
            //     method: 'GET',
            //     transformResponse: function (data) {
            //         if (data) {
            //             data = angular.fromJson(data);
            //         }
            //         return data;
            //     }
            // },
            // 'update': { method:'PUT' }
        });
    }
})();
