( function () {
	'use strict';

	angular
		.module( 'theFoodApp' )
		.config( stateConfig );

	function stateConfig( $stateProvider ) {
		$stateProvider.state( 'mobile-app', {
			parent: 'app',
			url: '/mobile-app',
			resolve: {
				storeUrl: [ 'MobileAppService', function ( MobileAppService ) {
					return MobileAppService.getStoreUrlForDevice();
				}]
			},
			onEnter: function ( $window, $location, $log, $timeout, storeUrl ) {
				$timeout( function () {
					if ( storeUrl && window.localStorage ) {
						localStorage.setItem( 'redirectTo', storeUrl );
						$window.history.replaceState( {}, '', '/' );
					} else {
						$log.error( 'Store URL non disponibile, redirect su homepage' );
						$location.path( '/?from=mobile#/landing' ).replace();
					}
				}, 0 );
			}
		} );
	}

} )();
