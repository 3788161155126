( function() {
	'use strict';

	angular
		.module( 'theFoodApp' )
		.controller( 'PaymentsController', PaymentsController );

	function PaymentsController($scope, $window, $state, $translate, $uibModalInstance, SERVER_URL, Pagamento, ordine,useCredit, totalWithUseCredit, bsLoadingOverlayService, $uibModal ) {
		var vm = this;
		vm.allPayments = [
			{
				imgUrl: SERVER_URL + 'static/images/payments/paypal.jpg',
				imgAlt: $translate.instant( 'theFoodApp.concludi.payments.paypal' ),
				issuer: 'PayPal'
			}
			, {
				imgUrl: SERVER_URL + 'static/images/payments/gestpay.png',
				imgAlt: $translate.instant( 'theFoodApp.concludi.payments.gestpay' ),
				issuer: 'BancaSella'
			}
			, {
				imgUrl: SERVER_URL + 'static/images/payments/cashOnDelivery.jpeg',
				imgAlt: $translate.instant( 'theFoodApp.concludi.payments.cashOnDelivery' ),
				issuer: 'CashOnDelivery'
			}
		];

		vm.pickUpPayments = [
			{
				imgUrl: SERVER_URL + 'static/images/payments/paypal.jpg',
				imgAlt: $translate.instant( 'theFoodApp.concludi.payments.paypal' ),
				issuer: 'PayPal'
			}
			, {
				imgUrl: SERVER_URL + 'static/images/payments/gestpay.png',
				imgAlt: $translate.instant( 'theFoodApp.concludi.payments.gestpay' ),
				issuer: 'BancaSella'
			}
		];

		vm.payments = ordine.ritiroAlLocale ? vm.pickUpPayments : vm.allPayments;
		vm.cancel = cancel;
		vm.selectIssuer = selectIssuer;
		vm.totalWithUseCredit = totalWithUseCredit;
		vm.useCredit = useCredit;

		var isSafari = false;
		var ua = navigator.userAgent.toLowerCase();
		if (ua.indexOf('safari') != -1 && ua.indexOf('chrome') <= -1) {
			isSafari = true;
		}

		function selectIssuer( issuer ) {

			var params = {
				ordineId: ordine.id,
				issuer: issuer,
				couponCode : ordine.codiceCoupon,
				useCredit : useCredit,
        device: $scope.isMobile() ? 'ANDROID' : 'WEB'
			};

			bsLoadingOverlayService.start( { referenceId: 'body' } );

			Pagamento.requestWithCouponAndCredit( params ).$promise
				.then( function( result ) {
					if( result.error === true){
						bsLoadingOverlayService.stop( { referenceId: 'body' } );
						cancel(result.errorCode);
					} else {
						if ( $scope.isMobile() ) {
							var ref = cordova.InAppBrowser.open( result.redirectUrl, '_blank', 'location=no' );
							var shouldReload = true;
							ref.addEventListener( 'loadstart', function( event ) {
								if ( event.url.match( "#/paymentOk/|#/paymentError/|#/paymentCancel/" ) ) {
									var newURL = event.url.substring( event.url.indexOf( '#' ) );
									$window.location.hash = newURL;
									$window.location.reload( true );
									shouldReload = false;
									ref.close();
								}
							} );
							ref.addEventListener('exit',function(){
								if(shouldReload){
									$window.location.reload( true );
								}
	
							});
						} else {
							$window.location.href = result.redirectUrl;
						}
					}
				} )
				.catch( function( err ) {
					bsLoadingOverlayService.stop( { referenceId: 'body' } );
					cancel();
					if (err.data.message === 'error.frozenCart') {
						openAnnullaOrdineModal(ordine);
					}
				} )
				.finally(function(){
					cancel();
					if(isSafari || $scope.isMobile()){
					    // Issue #109: Premendo back su safari o da app rimane il loading overlay attivo.
						bsLoadingOverlayService.stop( { referenceId: 'body' } );
					}
				});
		}

		function cancel(error) {
			if(error){
				$uibModalInstance.dismiss( error );
			} else {
				$uibModalInstance.dismiss( 'cancel' );
			}
		}

		function openAnnullaOrdineModal(ordine) {
			var modalInstance = $uibModal.open({
				animation: true,
				templateUrl: 'app/pages/carrello/annulla-pagamento.html',
				backdrop: 'static',
				keyboard: false,
				controller: 'AnnullaPagamentoController',
				controllerAs: 'vm',
				resolve: {
					ordine: ordine,
					translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
						$translatePartialLoader.addPart('carrello');
						return $translate.refresh();
					}]
				}
			});
			return Promise.resolve(modalInstance.result).then(function() {
				$state.reload();
			});
		}
	}

} )();
