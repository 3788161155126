(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.directive('strada', strada);

	function strada() {

		var indirizzoTemplate = _.template("{{ strada.descrizioneComune }}, {{ strada.descrizione }}");

		var directive = {
			restrict: 'A',
			template: '<input uib-typeahead="indirizzo as ss.componiIndirizzo(indirizzo) for indirizzo in ss.searchStrada($viewValue)" typeahead-wait-ms="600" typeahead-input-formatter="ss.componiIndirizzo($model, indirizzo)" autocomplete="off" typeahead-min-length="3" />',
			replace: true,
			controller: controller,
			controllerAs: 'ss'
		};

		function controller(TrovaZona, AlertService) {
			'ngInject';

			this.searchStrada = function(strada) {
				return TrovaZona.autocomplete({
					query: strada
				}).$promise.catch(function(error) {
					AlertService.error(error.data.message);
				});

			};

			this.componiIndirizzo = function(strada) {
				return strada ? capitalize(indirizzoTemplate({
					strada: strada
				})) : null;
			};

			function capitalize(str) {
				return _(str).split(' ').map(function (w) {
					if (w){
						return w[0].toUpperCase() + w.substring(1).toLowerCase()
					}
				} ).join(' ');
			}

			return this;

		}

		return directive;

	}

})();
