(function() {
    'use strict';

    angular
        .module('theFoodApp')
        .factory('translationStorageProvider', translationStorageProvider);

    translationStorageProvider.$inject = ['$cookies', '$log', 'LANGUAGES'];

    function translationStorageProvider($cookies, $log, LANGUAGES) {

        var current = null;
        
        return {
            getCurrent: getCurrent,
            get: get,
            put: put
        };

        function getCurrent() {
            if ( !current )
                current = get('NG_TRANSLATE_LANG_KEY');
            return current;
        }

        function get(name) {
            if (LANGUAGES.indexOf($cookies.getObject(name)) === -1) {
                $log.info('Resetting invalid cookie language "' + $cookies.getObject(name) + '" to prefered language "it"');
                $cookies.putObject(name, 'it');
            }
            return $cookies.getObject(name);
        }

        function put(name, value) {
            if ( name === 'NG_TRANSLATE_LANG_KEY' )
                current = value;
            $cookies.putObject(name, value);
        } 
    }
})();
