(function(){
	'use strict';

	angular
		.module('theFoodApp')
		.directive('telefono', telefono);

	function telefono(){

		var directive = {
			restrict: 'A',
			template: '<input ng-pattern="tl.patternTelefono"/>',
			replace: true,
			controller: controller,
			controllerAs: 'tl'
		};

		function controller(AlertService) {
			'ngInject';

			this.patternTelefono = /^[0-9]+$/;

			return this;

		}

		return directive;

	}

})();
