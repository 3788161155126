(function(){
    'use strict';

    angular
		.module( 'theFoodApp' )
		.controller( 'ConfirmCouponCreditoController', ConfirmCouponCreditoController );

		function ConfirmCouponCreditoController(couponCode, $uibModalInstance,$scope){
			$scope.couponCode = couponCode;
			$scope.modal = $uibModalInstance;

			return $scope.modal.result;
		}
})();
