(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.controller('LocaleController', LocaleController);

	function LocaleController($window, $stateParams, $state, locale, isTakeaway,isRunnerPizza, listino, InformazioniAperturaOdierna, promozioni, PromozioniHolder) {

		var vm = this;
		vm.isDesktop = $window.outerWidth > 812 ? true : false;
		vm.mostraLinee = vm.mostraLinee ? vm.mostraLinee : ( vm.isDesktop ? true : false);
		vm.locale = locale;
		vm.linee = listino;
		vm.promozioni = promozioni;
		vm.isTakeaway = isTakeaway; // vero sse siamo in modalità takeaway.
		vm.informazioniAperturaOdierna = InformazioniAperturaOdierna.getInformazioniAperturaOdierna(locale);
		vm.isRunnerPizza = isRunnerPizza;
		vm.showAlertProductModified = $stateParams.carrelloRiordinato;

		PromozioniHolder.setVisibility(promozioni.length > 0, [promozioni]);

		mostraStringaProssimaConsegnaUtile(locale);

		function mostraStringaProssimaConsegnaUtile(locale) {
			vm.mostraPrimaConsegnaUtile = vm.informazioniAperturaOdierna.prossimaConsegnaUtile && vm.informazioniAperturaOdierna.orarioPrimaConsegna;
		}

	}

})();
