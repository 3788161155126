(function() {
    'use strict';
    angular.module('theFoodApp').config(stateConfig);
    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('storico', {
            parent: 'app',
            url: '/storico-ordini',
            reloadOnSearch: false,
            data: {
                pageTitle: 'theFoodApp.storico-ordini.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/storico-ordini/storico-ordini.html',
                    controller: 'StoricoOrdiniController',
                    controllerAs: 'vm'
                }
            },
            breadcrumb: {
                title: "{{ 'theFoodApp.storico-ordini.title' | translate }}"
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('ordine');
                    $translatePartialLoader.addPart('locale');
                    $translatePartialLoader.addPart('storico')
                    return $translate.refresh();
                }]
            }
        });
    }
})();
