(function() {
    'use strict';
    angular
        .module('theFoodApp')
        .factory('Coupon', Coupon);

        Coupon.$inject = ['$resource', 'SERVER_URL'];

    function Coupon ($resource, SERVER_URL) {
        var resourceUrl = SERVER_URL + '/api/coupon';

        return $resource(resourceUrl, {}, { 

            saveCouponCredito: {
				method: 'GET',
				isArray: false,
                url: resourceUrl + '/aggiungi-credito/:codice',
                params: {
					codice: '@codice'
				},
				cache: false
            },     
            getValueCouponCredito: {
				method: 'GET',
				isArray: false,
                url: resourceUrl + '/valore-credito/:codice',
                params: {
					codice: '@codice'
				},
				cache: false
            }, 
            //'query': { method: 'GET', isArray: true}//,
            // 'get': {
            //     method: 'GET',
            //     transformResponse: function (data) {
            //         if (data) {
            //             data = angular.fromJson(data);
            //         }
            //         return data;
            //     }
            // },
            // 'update': { method:'PUT' }
        });
    }
})();
