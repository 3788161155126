/* global MobileDetect console */
( function () {
	
	'use strict';
	angular
		.module( 'theFoodApp' )
		.factory( 'MobileAppService', MobileAppService );

	function MobileAppService( $window, LINK_ANDROID_APP, LINK_IOS_APP ) {
		
		var me = this;

		// Interface
		me.getStoreUrlForDevice = getStoreUrlForDevice;

		// Implementation

		function getStoreUrlForDevice() {
			var md = new MobileDetect( $window.navigator.userAgent );
			if ( md.os() === 'AndroidOS' ) {
				return LINK_ANDROID_APP;
			} else if ( md.os() === 'iOS' ) {
				return LINK_IOS_APP;
			} else {
				return null;
			}
		}

		return me;

	}

} )();
