(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.controller('TakeawayController', TakeawayController);

	function TakeawayController($scope, $state, Takeaway, AlertService, pagingParams, paginationConstants, insegne, comuni) {
		var vm = this;
		var startSMSize = 991;

		vm.insegne = extendInsegne(insegne);
		vm.comuni = comuni;
		vm.tipologieLocali = [];

		vm.loadingTipologie = false;
		vm.loadingInsegne = false;

		vm.selectedComune = $state.params.comune ? parseFloat($state.params.comune) : null;
		vm.selectedTipologie = $state.params.tipologie ? _.map($state.params.tipologie, parseFloat) : [];

		vm.selectComune = selectComune;
		vm.toggleTipologiaLocale = toggleTipologiaLocale;
		vm.isScreenLargeEnough = isScreenLargeEnough;
		vm.setMostraComuni = setMostraComuni;

		function isScreenLargeEnough() {
			if (document.body.offsetWidth > startSMSize) {
				return true;
			}

			return false;

		}

		function setMostraComuni() {
			if (document.body.offsetWidth <= startSMSize) {
				vm.mostraComuni = false;
			}

			vm.mostraComuni = true;
		}

		function activate() {
			loadTipologieLocali(vm.selectedComune);
		}

		function selectComune(comuneId) {
			if (vm.mostraTipologiePerComune === comuneId) {
				vm.mostraTipologiePerComune = !vm.mostraTipologiePerComune;
			} else {
				vm.mostraTipologiePerComune = !vm.mostraTipologiePerComune;
				vm.mostraTipologiePerComune = comuneId;
			}
			vm.descrizioneSelectedComune = _.find(vm.comuni, ['id', comuneId]);

			if (vm.selectedComune === comuneId) {
				return Promise.resolve();
			}

			vm.selectedComune = comuneId;
			$state.go('.', {
				comune: vm.selectedComune,
				tipologie: vm.selectedTipologie
			});
			return Promise.all([
				loadTipologieLocali(vm.selectedComune),
				loadInsegne(vm.selectedComune, vm.selectedTipologie)
			]);

		}

		function toggleTipologiaLocale(e, t) {
			t.selected = !t.selected;

			vm.selectedTipologie = _(vm.tipologieLocali).filter('selected').map('id').value();
			$state.go('.', {
				comune: vm.selectedComune,
				tipologie: vm.selectedTipologie
			}).then(function() {
				loadInsegne(vm.selectedComune, vm.selectedTipologie);
			});

			e.stopPropagation();
			return false;
		}

		function loadTipologieLocali(comuneId) {
			if (!comuneId) {
				vm.selectedTipologie = [];
				return Promise.resolve([]);
			}

			vm.loadingTipologie = true;
			return Takeaway.tipologieLocali({
				comune: comuneId
			}).$promise.then(function(data) {
				vm.tipologieLocali = data;
				_.each(vm.tipologieLocali, function(t) {
					t.selected = _.includes(vm.selectedTipologie, t.id);
				});
				return data;
			}, function(error) {
				AlertService.error(error.data.message);
			}).then(function() {
				vm.loadingTipologie = false;
			});
		}

		function loadInsegne(comuneId, tipologieLocaliId) {
			var params = {
				comune: comuneId,
				tipologieLocali: tipologieLocaliId
			};

			vm.loadingInsegne = true;
			return Takeaway.insegne(params).$promise.then(function(data) {
				vm.queryCount = vm.totalItems;

				// Sostituisci i *contenuti* di vm.insegna senza eseguire un'assegnazione ad un nuovo valore.
				vm.insegne.splice(0, vm.insegne.length);
				vm.insegne.push.apply(vm.insegne, extendInsegne(data));

				return data;
			}, function(error) {
				AlertService.error(error.data.message);
			}).then(function() {
				vm.loadingInsegne = false;
			});
		}

		function extendInsegne(insegne) {
			_.each(insegne, function(insegna) {
				_.each(insegna.locales, function(locale) {
					locale.insegna = insegna;
				});
			});
			return insegne;
		}

		activate();
		setMostraComuni();

		return vm;

	}

})();
