(function() {
    'use strict';

    angular
        .module('theFoodApp')
        .controller('OrdineDialogController', OrdineDialogController);

    OrdineDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Ordine', 'Feedback', 'RigaOrdine', 'Cliente', 'Locale', 'Indirizzo', 'DatiFatturazione', 'ScontoPromozione', 'Pagamento'];

    function OrdineDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Ordine, Feedback, RigaOrdine, Cliente, Locale, Indirizzo, DatiFatturazione, ScontoPromozione, Pagamento) {
        var vm = this;

        vm.ordine = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.feedbacks = Feedback.query({filter: 'ordine-is-null'});
        $q.all([vm.ordine.$promise, vm.feedbacks.$promise]).then(function() {
            if (!vm.ordine.feedbackId) {
                return $q.reject();
            }
            return Feedback.get({id : vm.ordine.feedbackId}).$promise;
        }).then(function(feedback) {
            vm.feedbacks.push(feedback);
        });
        vm.rigaordines = RigaOrdine.query();
        vm.clientes = Cliente.query();
        vm.locales = Locale.query();
        vm.indirizzos = Indirizzo.query();
        vm.datifatturaziones = DatiFatturazione.query();
        vm.scontopromoziones = ScontoPromozione.query();
        vm.pagamentos = Pagamento.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.ordine.id !== null) {
                Ordine.update(vm.ordine, onSaveSuccess, onSaveError);
            } else {
                Ordine.save(vm.ordine, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('theFoodApp:ordineUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataOrdine = false;
        vm.datePickerOpenStatus.dataConsegna = false;
        vm.datePickerOpenStatus.dataDownload = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
