(function() {
    'use strict';

    angular
        .module('theFoodApp')
        .directive('partitaIva', partitaIva);

    function partitaIva () {
        var directive = {
            restrict: 'A',
            require: '?ngModel',
            link: linkFunc
        };

        return directive;

        function linkFunc (scope, element, attrs, ngModel) {
            if (!ngModel) {
                return;
            }

            ngModel.$validators.partitaIva = function (modelValue) {
                return ngModel.$isEmpty(modelValue) || checkPartitaIva(modelValue);
            };
        }

		function checkPartitaIva(pi) {
			if (pi === '') {
				return true;
			}

			if (!/^[0-9]{11}$/.test(pi)) {
				return false;
			}

			var s = 0, i;
			for (i = 0; i <= 9; i += 2)
				s += pi.charCodeAt(i) - '0'.charCodeAt(0);
			for (i = 1; i <= 9; i += 2) {
				var c = 2 * (pi.charCodeAt(i) - '0'.charCodeAt(0));
				if (c > 9)
					c = c - 9;
				s += c;
			}
			var atteso = (10 - s % 10) % 10;
			if (atteso !== pi.charCodeAt(10) - '0'.charCodeAt(0)) {
				return false;
			}

			return true;
		}
    }

})();
