(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.directive('locale', locale);

	function locale() {
		var directive = {
			restrict: 'E',
			scope: {
				locale: '=data',
				isTakeaway: '='
			},
			templateUrl: 'app/pages/selezione-locale/locale.directive.html',
			bindToController: true,
			controllerAs: 'vm',
			/* @ngInject */
			controller: function($state, InformazioniAperturaOdierna) {

				var vm = this;

				vm.pranzoApre = (vm.locale.calendarios && vm.locale.calendarios[0]) ? vm.locale.calendarios[0].pranzoApre : null;
				vm.cenaApre = (vm.locale.calendarios && vm.locale.calendarios[0]) ? vm.locale.calendarios[0].cenaApre : null;

				vm.mostraConsegnaUtile = vm.pranzoApre || vm.cenaApre;
				vm.informazioniAperturaOdierna = InformazioniAperturaOdierna.getInformazioniAperturaOdierna(vm.locale);

				return vm;
			}
		};

		return directive;
	}
})();
