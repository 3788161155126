(function () {

  angular
    .module('theFoodApp')
    .factory('WebStorageUtil', WebStorageUtil);

  function WebStorageUtil() {
    var instance = this;

    instance.isLocalStorageSupported = isLocalStorageSupported;
    instance.isSessionStorageSupported = isSessionStorageSupported;

    function isLocalStorageSupported() {
      return isWebStorageSupported('localStorage');
    }

    function isSessionStorageSupported() {
      return isWebStorageSupported('sessionStorage');
    }

    function isWebStorageSupported(webStorageName) {
			try {
				var supported = (webStorageName in window && window[webStorageName]);
				if (supported) {
					 window[webStorageName].setItem("storage", "");
					 window[webStorageName].removeItem("storage");
				}

				return true;
			} catch(err) {
				console.log(err)
				return false;
			}
    }

    return instance;
  }

})();
