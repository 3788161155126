(function() {
    'use strict';

    angular
        .module('theFoodApp')
        .factory('authInterceptor', authInterceptor);

    authInterceptor.$inject = ['$rootScope', '$q', '$location', '$localStorage', '$sessionStorage', 'WebStorageUtil', 'TokenStore'];

    function authInterceptor ($rootScope, $q, $location, $localStorage, $sessionStorage, WebStorageUtil, TokenStore) {
        var service = {
            request: request
        };

        return service;

        function request (config) {
            /*jshint camelcase: false */
            config.headers = config.headers || {};
            var token = '';
            if (WebStorageUtil.isLocalStorageSupported())
                token = $localStorage.authenticationToken || $sessionStorage.authenticationToken;
            else
                token = TokenStore.isPresent() ? TokenStore.get().token : null;

            if (token && token.expires_at && token.expires_at > new Date().getTime()) {
                config.headers.Authorization = 'Bearer ' + token.access_token;
            }

            return config;
        }
    }
})();
