
( function() {
	'use strict';
	angular
		.module( 'theFoodApp' )
		.factory( 'Locale', Locale );

	function Locale( $resource, SERVER_URL ) {
		var resourceUrl = SERVER_URL + 'api/public/v1/locali/:localeId';

		return $resource( resourceUrl, {}, {
			get: {
				method: 'GET',
				isArray: false,
				cache: true
			},
			getListino: {
				url: resourceUrl + '/listino',
				isArray: true,
				cache: false
			}
		} );
	}
} )();
