(function() {
	'use strict';
	angular
		.module('theFoodApp')
		.factory('Ordine', Ordine);

    Ordine.$inject = ['$resource', 'SERVER_URL', 'DateUtils'];

    function Ordine ($resource, SERVER_URL, DateUtils) {
        var resourceUrl = SERVER_URL + 'api/v1/ordini/:id';

		return $resource(resourceUrl, {}, {
			'query': {
				method: 'GET',
				isArray: true
			},
			'get': {
				method: 'GET',
				transformResponse: function(data) {
					if (data) {
						data = angular.fromJson(data);
						data.dataOrdine = DateUtils.convertDateTimeFromServer(data.dataOrdine);
						data.dataConsegna = DateUtils.convertLocalDateFromServer(data.dataConsegna);
						data.dataDownload = DateUtils.convertDateTimeFromServer(data.dataDownload);
					}
					return data;
				}
			},
			'update': {
				method: 'PUT',
				transformRequest: function(data) {
					var copy = angular.copy(data);
					copy.dataConsegna = DateUtils.convertLocalDateToServer(copy.dataConsegna);
					return angular.toJson(copy);
				}
			},
			'save': {
				method: 'POST',
				transformRequest: function(data) {
					var copy = angular.copy(data);
					copy.dataConsegna = DateUtils.convertLocalDateToServer(copy.dataConsegna);
					return angular.toJson(copy);
				}
			},
			'updateWithCouponCode': {
				method: 'PUT',
				url: SERVER_URL + 'api/v1/ordini/coupon'
			},
		});
	}
})();
