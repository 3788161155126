(function() {
    'use strict';

    angular
        .module('theFoodApp')
        .factory('ConfirmationYesnoDialogService', ConfirmationYesnoDialogService);

    function ConfirmationYesnoDialogService($uibModal) {
      var vm = this;

      vm.open = open;

      function open(data) {

          return $uibModal.open({
              templateUrl: 'app/components/dialog/confirmation-yesno-dialog.html',
              controller: 'ConfirmationYesnoDialogController',
              controllerAs: 'vm',
              size: 'md',
              resolve: { data: data }
          }).result;
      }

      return vm;
    }
})();