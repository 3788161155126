(function(){
	'use strict';

	angular
		.module('theFoodApp')
		.controller('PromozioniController', PromozioniController);

	function PromozioniController(promozioni){
		var vm = this;

		//Interface
		vm.promozioni = promozioni;

	}
})();