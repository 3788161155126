(function() {
    'use strict';

    angular
        .module('theFoodApp')
        .factory('DatiFatturazioneDialogService', DatiFatturazioneDialogService);

    DatiFatturazioneDialogService.$inject = ['$uibModal'];

    function DatiFatturazioneDialogService ($uibModal) {
      var vm = this;

      vm.open = open;
      vm.openDelete = openDelete;

        function open(cliente, datiFatturazione) {

            if (!datiFatturazione) {
                datiFatturazione = {
                    clienteId: cliente.id,
                    ragioneSociale: null,
                    ragioneSociale2: null,
                    pIva: null,
                    codFisc: null,
                    indirizzo: null,
                    citta: null,
                    provincia: null,
                    cap: null,
                    telefono: null,
                    id: null,
                    pec: null,
                    sdi: null
                };
            }

            return $uibModal.open({
                templateUrl: 'app/pages/cliente/dati-fatturazione/dati-fatturazione-dialog.html',
                controller: 'DatiFatturazioneDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: { entity: datiFatturazione }
            }).result;
        }

        function openDelete(datiFatturazione) {

            return $uibModal.open({
                templateUrl: 'app/pages/cliente/dati-fatturazione/dati-fatturazione-delete-dialog.html',
                controller: 'DatiFatturazioneDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: { entity: datiFatturazione }
            }).result;

        }

      return vm;
    }
})();
