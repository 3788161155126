(function () {
    'use strict';

    angular
        .module('theFoodApp')
        .factory('ElasticsearchReindex', ElasticsearchReindex);

    ElasticsearchReindex.$inject = ['$resource', 'SERVER_URL', 'SERVER_URL'];

    function ElasticsearchReindex($resource, SERVER_URL) {
        var service = $resource(SERVER_URL+'api/elasticsearch/index', {}, {
            'reindex': {method: 'POST'}
        });

        return service;
    }
})();
