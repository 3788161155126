(function() {
    'use strict';

    angular
        .module('theFoodApp')
        .filter('translateEnv', translateEnv);

    translateEnv.$inject = ['$translate', 'APP_NAME'];

    function translateEnv ($translate, APP_NAME) {
        function translate(input) {
            var translation = $translate.instant(input + '-' + APP_NAME);

            if (translation.indexOf(input) > -1) {
                translation = $translate.instant(input);
            }

            return translation;
        }

        translate.$stateful = true;
        return translate;
    }

})();
