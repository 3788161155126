(function() {
	'use strict';

	angular.module('theFoodApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {

		$stateProvider.state('carrello', {
			parent: 'locale',
			url : '/carrello',
			reloadOnSearch : false,
			data : {
				pageTitle : 'theFoodApp.ordine.detail.title'
			},
			views : {
				'content@' : {
					templateUrl : 'app/pages/carrello/carrello.full.html',
				},
				'carrello@carrello' : {
					templateUrl : 'app/pages/carrello/carrello.html',
					controller : 'CarrelloController',
					controllerAs : 'vm'
				}
			},
			resolve : {
				translatePartialLoader : [ '$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('ordine');
					$translatePartialLoader.addPart('aggiunte');
					$translatePartialLoader.addPart('varianti');
					$translatePartialLoader.addPart('carrello');
					$translatePartialLoader.addPart('global');
					return $translate.refresh();
				} ]
			},
			breadcrumb: {
				title: "{{ 'theFoodApp.ordine.detail.title' | translate }}"
			}
		});
	}
})();
