(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.factory('MultiInsegnaToggle', MultiInsegnaToggle);

	function MultiInsegnaToggle( APP_NAME ) {
    var vm = this;

    vm.isMultiInsegna = isMultiInsegna;

    function isMultiInsegna() {
      return ('RunnerPizza' !== APP_NAME);
    }

    return vm;
  }

})();
