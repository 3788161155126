(function() {
    'use strict';

    angular
        .module('theFoodApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('app', {
            abstract: true,
            data: {
                pageTitle: 'Home'
            },
            views: {
                'navbar@': {
                    templateUrl: 'app/layouts/navbar/navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                },
                'breadcrumb@': {
                    templateUrl: 'app/layouts/breadcrumb/breadcrumb.html',
                    controller: 'BreadcrumbController as bc'
                }
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
					$translatePartialLoader.addPart('payment');
                    $translatePartialLoader.addPart( 'selezione-locale' );
                    $translatePartialLoader.addPart('social');
                    return $translate.refresh();
                }]
            },
            breadcrumb: {
                icon: 'home',
                state: 'home'
            }
        });
		$stateProvider.state('landing', {
            parent: 'app',
            url: '/',
            data: {
                authorities: []
            },
            onEnter: function( $location, $timeout, HOME_PAGE ) {
                $timeout( function() {
                    if ( window.localStorage && window.localStorage.getItem( 'redirectTo' ) ) {
                        var redirectTo = window.localStorage.getItem( 'redirectTo' );
                        localStorage.removeItem( 'redirectTo' );
                        window.location.href = redirectTo;
                    } else {
                        $location.path( '/' + HOME_PAGE ).replace();
                    }
                }, 0 );
            }
        });
	}
})();
