(function() {

	angular
		.module('theFoodApp')
		.factory('CarrelloInstanceFactory', CarrelloInstanceFactory);

	function CarrelloInstanceFactory($rootScope, Carrello, TempUserIdService, $translate, ThrottlerInstanceFactory, $uibModal) {

		function CarrelloInstance(locale, zona) {

			var instance = this;
			// Implementation
			var lang = $translate.proposedLanguage() || $translate.use();

			// Interface
			this.locale = locale;
			this.zona = zona;

			this.refresh = refresh;
			this.getArticoli = getArticoli;
			this.aggiungiProdotto = aggiungiProdotto;
			this.rimuoviArticolo = rimuoviArticolo;
			this.svuotaCarrello = svuotaCarrello;
			this.getAggiunte = getAggiunte;
			this.salvaAggiunte = salvaAggiunte;
			this.salvaVariantiAggiunte = salvaVariantiAggiunte;
			this.getPrezzoProdotto = getPrezzoProdotto;
			this.getTotaleArticoli = getTotaleArticoli;
			this.getImportoArticoli = getImportoArticoli;
			this.getImportoArticoliSenzaSconti = getImportoArticoliSenzaSconti;
            this.getImportoArticoliExcludeScontoConsegna = getImportoArticoliExcludeScontoConsegna;
            this.isTipoConsegnaGratis=isTipoConsegnaGratis;
			this.getImportoSpedizione = getImportoSpedizione;
			this.getVarianti = getVarianti;
			this.salvaVarianti = salvaVarianti;
			this.isVuoto = isVuoto;
			this.isImportoMinimoRaggiunto = isImportoMinimoRaggiunto;
			this.getScontiOrdine =  getScontiOrdine;
			this.getOrdine = getOrdine;
			this.getMessaggiPromozione = getMessaggiPromozione;
			this.$promise = Promise.resolve();

			// Implementation

			var ordine = {},
				articoli = [],
				isTakeaway = zona === null,
				throttler = ThrottlerInstanceFactory.build(1, 0),
				annullaOrdineModalOpen = false;

			function parameters(args) {

				var device = 'WEB';
				if ( $rootScope.isMobile() ) {
					if ( window.device !== undefined && window.device.platform === "iOS" ) {
						device = 'IOS';
					} else if ( window.device !== undefined && window.device.platform === "Android" ) {
						device = 'ANDROID';
					} else {
						device = 'MOBILE';
					}
				}

				return _.extend( {}, args, {
					device: device,
					localeId: locale.id,
					zonaId: zona,
					clienteId: TempUserIdService.get()
				} );
			}

            function isTipoConsegnaGratis(scontoPromozione) {
                let dettaglio = getDettaglioPromozione(scontoPromozione);
                let tipoDettaglio = angular.fromJson(dettaglio);
                return tipoDettaglio.effetto.tipo === "SCONTO_CONSEGNA_GRATIS"
            }

            function getDettaglioPromozione(scontoPromozione) {
                return scontoPromozione.promozione.dettaglio;
            }

            function refresh() {
				return Carrello.get( parameters() ).$promise.then( onDataUpdated )
			}

			function activate() {
				instance.$promise = Promise.resolve( refresh() ).return( instance );
				return instance.$promise;
			}

			function onDataUpdated( data ) {
				ordine = data.ordine;
				articoli = ordine.rigaOrdines;
				$rootScope.$broadcast( 'carrello-updated', this );

				if(ordine.ordineCongelato){
					openAnnullaOrdineModal(ordine);
				}
				return data;
			}

			function getArticoli() {
				return articoli;
			}

			function getTotaleArticoli() {
				return articoli.length;
			}

            function getImportoArticoliExcludeScontoConsegna() {
                if (ordine.scontoPromoziones) {
                    let scontoPromoziones = ordine.scontoPromoziones
                    let totaleScontato = ordine.importoOrdine;

                    /* ISSUE 283*/
                    for (let index in scontoPromoziones) {
                        let sconto = scontoPromoziones[index];
                        if (isTipoConsegnaGratis(sconto)) {
                            totaleScontato += sconto.valoreSconto;
                        }
                    }
                    return totaleScontato;
                }
                return ordine.importoOrdine;
            }


            function getImportoArticoliSenzaSconti() {
				return ordine.importoOrdineSenzaSconti;
			}

			function getImportoArticoli() {
				return ordine.importoOrdine;
			}

			function getImportoSpedizione() {
				return ordine.importoSpedizione;
			}

			function handleError(err) {
				if (err.data.message === 'error.frozenCart') {
					openAnnullaOrdineModal(ordine);
				}

				return Promise.reject(err);
			}

			/**
			 * Aggiunge un prodotto a questo carrello.
			 * @param prodotto : Il prodotto da aggiungere
			 * @param menu[] : La lista di scelte fatte per il menu, se il prodotto è un menu. Ogni oggetto della lista dovrà contentenere una proprietà 'menuItemId' che è l'identificativo della scelta di menu fatta, e una proprietà 'productId' che indica qual'è il prodotto associato alla scelta.
			 */
			function aggiungiProdotto(prodotto, menu) {
				return throttler.throttle(function() {
					return Carrello.aggiungiProdotto(parameters(), {
						productId: prodotto.id,
						menuProducts: menu
					}).$promise.then(onDataUpdated, handleError);
				});
			}

			function rimuoviArticolo(riga) {
				return throttler.throttle(function() {
					return Carrello.rimuoviArticolo(parameters({
						rigaId: riga.id
					})).$promise.then(onDataUpdated, handleError);
				});
			}

			function svuotaCarrello() {
				return throttler.throttle(function() {
					return Carrello.svuotaCarrello(parameters())
						.$promise.then(onDataUpdated, handleError);
				});
			}

			function getAggiunte(rigaId, menuId) {
				return Carrello.aggiunte(parameters({
					rigaId: rigaId,
					dettaglioMenuId: menuId
				})).$promise;
			}

			function salvaAggiunte(rigaId, menuId, aggiunte) {
				return throttler.throttle(function() {
					return Carrello.salvaAggiunte(parameters({
						rigaId: rigaId,
						dettaglioMenuId: menuId
					}), aggiunte).$promise.then(onDataUpdated, handleError);
				});
			}

			function getVarianti(rigaId, menuId) {
				return Carrello.varianti(parameters({
					rigaId: rigaId,
					dettaglioMenuId: menuId
				})).$promise;
			}

			function salvaVarianti(rigaId, menuId, varianti) {
				return throttler.throttle(function() {
					return Carrello.salvaVarianti(parameters({
						rigaId: rigaId,
						dettaglioMenuId: menuId
					}), varianti).$promise.then(onDataUpdated, handleError);
				});
			}

			function salvaVariantiAggiunte(rigaId, menuId, varianti, aggiunte, isAggiunteToUpdate) {
				return throttler.throttle(function() {
					return Carrello.salvaVariantiAggiunte(parameters({
						rigaId: rigaId,
						dettaglioMenuId: menuId,
						aggiunte: aggiunte,
						isAggiunteToUpdate: isAggiunteToUpdate
					}), varianti).$promise.then(onDataUpdated, handleError);
				});
			}

			function getPrezzoProdotto(prodotto) {
				return isTakeaway ? prodotto.prezzoAsporto : prodotto.prezzoDomicilio;
			}

			function isVuoto() {
				return getTotaleArticoli() === 0;
			}

			function isImportoMinimoRaggiunto() {
				return !locale.insegna.importoMinimoConsegna || locale.insegna.importoMinimoConsegna <= getImportoArticoli();
			}

			function openAnnullaOrdineModal(ordine) {
				if ( annullaOrdineModalOpen )
					return;
				annullaOrdineModalOpen = true;
				var modalInstance = $uibModal.open({
					animation: true,
					templateUrl: 'app/pages/carrello/annulla-pagamento.html',
					backdrop: 'static',
					keyboard: false,
					controller: 'AnnullaPagamentoController',
					controllerAs: 'vm',
					resolve: {
						ordine: ordine,
						translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
							$translatePartialLoader.addPart('carrello');
							return $translate.refresh();
						}]
					}
				});
				return modalInstance.result.then( function() {
					annullaOrdineModalOpen = false;
					return refresh();
				}, function(e) {
					annullaOrdineModalOpen = false;
					return openAnnullaOrdineModal( ordine );
				});
			}

			// Restituisce gli sconti relativi all'ordine.
			function getScontiOrdine(){
				return ordine.scontoPromoziones;
			}

			function getOrdine(){
				return ordine;
			}

			function getMessaggiPromozione(){
				return ordine.messaggi;
			}

			activate();

			return instance;

		}

		return {
			build: function( locale, zona, isTakeaway ) {
				return new CarrelloInstance( locale, zona, isTakeaway );
			}
		};
	}

}) ();
