(function(){
    'use strict';

    angular
		.module( 'theFoodApp' )
		.controller( 'AnnullaPagamentoController', AnnullaPagamentoController );

		function AnnullaPagamentoController(ordine, $uibModalInstance, AnnullaPagamento, $state){
			var vm = this;

			// Interface
			vm.annullaPagamento = annullaPagamento;

			function annullaPagamento() {
				return AnnullaPagamento.sblocca({id: ordine.id}).$promise.then(function(){
					$uibModalInstance.close();
					$state.reload();
				});
			}


			return vm;
		}
})();
