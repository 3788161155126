( function() {
	'use strict';
	angular
		.module( 'theFoodApp' )
		.factory( 'Consegna', Consegna );

	Consegna.$inject = [ '$resource', 'SERVER_URL' ];

	function Consegna( $resource, SERVER_URL ) {
		var resourceUrl = SERVER_URL + 'api/public/v1/consegne/:zona/:type';

		return $resource( resourceUrl, {}, {
			insegne: {
				method: 'GET',
				isArray: true,
				params: {
					type: 'insegne'
				},
				cache: true
			},
			locale : {
				url : resourceUrl+'/:insegnaId',
				method: 'GET',
				isArray: false,
				params: {
					type: 'insegne'
				},
				cache: true
			},
			tipologieLocali: {
				method: 'GET',
				isArray: true,
				params: {
					type: 'tipologielocali'
				},
				cache: true
			}
		} );
	}
} )();
