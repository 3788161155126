( function() {
	'use strict';

	angular
		.module( 'theFoodApp' )
		.config( stateConfig );

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig( $stateProvider ) {
		$stateProvider
			.state( 'paymentOk', {
				parent: 'app',
				url: '/paymentOk/:transactionId',
				data: { pageTitle: 'theFoodApp.payment-ok.title' },
				views: {
					'content@': {
						controller: function($state, pagamento) {
							$state.go('acquisto-concluso', {
								pagamentoId: pagamento.id,
								paymentOk: true,
								t: pagamento.ordine.ritiroAlLocale ? pagamento.ordine.locale.id : undefined,
								i: pagamento.ordine.locale.insegna.id
							})
						}
					}
				},
				resolve: {
					pagamento: function($stateParams, Pagamento) {
						return Pagamento.transaction({ transactionId: $stateParams.transactionId }).$promise;
					}
				},
				breadcrumb: { title: "{{ 'theFoodApp.payment-ok.title' | translate }}" }
			} )
			.state( 'paymentCancel', {
				parent: 'app',
				url: '/paymentCancel/:transactionId',
				data: { pageTitle: 'theFoodApp.payment-cancel.title' },
				views: {
					'content@': {
						controller: function($state, pagamento) {
							$state.go('concludi-acquisto', {
								pagamentoId: pagamento.id,
								paymentCancel: true,
								t: pagamento.ordine.ritiroAlLocale ? pagamento.ordine.locale.id : undefined,
								i: pagamento.ordine.locale.insegna.id
							})
						}
					}
				},
				resolve: {
					pagamento: function($stateParams, Pagamento) {
						return Pagamento.transaction({ transactionId: $stateParams.transactionId }).$promise;
					}
				},
				breadcrumb: { title: "{{ 'theFoodApp.payment-cancel.title' | translate }}" }
			} )
			.state( 'paymentError', {
				parent: 'app',
				url: '/paymentError/:transactionId',
				data: { pageTitle: 'theFoodApp.payment-error.title' },
				views: {
					'content@': {
						controller: function($state, pagamento, AlertService, $translate) {
							if (pagamento) {
								$state.go('concludi-acquisto', {
									pagamentoId: pagamento.id,
									paymentError: true,
									t: pagamento.ordine.ritiroAlLocale ? pagamento.ordine.locale.id : undefined,
									i: pagamento.ordine.locale.insegna.id
								});
							} else {
								AlertService.error($translate.instant('payments.payments-interrupted-error.title'));
								$state.go('landing');
							}
						}
					}
				},
				resolve: {
					pagamento: function($stateParams, Pagamento) {
						return Pagamento.transaction({ transactionId: $stateParams.transactionId }).$promise
							.then(function(pagamento) {
								Promise.resolve(pagamento);
							})
							.catch(function(err) {
								Promise.resolve();
							});
					}
				},
				breadcrumb: { title: "{{ 'theFoodApp.payment-error.title' | translate }}" }
			} );

	}

} )();
