(function() {
    'use strict';

    angular
        .module('theFoodApp')
        .factory('IndirizzoDialogService', IndirizzoDialogService);

    IndirizzoDialogService.$inject = ['$uibModal'];

    function IndirizzoDialogService ($uibModal) {
      var vm = this;

      vm.open = open;
      vm.openDelete = openDelete;

        function open(cliente, indirizzo) {

          if (!indirizzo) {
            indirizzo = {
              clienteId: cliente.id,
              civico: null,
              tipoCivico: null,
              nomeSulCampanello: null,
              sezioneCensimento: null,
              via: null,
              telefono: null,
              telefono2: null,
              piano: null,
              interno: null,
              scala: null,
              note: null,
              id: null
            };
          }

          return $uibModal.open({
             templateUrl: 'app/pages/cliente/indirizzo/indirizzo-dialog.html',
             controller: 'IndirizzoDialogController',
             controllerAs: 'vm',
             backdrop: 'static',
             size: 'lg',
             resolve: { entity: indirizzo }
          }).result;
        }

        function openDelete(indirizzo) {

          return $uibModal.open({
                templateUrl: 'app/pages/cliente/indirizzo/indirizzo-delete-dialog.html',
                controller: 'IndirizzoDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: { entity: indirizzo }
            }).result;

        }

      return vm;
    }
})();
