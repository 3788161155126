( function() {
	'use strict';

	angular
		.module( 'theFoodApp' )
		.controller( 'BreadcrumbController', BreadcrumbController );

	function BreadcrumbController( $scope, $state, $interpolate, $log ) {
		var vm = this;

		var updateBreadcrumbsArray = function() {
			var links = [],
				current = $state.$current;

			while ( current ) {

				if ( current.breadcrumb ) {

					var link = {
						sref: current.breadcrumb.state || current.name, // Allow overriding the breadcrumb link with the special property state.
						isIcon: false,
						description: '--',
						icon: '--'
					};

					if ( current.breadcrumb.title ) {
						var interpolationContext = angular.isDefined( current.locals ) ? current.locals.globals : current;
						link.description = $interpolate( current.breadcrumb.title )( interpolationContext );
					} else if ( current.breadcrumb.icon ) {
						link.icon = 'glyphicon glyphicon-' + current.breadcrumb.icon;
						link.isIcon = true;
					} else {
						$log.error( 'Invalid breadcrumb definition for state: ' + current.name );
						link = null;
					}

					if ( link )
						links.push( link );

				} else if ( current.name && !current.abstract ) {
					$log.warn( 'No breadcrumb defined for state: ' + current.name );
				}

				current = current.parent;
			}

			vm.links = _.reverse( links );
		};

		$scope.$on( '$stateChangeSuccess', function() {
			updateBreadcrumbsArray();
		} );
		$scope.$root.$on( '$translateChangeEnd', function() {
			updateBreadcrumbsArray();
		} );

		updateBreadcrumbsArray();

	}
} )();
