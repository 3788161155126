
( function() {
	'use strict';
	angular
		.module( 'theFoodApp' )
		.factory( 'TempUserIdService', TempUserIdService );

	var COOKIE_NAME = 'temp-customer-uuid';
	var COOKIE_EXPIRATION = 24 * 7; // In hours.

	function TempUserIdService( $cookies ) {

		var vm = this;

		// Interface

		vm.init = init;
		vm.get = get;
		vm.clear = remove;

		// Implementation

		var current;

		function get() {
			return current;
		}

		function remove() {
			$cookies.remove( COOKIE_NAME );
			init();
		}

		function init() {
			var value = $cookies.get(COOKIE_NAME) || generateUUID();
			$cookies.put( COOKIE_NAME, value, {
				expires: new moment().add(COOKIE_EXPIRATION, 'hours').toDate()
			} );
			current = value;
			return value;
		}

		/**
		 * Generates a random string.
		 * Taken from: http://stackoverflow.com/a/8809472/90006
		 */
		function generateUUID() {
			var d = new Date().getTime();
			if (angular.isDefined(performance) && angular.isFunction(performance.now)){
				d += performance.now(); //use high-precision timer if available
			}
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
				var r = (d + Math.random() * 16) % 16 | 0;
				d = Math.floor(d / 16);
				return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
			});
		}

		init();

		return vm;

	}

} )();
