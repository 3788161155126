(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.controller( 'VariantiController', VariantiController );

	function VariantiController( $window, $uibModalInstance, currencyFilter, languageSwitchFilter, carrelloInstance, rigaOrdine, sceltaMenu, varianti, aggiunte ) {

		var vm = this;

		// Interface

		//Aggiunte
		vm.aggiunte = _.clone( aggiunte );
		vm.toggleAggiunta = toggleAggiunta;
		vm.getTotaleAggiunte = getTotaleAggiunte;
		vm.getCostoAggiunte = getCostoAggiunte;
		vm.getPrezzoAggiunta = getPrezzoAggiunta;
		vm.getAggiunteDisponibili = getAggiunteDisponibili;
		vm.canClear = _.constant( true );
		vm.canSave = canSave;
		vm.clear = clear;
		vm.isDesktop = $window.outerWidth > 812 ? true : false;
		vm.mostraAggiunte = vm.mostraAggiunte ? vm.mostraAggiunte : ( vm.isDesktop ? true : false);

		var riga = sceltaMenu || rigaOrdine,
		articolo = riga.prodotto;


		//Varianti
		vm.varianti = [];
		vm.variantiSelezionate = [];

		vm.isSaving = false;
		// vm.canSave = _.constant( true );
		vm.save = save;

		vm.abort = abort;

		vm.getCostoVarianti = getCostoVarianti;
		vm.getVarianteLabel = getVarianteLabel;

		// Implementation

		var riga = sceltaMenu || rigaOrdine;

		function activate() {
			vm.varianti = initVarianti( _.clone( varianti ) );
			vm.variantiSelezionate = _( riga.dettaglioVariantes )
				.keyBy( 'varianteId' )
				.value( );
			var current = _.map( riga.aggiuntas, 'id' );
				_.each( vm.aggiunte, function(a) {
					a.selected = _.includes( current , a.id );
				});
		}

		function extendDettaglioVariante( dettaglio ) {
			dettaglio.dettaglioVarianteId = dettaglio.id;
			return dettaglio;
		}

		function initVarianti( varianti ) {
			return _.orderBy( varianti, 'sortOrder' );
		}

		function getVarianteLabel( variante ) {
			var name = languageSwitchFilter( variante, 'descrizioneIt', 'descrizioneEn' ),
				priceLabel = ( variante.price === 0 ? '' : ' - ' + currencyFilter( variante.price, '€' ) );
			return name + priceLabel;
		}

		function getCostoVarianti() {
			return _.reduce( vm.variantiSelezionate, function(totale, vs) {
				return totale + vs.price;
			}, 0 );
		}

		function save() {
			var payload = _( vm.variantiSelezionate )
				.map( extendDettaglioVariante )
				.values();

				// return carrelloInstance.salvaVarianti( rigaOrdine.id, sceltaMenu ? sceltaMenu.id : null, payload )
				// 	.then( $uibModalInstance.close );
				var isAggiunteToUpdate = vm.aggiunte.length ? true : false;
				return carrelloInstance.salvaVariantiAggiunte( rigaOrdine.id, sceltaMenu ? sceltaMenu.id : null, payload, getAggiunteSelezionate(), isAggiunteToUpdate )
					.then( $uibModalInstance.close );
			}

		function abort() {
			$uibModalInstance.dismiss();
		}


		//Aggiunte
		function toggleAggiunta(evt, aggiunta) {
			aggiunta.selected = !aggiunta.selected;

			evt.stopPropagation();
			return false;
		}
		function clear() {
			_.each( vm.aggiunte, function(a) {
				a.selected = false;
			} );
		}

		function getAggiunteSelezionate() {
				return _( vm.aggiunte ).filter( 'selected' ).map( 'id' ).value();
		}

		function getTotaleAggiunte() {
			return _( vm.aggiunte ).filter( 'selected' ).value().length;
		}

		function getPrezzoAggiunta( aggiunta ) {
			return carrelloInstance.getPrezzoProdotto( aggiunta );
		}

		function getCostoAggiunte() {
			return _( vm.aggiunte ).filter( 'selected' ).reduce( function(v, a) {
				return v + ( a.selected ? getPrezzoAggiunta( a ) : 0 );
			}, 0 );
		}

		function getAggiunteDisponibili() {
			return articolo.aggiunteDisponibili;
		}

		function clear() {
			_.each( vm.aggiunte, function(a) {
				a.selected = false;
			} );
		}

		function canSave() {
			if (vm.aggiunte.length){
				return getAggiunteDisponibili() && getTotaleAggiunte() <= getAggiunteDisponibili();
			}else{
				return true;
			}
		}

		activate();

		return vm;

	}

})();
