( function() {
	'use strict';

	angular
		.module( 'theFoodApp' )
		.directive( 'calendario', calendario );

	function calendario() {
		var directive = {
			restrict: 'E',
			scope: {
				calendario: '=data'
			},
			templateUrl: 'app/pages/locale/calendario.directive.html',
			/* @ngInject */
			controller: function( $scope, $state, $translate, $translatePartialLoader ) {
				$translatePartialLoader.addPart( 'locale' );
				$translate.refresh();

				$scope.calendarioOrdinato = _.orderBy( $scope.calendario, [ 'giornoSettimana' ], [ 'asc' ] );

				$scope.days = [ 0, 1, 2, 3, 4, 5, 6 ];

				$scope.getDayOfTheWeek = function( giornoSettimana ) {
					var lang = $translate.proposedLanguage() || $translate.use();
					return moment().locale( lang ).day( giornoSettimana + 1 ).format( 'ddd' );
				};

				$scope.isOpenAtLunch = function( giornoSettimana ) {
					return $scope.calendarioOrdinato[ giornoSettimana ] && $scope.calendarioOrdinato[ giornoSettimana ].pranzoApre !== null;
				};

				$scope.isOpenAtDinner = function( giornoSettimana ) {
					return $scope.calendarioOrdinato[ giornoSettimana ] && $scope.calendarioOrdinato[ giornoSettimana ].cenaApre !== null;
				};

			},
			controllerAs: 'ctrl'
		};

		return directive;
	}
} )();
