(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.controller('SelezioneIndirizzoRubricaController', SelezioneIndirizzoRubricaController);

	SelezioneIndirizzoRubricaController.$inject = [ '$state', 'TrovaZona', 'IndirizzoStore', 'addressBook', 'Consegna' ];

	function SelezioneIndirizzoRubricaController( $state, TrovaZona, IndirizzoStore, addressBook, Consegna ) {

		var addressTemplate = _.template( '{{ indirizzo.strada.descrizioneComune }} - {{ indirizzo.strada.descrizione }} {{ indirizzo.civico }}' );

		//

		var vm = this;

		vm.validZone = true;
		vm.addressBook = addressBook;
		vm.getDescrizione = getDescrizione;
		vm.selezionaIndirizzo = selezionaIndirizzo;

		//

		function getDescrizione( indirizzo ) {
			return addressTemplate( { indirizzo: indirizzo } );
		}

		function selezionaIndirizzo( indirizzo ) {
			vm.validZone = true;
			return TrovaZona.zona({stradaId: indirizzo.strada.id, civico: indirizzo.civico}).$promise.then( function(zona) {
				IndirizzoStore.setFromAddressBook( indirizzo, zona.id );

				$state.go( 'consegna' );

			}, function() {
				vm.validZone = false;
			} );
		}

		return vm;
	}

})();
