(function () {

  angular
    .module('theFoodApp')
    .factory('ThrottlerInstanceFactory', ThrottlerInstanceFactory);

  function ThrottlerInstanceFactory() {

    function ThrottlerInstance(slotCount, slotCooldown) {
      if (!_.isFinite(slotCount)) {
        throw new Error('No or invalid slotCount parameter given');
      }

      if (!_.isFinite(slotCooldown)) {
        throw new Error('No or invalid slotCooldown parameter given');
      }

      var instance = this;

      this._slotCount = Math.max(slotCount, 1);
      this._slotCooldown = Math.max(slotCooldown, 0);
      this._availableSlots = _.map(_.times(this._slotCount, function () { return 0; }),
        function (v, i) { return Date.now() + i * this._slotCooldown / this._slotCount; });
      this._waitingRequests = [];

      this._awaitSlot = _awaitSlot;
      this._releaseSlot = _releaseSlot;
      this.throttle = throttle;

      function _awaitSlot() {
        if (instance._availableSlots.length > 0) {
          var slot = instance._availableSlots.shift(),
            now = Date.now(),
            promise = Promise.resolve(slot);
          if (slot > now) {
            promise = promise.delay(slot - now);
          }
          return promise;
        } else {
          // Semaphore implementation using promises.
          var release;
          var promise = new Promise(function () {
            release = arguments[0];
          });
          instance._waitingRequests.push({ release: release });
          return promise.then(function () { return instance._awaitSlot() });
        }
      }

      function _releaseSlot() {
        instance._availableSlots.push(Date.now() + instance._slotCooldown);
        if (instance._waitingRequests.length > 0) instance._waitingRequests.shift().release();
      }

      function throttle(fn) {
        if (!_.isFunction(fn))
          return Promise.reject(new Error('No or invalid function given.'));

        return instance._awaitSlot()
          .then(function(slot) { return fn(); })
          .finally(function () {
            instance._releaseSlot();
          });
      }

      return instance;
    }

    return {
      build: function (slotCount, slotCooldown) {
        return new ThrottlerInstance(slotCount, slotCooldown);
      }
    };
  }

})();
