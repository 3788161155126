(function(){
	'use strict';

	angular
		.module("theFoodApp")
		.config(stateConfig);

	function stateConfig($stateProvider){
		$stateProvider.state('promozione',{
			parent: 'locale',
			url: '/promozione',
			resolve: {
				promozioni: function($rootScope, $stateParams, RecuperaPromozioniService){
					return RecuperaPromozioniService.get({
						insegnaId: $stateParams.i,
						isMobile: $rootScope.isMobile()
					}).$promise;
				},
				translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
					$translatePartialLoader.addPart('promozioni');
					return $translate.refresh();
				}]
			},
			views: {
				'content@': {
					templateUrl: 'app/pages/promozioni/promozioni.full.html',
					controller: 'PromozioniController',
					controllerAs: 'vm'
				}
			}
		});
	}
})();