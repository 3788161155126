
( function() {

	angular
		.module( 'theFoodApp' )
		.factory( 'CarrelloHolder', CarrelloHolder );

	function CarrelloHolder($state) {
    var instance = this;
    instance.visible = false;
    instance.carrelli = [];

    instance.setVisibility = setVisibility;
    instance.isVisible = isVisible;
    instance.getCarrelli = getCarrelli;
    instance.getImportoCarrello = getImportoCarrello;

    function setVisibility(visible, carrelli) {
      instance.visible = visible;
      if (visible && carrelli && carrelli.length > 0) {
        instance.carrelli = carrelli;
      } else {
        instance.carrelli = [];
      }
    }

    function isVisible() {
      return instance.visible;
    }

    function getCarrelli() {
      return instance.carrelli;
    }

    function getImportoCarrello() {
      if (instance.carrelli && instance.carrelli.length > 0) {
			  return instance.carrelli[0].getImportoArticoliExcludeScontoConsegna();
      } else {
        return null;
      }
		}

    return instance;
  }

})();
