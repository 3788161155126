(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.controller('PaymentOkController', PaymentOkController);

	function PaymentOkController( $state, $stateParams, DateUtils, pagamento, indirizzo ) {
    var vm = this;

		vm.pagamento = pagamento;
		vm.ordine = pagamento.ordine;
		vm.locale = pagamento.ordine.locale;
		vm.indirizzo = indirizzo;

		function getOrariConsegna(orarioConsegna, locale) {
			var d = new Date();
			d.setHours(parseInt(orarioConsegna.split(':')[0]));
			d.setMinutes(parseInt(orarioConsegna.split(':')[1]));

			d.setMinutes(d.getMinutes() + locale.insegna.splitOrariDiConsegna);

			return orarioConsegna + " - " + d.getHours() + ":" + d.getMinutes();
		}

		vm.getOrariConsegna = getOrariConsegna;

		vm.dataOraTransazione = DateUtils.convertDateTimeFromServer(vm.pagamento.dataOraTransazione);
		vm.dataOrdine = DateUtils.convertDateTimeFromServer(vm.ordine.dataOrdine);
		vm.dataConsegna = DateUtils.convertDateTimeFromServer(vm.ordine.dataConsegna);

		return vm;
  }

}) ();