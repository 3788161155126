(function() {
    'use strict';
    angular
        .module('theFoodApp')
        .factory('OrariConsegna', OrariConsegna);

    OrariConsegna.$inject = ['$resource', 'SERVER_URL'];

    function OrariConsegna($resource, SERVER_URL) {
        var resourceUrl = SERVER_URL + 'api/public/v1/locali/orari-consegna';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: false
            }
        });
    }
})();
