(function() {

	angular
		.module( 'theFoodApp' )
		.factory( 'PromozioniHolder', PromozioniHolder );

	function PromozioniHolder($state) {
		var instance = this;
		instance.visible = false;
		instance.promozioni = [];

		instance.setVisibility = setVisibility;
		instance.isVisible = isVisible;
		instance.getPromozioni = getPromozioni;

		function setVisibility(visible, promozioni) {
			instance.visible = visible;
			if (visible && promozioni && promozioni.length > 0) {
				instance.promozioni = promozioni;
			} else {
				instance.promozioni = [];
			}
		}

		function isVisible() {
			return instance.visible;
		}

		function getPromozioni() {
			return instance.promozioni;
		}

		return instance;
	}
})();