( function() {
	'use strict';

	angular
		.module( 'theFoodApp' )
		.config( stateConfig );

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig( $stateProvider ) {

		$stateProvider.state( 'locale', {
			parent: 'selezione-locale',
			url: '^/insegna/{i:int}?{t:int}',
			data: {
				pageTitle: '{{ locale.insegna.insegna }}'
			},
			params: {
				carrelloRiordinato: false
			},
			resolve: {

				isTakeaway: function( $stateParams ) {
					return angular.isDefined( $stateParams.t );
				},
				
				isRunnerPizza : function(APP_NAME){
					return ('RunnerPizza' === APP_NAME);
				},

				indirizzoSalvato: function( isTakeaway, IndirizzoStore, RouteError, translatePartialLoader ) {
					if ( isTakeaway )
						return null;
					if ( !IndirizzoStore.isPresent() )
						return Promise.reject( new RouteError().translatedErrorMessage( 'theFoodApp.selezione-locale.no-indirizzo-consegna' ).redirectTo( 'selezione-indirizzo' ) );
					return IndirizzoStore.get();
				},
				zona: function( isTakeaway, indirizzoSalvato ) {
					if ( isTakeaway )
						return null;
					return indirizzoSalvato.zone;
				},

				locale: function( $stateParams, isTakeaway, zona, Locale, Consegna, RouteError, translatePartialLoader ) {

					if ( isTakeaway ) {

						if ( !$stateParams.t ) {
							// Per il takeaway è necessario sapere anche quale locale è stato selezionato.
							return Promise.reject( new RouteError( 'invalid-url' ).redirectTo( 'takeaway' ) );
						}

						return Locale.get( { localeId: $stateParams.t } ).$promise.then( function( locale ) {

							if ( locale.insegna.id !== $stateParams.i ) {
								// Il locale e l'insegna specificata non matchano.
								return Promise.reject( new RouteError().translatedErrorMessage( 'invalid-url' ).redirectTo( 'takeaway' ) );
							}

							return locale;

						}, function( err ) {
							if ( err && 'status' in err && err.status === 404 ) {
								return Promise.reject( new RouteError().translatedErrorMessage( 'invalid-url' ).redirectTo( 'takeaway' ) );
							} else {
								throw err;
							}
						} );
						// TODO: Gestire 404?
					} else {
						return Consegna.locale({ zona : zona, insegnaId : $stateParams.i }).$promise
							.catch( function( err ) {
								if ( err && 'status' in err && err.status === 404 ) {
									return Promise.reject( new RouteError().translatedErrorMessage( 'theFoodApp.selezione-indirizzo.error.no-locale-for-zone' ).redirectTo( 'consegna' ) );
								} else {
									throw err;
								}
							} );
					}

				},

				listino: function( Locale, locale ) {
					return Locale.getListino( { localeId: locale.id } ).$promise;
				},

				carrelloInstance: function( CarrelloInstanceFactory, locale, zona ) {
					return CarrelloInstanceFactory.build( locale, zona );
				},
				promozioni: function($rootScope, RecuperaPromozioniService, locale){
					var isMobile = $rootScope.isMobile();
					return RecuperaPromozioniService.get({
						insegnaId: locale.insegna.id,
						isMobile: isMobile
					}).$promise;
				},

				translatePartialLoader: [ '$translate', '$translatePartialLoader', function( $translate, $translatePartialLoader ) {
					// $translatePartialLoader.addPart( 'selezione-locale' );
					$translatePartialLoader.addPart( 'global' );
					$translatePartialLoader.addPart( 'locale' );
					$translatePartialLoader.addPart( 'ordine' );
					$translatePartialLoader.addPart( 'aggiunte' );
					$translatePartialLoader.addPart( 'varianti' );
					$translatePartialLoader.addPart( 'carrello' );
					return $translate.refresh();
				} ]
			},
			views: {
				'content@': {
					templateUrl: 'app/pages/locale/locale.html',
					controller: 'LocaleController',
					controllerAs: 'vm'
				},
				'listino@locale': {
					templateUrl: 'app/pages/locale/locale.listino.html',
					controller: 'LocaleListinoController',
					controllerAs: 'vm'
				},
				'info@locale': {
					templateUrl: 'app/pages/locale/locale.info.html',
					controller: function( locale ) {
						this.locale = locale;
					},
					controllerAs: 'll'
				},
				'carrello@locale': {
					templateUrl: 'app/pages/carrello/carrello.html',
					controller : 'CarrelloController',
					controllerAs : 'vm'
				}
			},
			breadcrumb: {
				// TODO: Risolvere in qualche modo...
				titleTakeaway: "{{ locale.insegna.insegna }} - {{ locale.indirizzo }}",
				titleConsegna: "{{ locale.insegna.insegna }}",
			}
		} );

	}
} )();
