(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.factory('IndirizzoStore', IndirizzoStore);

	var COOKIE_NAME = 'consegna';
	var COOKIE_EXPIRATION = 12; // In hours.

	/*
	 *
	 * addressCookie = {
	 *
	 *	// Se true, è stato specificato una strada e un civico custom;
	 *	// Se false, è stato selezionato uno degli indirizzi esistenti
	 *	new: true,
	 *
	 *	// (new == false) L'id dell'indirizzo scelto
	 *	addressId: null,
	 *
	 *	// (new == true) Oggetto strada e numerico civico inseriti dall'utente
	 *	strada: null,
	 * 	civico: null
	 *
	 *	// The resulting zone identifier
	 *	zone: null
	 *
	 * };
	 *
	 */

	function IndirizzoStore($cookies) {

		var vm = this;

		// Interface

		vm.setFromAddressBook = setFromAddressBook;
		vm.setFromInput = setFromInput;
		vm.clearCookie = clearCookie;
		vm.isPresent = isPresent;
		vm.get = clone;

		// Implementation

		var validCookie = false;
		var addressCookie = null;

		// Low level functions
		function loadCookie() {
			return $cookies.getObject(COOKIE_NAME);
		}

		function saveCookie(cookie) {
			$cookies.putObject(COOKIE_NAME, cookie, {
				expires: new moment().add(COOKIE_EXPIRATION, 'hours').toDate()
			});
		}

		function removeCookie() {
			$cookies.remove(COOKIE_NAME);
		}

		// High level functions
		function setFromAddressBook(address, zone) {
			validCookie = true;
			addressCookie = {
				new: false,
				addressId: address.id,
				strada: address.strada,
				civico: address.civico,
				zone: zone
			};
			saveCookie(addressCookie);
			return clone();
		}

		function setFromInput(strada, civico, zone) {
			validCookie = true;
			addressCookie = {
				new: true,
				strada: strada,
				civico: civico,
				zone: zone
			};
			saveCookie(addressCookie);
		}

		function loadFromCookie() {
			addressCookie = loadCookie();
			validCookie = angular.isDefined(addressCookie);
		}

		function clearCookie() {
			validCookie = false;
			addressCookie = null;
			removeCookie();
		}

		function isPresent() {
			return validCookie;
		}

		function clone() {
			return _.clone(addressCookie);
		}

		loadFromCookie();

		return vm;
	}

})();
