(function() {
	'use strict';
	angular
		.module('theFoodApp')
		.factory('ClienteOrdine', ClienteOrdine);

	function ClienteOrdine($resource, SERVER_URL, DateUtils) {

		var resourceUrl = SERVER_URL + 'api/v1/ordini/ordine-cliente';

		return $resource(resourceUrl, {}, {
			'get': {
				method: 'GET',
				transformResponse: function(data) {
					if (data) {
						data = angular.fromJson(data);
						data.dataOrdine = DateUtils.convertDateTimeFromServer(data.dataOrdine);
						data.dataConsegna = DateUtils.convertLocalDateFromServer(data.dataConsegna);
						data.dataDownload = DateUtils.convertDateTimeFromServer(data.dataDownload);
					}
					return data;
				}
			}
		});
	}
})();
