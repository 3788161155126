(function() {
	'use strict';

	angular
		.module('theFoodApp')
		.factory('IndirizziRubricaDialogService', IndirizziRubricaDialogService);

	function IndirizziRubricaDialogService ($uibModal) {
		var vm = this;

		vm.open = open;

		function open(indirizziRubrica) {
			var modalInstance = $uibModal.open({
				templateUrl: 'app/pages/concludi-acquisto/indirizzi/indirizzi-rubrica-dialog.html',
				controller: 'IndirizziRubricaDialogController',
				controllerAs: 'vm',
				backdrop: 'static',
				resolve: {
				indirizziRubrica: function () {
					return indirizziRubrica;
				}
			 }
			});

			return Promise.resolve(modalInstance.result);
		}

		return vm;
	}

})();